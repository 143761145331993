import React from 'react';
import tallesStyle from "../styles/estilos/Talles.module.css";
import guideStyle from "../styles/estilos/guide.module.css";
import fantasma from "../images/fantasmaOpinion.png";
import danger from "../images/Danger.png";
import remera1 from "../images/remeras/remera1.png";
import remera2 from "../images/remeras/remera2.png";
import remera3 from "../images/remeras/remera3.png";
import remera4 from "../images/remeras/remera4.png";
import remera5 from "../images/remeras/remera5.png";
import remera6 from "../images/remeras/remera6.png";


const GuiaRemera1 = ({ onClose, onNext }) =>{ 

  return(
  
 <div className={tallesStyle.modalBackground}>
  <div className={tallesStyle.modalG1}>
    {/*<img className={guideStyle.danger} src={danger} alt="Danger" />*/}
  <div className={tallesStyle.cellContent}></div>
  <div className={guideStyle.cellContent}>
    <h2 className={guideStyle.title} >AYUDA PARA COMPLETAR TU GUÍA LITTLE </h2>
    <h6 className={guideStyle.subtitle}>TENE TU GUIA LISTA PARA COMPLETARLO EN CONJUNTO</h6>
    <div className={guideStyle.announce}>
      <p>IMPORTANTE: SI AGREGAN cosas que no estan en el presupuesto que les pasaron anteriormente, su precio puede AUMENTAR.</p>
      <img  className={guideStyle.fantasma} src={fantasma} alt="fantasma"/>
    </div>

    <div className={guideStyle.number}>
      <h2 className={guideStyle.num}>1</h2>
      <p>COMO ES TU MODELO?</p>
    </div>

    <div className={guideStyle.container} >
     <div className={guideStyle.modelImages}>

       <div className={guideStyle.category}>
        <p>CHOMBA</p>
        <img src={remera1} alt="remera"/>
       </div>

      <div className={guideStyle.category2}>
        <p>REMERA</p> <p>CUELLO REDONDO</p>
        <img src={remera2} alt="remera"/>
      </div>

      <div className={guideStyle.category2}>
        <p>REMERA</p> <p>CUELLO V</p>
        <img src={remera3} alt="remera"/>
       </div>
  </div>
</div>

    <h6>QUE TIPO DE RECORTE LLEVA TU PRENDA?</h6>
    
     <div className={guideStyle.announce}>
      <p>SI NO LLEVA RECORTES ES UNA PRENDA LISA</p>
      <img  className={guideStyle.fantasma} src={fantasma} alt="fantasma"/>
    </div>
    
    <section>
      <h6>ES UN RECORTE SIMPLE?</h6>
      <div className={guideStyle.modelimages2}>

        <div className={guideStyle.category}>
        <p>RECORTE</p> <p>50/50</p>
        <img  className={guideStyle.img}
        src={remera4} alt="remera"/>
      </div>

        <div className={guideStyle.category}>
        <p>RECORTE</p> <p>70/30</p>
        <img className={guideStyle.img} src={remera5} alt="remera"/>
       </div>

       <div className={guideStyle.category}>
        <p>RECORTE</p> <p>M</p>
        <img className={guideStyle.img} src={remera6} alt="remera"/>
       </div> 

       </div>
    </section>
  <div className={guideStyle.buttonContainer}>
     <button className={guideStyle.one}  onClick={onClose}>Cerrar</button>
     <button className={guideStyle.two}  onClick={onNext}>Siguiente</button>
   </div>
   </div>
   {/**Escritorio */}
  <div className={guideStyle.deskContent}>
  <h2 className={guideStyle.title} >AYUDA PARA COMPLETAR TU GUÍA LITTLE </h2>
    <h6 className={guideStyle.subtitle}>TENE TU GUIA LISTA PARA COMPLETARLO EN CONJUNTO</h6>
    <div className={guideStyle.announce}>
      <p>IMPORTANTE: SI AGREGAN cosas que no estan en el presupuesto que les pasaron anteriormente, su precio puede AUMENTAR.</p>
      <img  className={guideStyle.fantasma} src={fantasma} alt="fantasma"/>
    </div>

    <div className={guideStyle.number}>
      <h2 className={guideStyle.num}>1</h2>
      <p>COMO ES TU MODELO?</p>
    </div>

    <div className={guideStyle.container} >
     <div className={guideStyle.modelImages}>

       <div className={guideStyle.category}>
        <p>CHOMBA</p>
        <img src={remera1} alt="remera"/>
       </div>

      <div className={guideStyle.category2}>
        <p>REMERA</p> <p>CUELLO REDONDO</p>
        <img src={remera2} alt="remera"/>
      </div>

      <div className={guideStyle.category2}>
        <p>REMERA</p> <p>CUELLO V</p>
        <img src={remera3} alt="remera"/>
       </div>
  </div>
</div>
     <div className={guideStyle.buttonContainer1}>
      <button className={guideStyle.one}  onClick={onClose}>Cerrar</button>
      <button className={guideStyle.two}  onClick={onNext}>Siguiente</button>
    </div>
  </div>    
</div>
</div>
   );
 };
  export default GuiaRemera1;