import React from 'react';
import tallesStyle from "../styles/estilos/Talles.module.css";
import guideStyle from "../styles/estilos/guide.module.css";
import imagen45 from "../images/guideImages/imagen45.png";
import imagen46 from "../images/guideImages/imagen46.png";
import imagen47 from "../images/guideImages/imagen47.png";
import imagen48 from "../images/guideImages/imagen48.png";
const ModalTutorial7 = ({ onClose,onBack }) =>{ 

  return(
  
  <div className={tallesStyle.modalBackground}>
   <div className={tallesStyle.modalG1}>
   <div className={guideStyle.cellContent}>

     <div className={guideStyle.number}>
      <h2 className={guideStyle.num}>11</h2>
      <p>UBICACIONES</p>
    </div>
   
    <img  className={guideStyle.bigimg} src={imagen45} alt="medidas"/>
    <img className={guideStyle.bigimg} src={imagen46} alt="medidas"/>
      <div className={guideStyle.mangasContainer}>
        <img src={imagen47} alt="mangas"/>
        <img src={imagen48} alt="mangas"/>
      </div>

    <div className={guideStyle.buttonContainer7}>
      <button className={guideStyle.one}  onClick={onClose}>Cerrar</button>
      <button className={guideStyle.two} onClick={onBack}>Atras</button>
    </div>
    </div> 
       {/**Escritorio */}
  <div className={guideStyle.deskContent}>
    <div className={guideStyle.number}>
      <h2 className={guideStyle.num}>11</h2>
      <p>UBICACIONES</p>
    </div> 
  <div className={guideStyle.deskUbicacionescontainer}>
  <div className={guideStyle.firstubicaciones}>
    <img  className={guideStyle.bigimg} src={imagen45} alt="medidas"/>
    <img className={guideStyle.bigimg} src={imagen46} alt="medidas"/>
  </div>
  <div className={guideStyle.secondubicaciones}>
    <div className={guideStyle.mangasContainerDesk}>
        <img src={imagen47} alt="mangas"/>
        <img src={imagen48} alt="mangas"/>
      </div>
  </div>
  </div>
    <div className={guideStyle.buttonContainer7}>
      <button className={guideStyle.one}  onClick={onClose}>Cerrar</button>
      <button className={guideStyle.two} onClick={onBack}>Atras</button>
    </div>
  </div>

  </div>
  </div>
   );
 };
  export default ModalTutorial7;