import React from 'react';
import tallesStyle from "../styles/estilos/Talles.module.css";
import guideStyle from "../styles/estilos/guide.module.css";
import fantasma from "../images/fantasmaOpinion.png";
import danger from "../images/Danger.png";
import imagenremera17 from "../images/remeras/imagenremera17.png";
import cartacoloresjersey from "../images/guideImages/cartacoloresJersey.png";
import cartaColoresPique from "../images/guideImages/cartaColoresPique.png";
import remera15 from "../images/remeras/remera15.png";
import remera16 from "../images/remeras/remera16.png";
import carta1  from "../images/guideImages/CARTA1.png"
import carta2  from "../images/guideImages/CARTA2.png";
import carta3  from "../images/guideImages/CARTA3.png";
import carta4  from "../images/guideImages/CARTA4.png";

const GuiaRemera3 = ({ onClose, onNext,onBack }) =>{ 

  return(
  
 <div className={tallesStyle.modalBackground}>
<div className={tallesStyle.modalG1}>
 <div className={tallesStyle.modalContent}></div>
 <div className={guideStyle.cellContent}>

  <div className={guideStyle.number}>
      <h2 className={guideStyle.num}>4</h2>
      <p>CARTA DE COLORES JERSEY</p>
  </div>

    <div className={guideStyle.announceCarta}>
      <p>COLORES SUJETOS A DISPONIBILIDAD AL MOMENTO DE PRODUCCIÓN</p>
      <img  className={guideStyle.fantasma} src={fantasma} alt="fantasma"/>
    </div>
   
  <div className={guideStyle.cartaColores}>
    <img className={guideStyle.carta} src={cartacoloresjersey} alt="carta de colores"/>
  </div>

  <p className={guideStyle.pique}>CARTA DE COLORES PIQUE</p>
  <div className={guideStyle.announceCarta}>
      <p>COLORES SUJETOS A DISPONIBILIDAD AL MOMENTO DE PRODUCCIÓN</p>
      <img  className={guideStyle.fantasma} src={fantasma} alt="fantasma"/>
  </div>

  <div className={guideStyle.cartaColores}>
    <img className={guideStyle.carta} src={cartaColoresPique} alt="carta de colores"/>
  </div>

    
 <div className={guideStyle.buttonContainer}>
    <button className={guideStyle.one}  onClick={onClose}>Cerrar</button>
    <button className={guideStyle.two}  onClick={onBack}>Atras</button>
    <button className={guideStyle.two}  onClick={onNext}>Siguiente</button>
  </div> 
  </div>
  {/**desk */}
  <div className={guideStyle.deskContent1}>
    <section className={guideStyle.first3}>
          <div className={guideStyle.number}>
            <h2 className={guideStyle.num}>3</h2>
            <p>QUE TIPO DE MANGA PREFERIS?</p>
          </div>

          <div className={guideStyle.container} >
            <div className={guideStyle.modelimages3}>
              <div className={guideStyle.category}>
                <p>Normal </p>
                <img src={remera15} alt="remera" />
              </div>

              <div className={guideStyle.category}>
                <p>Ranglan</p>
                <img src={remera16} alt="remera" />
              </div>
            </div>
          </div>
    </section>
    <section className={guideStyle.second3}>
         <div className={guideStyle.number}>
            <h2 className={guideStyle.num}>4</h2>
            <p>CARTA DE COLORES DE JERSEY</p>
          </div>
          <div className={guideStyle.announce}>
           <p>COLORES SUJETOS A DISPONIBILIDAD AL MOMENTO DE PRODUCCIÓN</p>
           <img  className={guideStyle.fantasma} src={fantasma} alt="fantasma"/>
          </div>
          <div className={guideStyle.cartas}>
            <img className={guideStyle.cartaimg}  src={carta1} alt="" />
            <img className={guideStyle.cartaimg} src={carta2} alt="" />
            <img className={guideStyle.cartaimg}  src={carta3} alt="" />
            <img className={guideStyle.cartaimg}  src={carta4} alt="" />

          </div>
    </section>
  <div className={guideStyle.buttonContainercarta}>
    <button className={guideStyle.one}  onClick={onClose}>Cerrar</button>
    <button className={guideStyle.two}  onClick={onBack}>Atras</button>
    <button className={guideStyle.two}  onClick={onNext}>Siguiente</button>
  </div>
  </div>  
</div>
</div>
   );
 };
  export default GuiaRemera3;