import infoStyles from "../../styles/estilos/infoMetodos.module.css"
import santander from "../../images/santander.png"
import galicia from "../../images/galicia.png"
function TransferInfo(){

  return(
    <div className={infoStyles.infoContainer}>
      <div className={infoStyles.title}>TRANSFERENCIA BANCARIA</div>
      <div className={infoStyles.firstP}>
        <p className={infoStyles.number}>1</p>
        <p className={infoStyles.text}>Ingresá al Homebanking</p>
    </div>
    <div className={infoStyles.firstP}>
     <p className={infoStyles.number}>2</p>
     <p className={infoStyles.text}>Elegí uno de nuestros bancos a donde <br/>
      quieras realizar la transferencia:</p>
   </div>

   <div className={infoStyles.bancoscontainer}>
     <div className={infoStyles.bancocontainer}>
       <img src={santander} alt=""/>
       <div className={infoStyles.dataContainer}>
         <p className={infoStyles.items}>RAZON SOCIAL:<span className={infoStyles.dataSpan}>Little Blue Srl</span></p>
         <p className={infoStyles.items}>CUIT:<span className={infoStyles.dataSpan}>30-71050128-5</span></p>
         <p className={infoStyles.items}>CTA:<span className={infoStyles.dataSpan}>CC en Pesos 733-004896/1</span></p>
         <p className={infoStyles.items}>CBU:<span className={infoStyles.dataSpan}>0720733620000000489618</span></p>
         <p className={infoStyles.items}>SUCURSAL:<span className={infoStyles.dataSpan}>733</span></p>
         <p className={infoStyles.items}>ALIAS:<span className={infoStyles.dataSpan}>LITTLEBLUE.RIO</span></p>
         <p><span></span></p>
       </div>
     </div>

     <div className={infoStyles.bancocontainer}>
       <img src={galicia} alt=""/>
       <div className={infoStyles.dataContainer}>
         <p className={infoStyles.items}>RAZON SOCIAL:<span className={infoStyles.dataSpan}>Little Blue Srl</span></p>
         <p className={infoStyles.items}>CUIT:<span className={infoStyles.dataSpan}>30-71050128-5</span></p>
         <p className={infoStyles.items}>CTA.N:<span className={infoStyles.dataSpan}> 6590-2</span></p>
         <p className={infoStyles.items}>CBU:<span className={infoStyles.dataSpan}>0070335020000006590293</span></p>
         <p className={infoStyles.items}>SUCURSAL:<span className={infoStyles.dataSpan}>335-9</span></p>
         <p className={infoStyles.items}>ALIAS:<span className={infoStyles.dataSpan}>LITTLEBLUE.GALICIA</span></p>
         <p><span></span></p>
       </div>
 
     </div>
    
   </div>
   <div className={infoStyles.firstP}>
        <p className={infoStyles.number}>3</p>
        <p className={infoStyles.text}>
        Una vez que tengas el comprobante de pago,<br/>
         Vamos a necesitar que lo informes en:Mi Cuenta Blue. <br/> 
        Recorda que es IMPORTANTE que <br/>
        el comprobante se vea completo.
  </p>
</div>

    </div>
    
  )
}
export default TransferInfo;