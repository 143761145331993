
import infoStyles from "../../styles/estilos/infoMetodos.module.css"
import AlertaInfo from "../../images/ALERTAinfo.png"
function TarjDebitoInfo(){
  return(
    <div className={infoStyles.infoContainer}>
      
    <div className={infoStyles.title}>TARJETA DE DEBITO</div>

    <div className={infoStyles.firstP}>
    <p className={infoStyles.number}>1</p>
    <p className={infoStyles.text}>Ingresa al link que te mando tu asesor/a</p>
    </div>

    <div className={infoStyles.firstP}>
  <p className={infoStyles.number}>2</p>
  <p className={infoStyles.text}>La primera pantalla que va a aparecer<br/>
explicara el funcionamiento <br/>
de GO CUOTAS, si entendiste, presiona<br/>
COMENZAR.</p>
</div>

<div className={infoStyles.firstP}>
  <p className={infoStyles.number}>3</p>
  <p className={infoStyles.text}>
  Una vez completados los datos de quien <br/>
va a realizar la Transacción, vas a poder <br/>
ver el LIMITE DISPONIBLE. <br/>
(como en una tarjeta de crédito).
  </p>
</div>
<div>
  <img  className={infoStyles.image} src={AlertaInfo} alt="comprobante"/>
</div>

<div className={infoStyles.firstP}>
  <p className={infoStyles.number}>4</p>
  <p className={infoStyles.text}>Vas a poder seleccionar en CUANTAS <br/>
cuotas queres abonar y, a su vez, ELEGIR <br/>
el DÍA DE VENCIMIENTO de las mismas. </p>
</div>

<div className={infoStyles.firstP}>
  <p className={infoStyles.number}>5</p>
  <p className={infoStyles.text}>Completa los datos de la tarjeta de débito <br/>
con la cual abonaras todos los meses la cuota</p>
</div>

<div className={infoStyles.firstP}>
  <p className={infoStyles.number}>6</p>
  <p className={infoStyles.text}>Finalmente vas a poder ver un resumen de <br/>
compra. En donde va a figurar el monto total,<br/>
la cantidad de cuotas y los próximos <br/>
vencimientos. <br/> 
IMPORTANTE: SACA CAPTURA PANTALLA <br/>
DEL ID DE COMPRA.</p>
</div>

<div className={infoStyles.firstP}>
  <p className={infoStyles.number}>7</p>
  <p className={infoStyles.text}>Una vez que tengas el ID DE COMPRA,<br/>
   Vamos a necesitar que lo informes en Mi Cuenta Blue. <br/>
Recorda que es IMPORTANTE que el comprobante se vea completo.</p>
</div>
    
    </div>
  )
}
export default TarjDebitoInfo;