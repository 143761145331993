import React, { useState, useRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ticket from "../../images/Group 91.png";
import formulario from "../../styles/FormularioTB.module.css";
import { useDataContext } from "../../contexts/AuthContext";
import tickettb from "../../images/comprobantes ui-tansferencia.png";
import { useNavigate } from "react-router-dom";
import { paymentTF } from "../../services/api";
import { getPaymentInformation } from "../../services/api";
import fantasmaImg from "../../images/fantasma-opinion.png";
import logoPng from "../../images/Frame.png";
import Swal from "sweetalert2";



export default function TransferenciaBancaria({ toggleModal3 }) {
  const navigate = useNavigate();
  const { contextData } = useDataContext();

  const [selectedDate, setSelectedDate] = useState(null);
  const [nombre, setNombre] = useState("");
  const [digitos, setDigitos] = useState("");
  const [banco, setBanco] = useState("");
  const [amount, setAmount] = useState("");
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [singlePayment, setSinglePayment] = useState(0);
  const [amountError, setAmountError] = useState("");
  const [showImage, setShowImage] = useState(false);
  const [digitosError, setDigitosError] = useState("");
  const [modalVisible, setModalVisible] = useState(true);

  const [paymentStatus, setPaymentStatus] = useState(0);

  const [isSubmitting, setIsSubmitting] = useState(false); 
  const [loading, setLoading] = useState(true); 


  useEffect(() => {
    if (!contextData || !contextData.customer || !contextData.customer.id) {
      return;
    }

    const fetchPaymentInformation = async () => {
      try {
        const data = await getPaymentInformation(contextData.customer.id);
        console.log("Datos de pago:", data);
        setPaymentMethod(data.payment_method_number);
        setPaymentType(data.payment_type);
        setLoading(false);
      } catch (error) {
        console.log(
          `Error en la solicitud de información de pago: ${error.message}`
        );
      }
    };

    fetchPaymentInformation();
  }, [contextData.customer.id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (amount <= 100) {
        setAmountError("El monto debe ser mayor que 100");
        return;
      } else {
        setAmountError("");
      }
      if (!/^\d{4}$/.test(digitos)) {
        setDigitosError("Debe ingresar 4 dígitos");
        return;
      } else {
        setDigitosError("");
      }

      // Evitar envío duplicado
      if (isSubmitting) {
        return;
      }

      setIsSubmitting(true); // Marcamos que estamos enviando

      const formData = new FormData();
      formData.append("selectedDate", selectedDate);
      formData.append("amount", amount);
      formData.append("nombre", nombre);
      formData.append("digitos", digitos);
      formData.append("banco", banco);
      formData.append("paymentMethod", paymentMethod);
      formData.append("paymentType", paymentType);
      formData.append("singlePayment", singlePayment);
      formData.append("paymentStatus", paymentStatus);
      formData.append("archivoAdjunto", file);
      formData.append("customer", contextData.customer.id);
      formData.append("order_id", contextData.customer.order_id);

      const response = await paymentTF(formData, contextData.customer.id);

      if (response.ok) {
        const nuevoPayment = await response.json();
        console.log("Payment creado exitosamente:", nuevoPayment);
        navigate("/correcto");
      } else {
        const errorData = await response.json();
        console.error(
          "Error al crear el pago:",
          response.status,
          response.statusText,
          errorData
        );
        Swal.fire({
          icon: "error",
          iconColor: "#d54ab7",
          confirmButtonColor: "#0071ce",
          title: "Error al crear el pago",
          text: "Por favor, inténtelo de nuevo más tarde.",
        });

      }
    } catch (error) {
      console.error("Error al crear el pago:", error);
      Swal.fire({
        icon: "error",
        iconColor: "#d54ab7",
        confirmButtonColor: "#0071ce",
        title: "Error al crear el pago",
        text: "Por favor, inténtelo de nuevo más tarde.",
      });

    } finally {
      setIsSubmitting(false); // Resetear el estado después del envío
    }
  };

  const handleFileInputChange = (e) => {
    e.preventDefault();
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    console.log(selectedFile);
  };
  const handleAbrirModal = (e) => {
    e.preventDefault();

    setModalVisible(true);
  };
  const handleCloseModal = () => {
    setModalVisible(false);
  };

  if (loading) {
    return (
      <div className="contcargando">
        <img src={logoPng} alt="littleBlueLogo" className="imgcargando" />
        <h2 className="text-muted">CARGANDO...</h2>
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <div className={formulario.totalcontent}>
      <div className={formulario.deskcontent}>
        <div
          className={`container-fluid d-flex align-items-center justify-content-center  ${formulario.contenedorprincipalform}`}
        >
          <div className={formulario.conteinerprincipal}>
            <div className={`${formulario.containerTitulo}`}>
              <h2 className={`text-center ${formulario.titulotb}`}>
                TRANSFERENCIA
              </h2>
              <p className={`${formulario.mesegetb}`}>
                Por favor completá los datos que te marcamos en los suiguientes
                campos.
              </p>
            </div>
            <form
              onSubmit={handleSubmit}
              className={`d-flex flex-wrap justify-content-between ${formulario.contenedorform}`}
              encType="multipart/form-data"
            >
              <div className="mb-3">
                <img
                  src={tickettb}
                  alt="Descripción de la imagen"
                  className={`${formulario.imagetb}`}
                />
              </div>
              <div className={`w-48 ${formulario.containertb}`}>
                <div className="form-group mb-3">
                  <label className={formulario.label}>Fecha de pago</label>
                  <DatePicker
                  placeholderText="fecha del comprobante"
                    className={`form-control ${formulario.inputfecha}`}
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    dateFormat="dd/MM/yyyy"
                    required
                  />
                </div>

                <div className="form-group mb-3">
                  <label className={formulario.label}>ULTIMOS 4 DIGITOS</label>
                  <input
                    placeholder="Transaccion"
                    className={`form-control ${formulario.input}`}
                    type="number"
                    value={digitos}
                    onChange={(e) => setDigitos(e.target.value)}
                    pattern="[0-9]*"
                    required
                  />
                  {digitosError && <p style={{ color: 'red' }}>{digitosError}</p>}
                </div>

                <div className="form-group mb-3">
                  <label className={formulario.label}>CUENTA DESTINO</label>
                  <select
                    className={`form-control ${formulario.inputt}`}
                    value={banco}
                    onChange={(e) => setBanco(e.target.value)}
                    required
                  >
                    <option value="">Selecciona un banco</option>
                    <option value="Banco Santander">Banco Santander</option>
                    <option value="Banco Galicia">Banco Galicia</option>
                  </select>
                </div>
                <div className="form-group mb-3">
                  <label className={formulario.label}>IMPORTE</label>
                  <input
                    className={`form-control ${formulario.input}`}
                    type="number"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    pattern="[0-9]*"
                    required
                  />
                  {amount && !/^\d+$/.test(amount) && (
                    <div className="text-danger">Ingresa solo números</div>
                  )}
                  {amountError && (
                    <div className={`text-danger ${formulario.valitf}`}>
                      {amountError}
                    </div>
                  )}
                </div>

                <div className="w-48">
                  <div
                    className={`form-group mb-3 text-center ${
                      formulario.contenedorimagentb
                    } ${file ? "has-comprobante" : ""}`}
                  >
                    <div className={formulario.containerAjuntar}>
                      <img
                        src={ticket}
                        alt="Descripción de la imagen"
                        className={`${formulario.fototicket}`}
                      />

                      <div className="form-group mb-3 text-center">
                        <input
                          className="form-control-file"
                          type="file"
                          accept=".pdf, .jpg, .png"
                          style={{ display: "none" }}
                          onChange={handleFileInputChange}
                          ref={fileInputRef}
                        />
                        <button
                          className={`btn btn-primary btn-block ${formulario.botonadjuntartb}`}
                          onClick={(e) => {
                            e.preventDefault();
                            fileInputRef.current.click();
                          }}
                        >
                          {file
                            ? `Adjuntar Otro Comprobante`
                            : "Adjuntar Comprobante"}
                        </button>
                        {!file && (
                          <img
                            src={fantasmaImg}
                            alt="Fantasma"
                            className={formulario.fantasmaImg}
                          />
                        )}
                      </div>
                    </div>

                    {file && (
                      <div className="mb-3 text-center">
                        <img
                          src={URL.createObjectURL(file)}
                          alt="Comprobante adjunto"
                          className={formulario.imagenchicatf}
                        />
                      </div>
                    )}
                    <div className={formulario.mensajefecha}>
                  <p className={formulario.mensajefecha2}>
                  Verifique que la fecha sea visible
                  </p>
                </div>
                  </div>
                </div>
              </div>

              <div className={`w-100 mt-3 ${formulario.containerbotontb}`}>
                <button
                  type="submit"
                  className={`btn btn-primary btn-block ${formulario.botonenviartb}`}
                  disabled={!file || isSubmitting} // Deshabilitar el botón mientras se envía
                >
                  {isSubmitting ? "Enviando..." : "Enviar"}
                </button>
                <button
                  type="button"
                  className={`btn btn-secondary ${formulario.botoncerrartb}`}
                  onClick={toggleModal3}
                >
                  Cerrar
                </button>

                {!file && (
                  <div className={`text-danger mt-2 ${formulario.mensajetb}`}>
                    Debes cargar el comprobante
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* celular */}
      <div className={formulario.celcontent}>
        <div
          className={`container-fluid d-flex align-items-center justify-content-center  ${formulario.contenedorprincipalform}`}
        >
          <div className={formulario.conteinerprincipal}>
            <div className={`${formulario.containerTitulo}`}>
              <h2 className={`text-center ${formulario.titulotb}`}>
                TRANSFERENCIA
              </h2>
              <p className={`${formulario.mesegetb}`}>
                Por favor completá los datos que te marcamos en los suiguientes
                campos.
              </p>
            </div>
            <form
              onSubmit={handleSubmit}
              // sacar el betwen aca
              className={`d-flex flex-wrap  ${formulario.contenedorform}`}
              encType="multipart/form-data"
            >
              {/* <div className="mb-3">
            <img
              src={tickettb}
              alt="Descripción de la imagen"
              className={`${formulario.imagetb}`}
            />
          </div> */}
              <div className={`w-48 ${formulario.containertb}`}>
                <div className="form-group mb-3">
                  <label className={formulario.label}>Fecha de pago</label>
                  <DatePicker
                  placeholderText="fecha del comprobante"
                    className={`form-control ${formulario.inputfecha}`}
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    dateFormat="dd/MM/yyyy"
                    required
                  />
                </div>

                <div className="form-group mb-3">
                  <label className={formulario.label}>
                    ULTIMOS 4 DIGITOS
                    <button
                      type="button"
                      className={`btn btn-link ${formulario.btnss}`}
                      onClick={() => setShowImage(true)}
                    >
                      <i className="bi bi-question-circle"></i>
                    </button>{" "}
                  </label>
                  <input
                    placeholder="Transaccion"
                    className={`form-control ${formulario.input}`}
                    type="number"
                    value={digitos}
                    onChange={(e) => setDigitos(e.target.value)}
                    pattern="[0-9]*"
                    required
                  />
                  {digitos && !/^\d+$/.test(digitos) && (
                    <div className={`text-danger ${formulario.valitf}`}>
                      Ingresa solo números
                    </div>
                  )}
                </div>

                <div className="form-group mb-3">
                  <label className={formulario.label}>CUENTA DESTINO</label>
                  <select
                    className={`form-control ${formulario.inputt}`}
                    value={banco}
                    onChange={(e) => setBanco(e.target.value)}
                    required
                  >
                    <option value="">Selecciona un banco</option>
                    <option value="Banco Santander">Banco Santander</option>
                    <option value="Banco Galicia">Banco Galicia</option>
                  </select>
                </div>
                <div className="form-group mb-3">
                  <label className={formulario.label}>IMPORTE</label>
                  <input
                    className={`form-control ${formulario.input}`}
                    type="number"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    pattern="[0-9]*"
                    required
                  />
                  {amount && !/^\d+$/.test(amount) && (
                    <div className="text-danger">Ingresa solo números</div>
                  )}
                  {amountError && (
                    <div className={`text-danger ${formulario.valitf}`}>
                      {amountError}
                    </div>
                  )}
                </div>
              </div>
              {/* aca lo separa */}
              <div className="justify-content-center  text-center">
                <div
                  className={`form-group mb-3 text-center ${
                    formulario.contenedorimagentb
                  } ${file ? "has-comprobante" : ""}`}
                >
                  <div className={formulario.containerAjuntar}>
                    {/* <img
                    src={ticket}
                    alt="Descripción de la imagen"
                    className={`${formulario.fototicket}`}
                  /> */}

                    <div className={formulario.celcontent2}>
                      <input
                        className="form-control-file"
                        type="file"
                        accept=".pdf, .jpg, .png"
                        style={{ display: "none" }}
                        onChange={handleFileInputChange}
                        ref={fileInputRef}
                      />
                      <button
                        className={`btn btn-primary btn-block ${formulario.botonadjuntartb}`}
                        onClick={(e) => {
                          e.preventDefault();
                          fileInputRef.current.click();
                        }}
                      >
                        {file
                          ? `Adjuntar Otro Comprobante`
                          : "Adjuntar Comprobante"}
                      </button>
                      {!file && (
                      <img
                        src={fantasmaImg}
                        alt="Fantasma"
                        className={formulario.fantasmaImg}
                      />
                    )}
                    </div>
                  </div>

                  {file && (
                    <div className="mb-3 ">
                      <img
                        src={URL.createObjectURL(file)}
                        alt="Comprobante adjunto"
                        className={formulario.imagenchicatf}
                      />
                    </div>
                  )}
                </div>
                <div className={formulario.mensajefecha}>
                  <p className={formulario.mensajefecha2}>
                    Verifique que la fecha sea visible
                  </p>
                </div>
              </div>

              <div className={`w-100 mt-3 ${formulario.containerbotontb}`}>
                <button
                  type="submit"
                  className={`btn btn-primary btn-block ${formulario.botonenviartb}`}
                  disabled={!file || isSubmitting} // Deshabilitar el botón mientras se envía
                >
                  {isSubmitting ? "Enviando..." : "Enviar"}
                </button>
                <button
                  type="button"
                  className={`btn btn-secondary ${formulario.botoncerrartb}`}
                  onClick={toggleModal3}
                >
                  Cerrar
                </button>

                {!file && (
                  <div className={`text-danger mt-2 ${formulario.mensajetb}`}>
                    Debes cargar el comprobante
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      {showImage && (
        <div
          className={`modal fade ${showImage ? "show" : ""}`}
          id="imageModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: showImage ? "block" : "none" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowImage(false)}
                  aria-label="Close"
                ></button>
              </div>
              <div className={`modal-body ${formulario.containermodal}`}>
                <img
                  src={tickettb}
                  alt="Comprobante adjunto"
                  className={formulario.imagenmodal}
                />
              </div>
            </div>
          </div>
        </div>
      )}{" "}

    </div>
  );
}
