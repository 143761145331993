import dashboardStyle from "../styles/Dashboard.module.css";
 import DepositoBancario from "./MetodosDePago/DepositoBancario";
import Efectivo from "./MetodosDePago/Efectivo";
import PagoFacil from "./MetodosDePago/PagoFacil";
import TarjetaDeCredito from "./MetodosDePago/TarjetaDeCredito";
import TarjetaDeDebito from "./MetodosDePago/TarjetaDeDebito";
import MercadoPago from "./MetodosDePago/MercadoPago";
import TransferenciaBancaria from "./MetodosDePago/TransferenciaBancaria";
import { useDataContext } from "../contexts/AuthContext";
import { useState, useEffect } from "react";
import { getPaymentInformation } from '../services/api'
function Modal3({ toggleModal3,onFormularioEnviado }){
  const { contextData } = useDataContext();
  const [paymentMethod, setPaymentMethod] = useState("");
  const [formularioVisible, setFormularioVisible] = useState(null);
  
  useEffect(() => {
    if(!contextData ||!contextData.customer|| !contextData.customer.id){
      return;
    }

    getPaymentInformation(contextData.customer.id)
      .then((data) => {
        console.log("Datos de pago:", data);
        setPaymentMethod(data.payment_method);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, [contextData.customer.id]);
  

  useEffect(() => {
    if(!paymentMethod){
      return
    }
    // Lógica para mostrar el formulario correspondiente
    switch (paymentMethod) {
      case "Depósito Bancario":
        setFormularioVisible(
          <DepositoBancario
            toggleModal3={toggleModal3}
            onFormularioEnviado={onFormularioEnviado}
          />
        );
        break;
      case "Efectivo":
        setFormularioVisible(<Efectivo toggleModal3={toggleModal3}
          onFormularioEnviado={onFormularioEnviado}
        />);
        break;
      case "PagoFácil":
        setFormularioVisible(<PagoFacil toggleModal3={toggleModal3}
          onFormularioEnviado={onFormularioEnviado}
        />);
        break;
      case "Mercado Pago":
        setFormularioVisible(<MercadoPago />);
        break;
      case "Tarjeta de crédito":
        setFormularioVisible(<TarjetaDeCredito toggleModal3={toggleModal3} />);
        break;
      case "Tarjeta de débito":
        setFormularioVisible(<TarjetaDeDebito toggleModal3={toggleModal3}/>);
        break;
      case "Transferencia Bancaria":
        setFormularioVisible(<TransferenciaBancaria toggleModal3={toggleModal3} />);
        break;
      default:
        setFormularioVisible(<p>Seleccione un método de pago.</p>);
        break;
    }
  }, [paymentMethod]);
  return(
    <div className={dashboardStyle.modalBackground}>
      <div className={dashboardStyle.modal3}>
      {formularioVisible}
      
      {/* <button onClick={toggleModal3}>Cerrar</button> */}
      </div>
    </div>
  )
}
export default Modal3;