import React, { useContext, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDataContext } from "../contexts/AuthContext";
import { userLogin } from "../services/api";

export default function Login() {
  const { setContextData } = useDataContext();
  const [loginData, setLoginData] = useState({
    loginCode: "",
    dni: "",
  });
  const [errorMessages, setErrorMessages] = useState({
    loginCode: "",
    dni: "",
    general: "",
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let errorMessage = "";

    if (name === "dni" && (!/^\d{0,8}$/.test(value) || value.length !== 8)) {
      errorMessage = "El DNI debe ser un número de 8 dígitos.";
    }

    setLoginData({
      ...loginData,
      [name]: value,
    });

    setErrorMessages({
      ...errorMessages,
      [name]: errorMessage,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!loginData.loginCode || !loginData.dni) {
      setErrorMessages({
        ...errorMessages,
        general: "Todos los campos son obligatorios",
      });
      return;
    }

    const data = {
      loginCode: loginData.loginCode,
      dni: loginData.dni,
    };

    console.log(data);

    userLogin(data)
      .then((data) => {
        console.log("Respuesta del servidor:", data);

        if (data.error) {
          // Si el servidor devuelve un error, muestra un mensaje al usuario
          setErrorMessages({
            ...errorMessages,
            general: "Código de acceso o contraseña incorrectos.",
          });
        } else {
          // Si el inicio de sesión es exitoso, limpia los mensajes de error y redirige
          setErrorMessages({
            loginCode: "",
            dni: "",
            general: "",
          });
          setContextData(data.token);
          navigate("/terminos");
        }
      })
      .catch((error) => {
        console.error("Error al enviar la solicitud:", error);
        setErrorMessages({
          ...errorMessages,
          general: "Código de acceso o contraseña incorrectos.",
        });
      });
  };

  const handleRegistro = () => {
    navigate("/registro");
  };

  return (
    <>
      <div className="contenedorFormulario">
        <form onSubmit={handleSubmit} className="login-background inputs">
          <div className="nombreDelDivConLittle">
            <h1 className="tituloPrincipal">
              SI NO ES <span style={{ color: "#d54ab7" }}>LITTLE</span> ES COPIA
            </h1>
          </div>
          <div className="input-container">
            {errorMessages.general && (
              <div className="alert alert-danger mb-3" role="alert">
                {errorMessages.general}
              </div>
            )}
          </div>
          <div className="estiloDiv">
            <label htmlFor="loginCode">
              <i className="bi bi-person-fill icono "></i>
            </label>
            <div className="input-container">
              <input
                type="text"
                id="loginCode"
                name="loginCode"
                value={loginData.loginCode}
                onChange={handleChange}
                placeholder="Código de acceso"
                className="customInput"
                required
              />
              <div className="invalid-feedback d-block">
                {errorMessages.loginCode}
              </div>
            </div>
          </div>
          <div className="estiloDiv">
            <label htmlFor="dni">
              <i className="bi bi-file-lock2 icono "></i>
            </label>
            <div className="input-container">
              <input
                type="text"
                id="dni"
                name="dni"
                value={loginData.dni}
                onChange={handleChange}
                placeholder="Ingresá tu DNI"
                className="customInput"
                required
              />
              <div className="invalid-feedback d-block">
                {errorMessages.dni}
              </div>
            </div>
          </div>
          <button className="btn mt-3 boton4" type="submit">
            Ingresar
          </button>
          <button
            className="btn mt-3 boton3"
            type="button"
            onClick={handleRegistro}
          >
            Crear Cuenta
          </button>
        </form>
      </div>
    </>
  );
}
