import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDataContext } from "../contexts/AuthContext";
import { userLogin } from "../services/api";

import "../styles/estilos/login.css";

export default function LoginEnriched() {
  const { setContextData } = useDataContext();

  const [loginData, setLoginData] = useState({
    loginCode: "",
    dni: "",
  });



  const [mensajesError, setMensajesError] = useState({
    dni: "",
    camposIncompletos: "",
  });

  const navigate = useNavigate();
  const { accessCode } = useParams();

  useEffect(() => {
    if(!accessCode){
      return
    }
    if (accessCode) {
      setLoginData((prevLoginData) => ({
        ...prevLoginData,
        loginCode: accessCode,
      }));
    }
  }, [accessCode]);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    let mensajeError = "";

    // Validación del DNI
    if (name === "dni" && !/^\d{8}$/.test(value)) {
      mensajeError = "El DNI debe ser solo un número de 8 dígitos.";
    }

    setLoginData((prevLoginData) => ({
      ...prevLoginData,
      [name]: value,
    }));

    setMensajesError({
      ...mensajesError,
      [name]: mensajeError,
      camposIncompletos: "", 
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validar que todos los campos estén completos
    const camposIncompletos = Object.values(loginData).some((value) => value === "");

    if (camposIncompletos) {
      setMensajesError({
        ...mensajesError,
        camposIncompletos: "Por favor, complete todos los campos.",
      });
      return;
    }

    // Validación del DNI antes de enviar
    if (mensajesError.dni) {
      // Manejar DNI inválido
      console.log("DNI inválido");
      return;
    }

    const datos = {
      loginCode: loginData.loginCode,
      dni: loginData.dni,
    };

    console.log(datos);

    userLogin(datos)
    
    
    .then((data) => {
      console.log("Respuesta del servidor:", data);
      setContextData(data.token);
      navigate("/terminos");
    })
    .catch((error) => {
      if (error.message === "401") {
        // Error de credenciales inválidas (DNI incorrecto)
        setMensajesError({
          ...mensajesError,
          dni: "DNI incorrecto",
        });
      } else {
        console.error("Error al enviar la solicitud:", error);
        setMensajesError((prevMensajesError) => ({
          ...prevMensajesError,
          dni: "DNI incorrecto",
        }));
      }
    });
};
  return (
    <>
      <form onSubmit={handleSubmit} className="login-background inputs">
        <input
          type="hidden"
          id="loginCode"
          name="loginCode"
          value={loginData.loginCode}
          onChange={handleChange}
          placeholder={loginData.loginCode}
          className="customInput"
        />
        <div className="nombreDelDivConLittle mb-5">
        <h1 className="tituloPrincipaldos">
            SI NO ES <span style={{ color: "#d54ab7" }}>LITTLE</span> ES COPIA
          </h1>
        </div>

        <div className="estiloDiv">
          <label htmlFor="dni">
            <i className="bi bi-file-lock2 icono"></i>
          </label>
          <input
            type="text"
            id="dni"
            name="dni"
            value={loginData.dni}
            onChange={handleChange}
            placeholder="Ingresa tu DNI"
            className="customInput"
            required

          />
        </div>
        <div className="invalid-feedback d-flex justify-content-center">{mensajesError.dni}</div>

        {mensajesError.camposIncompletos && (
          <div className="alert alert-danger mb-3" role="alert">
            {mensajesError.camposIncompletos}
          </div>
        )}

        <button className="btn mt-3 boton" type="submit">
          Iniciar Sesión
        </button>
      </form>
    </>
  );
}

