// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/src/fonts/Gotham Bold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("/src/fonts/Gotham-Light.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("/src/fonts/AzeretMono-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("/src/fonts/AzeretMono-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("/src/fonts/Gotham Book.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("/src/fonts/Gotham Medium.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("/src/fonts/vinila-extended-xbold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("/src/fonts/vinilaExtendedBold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("../../fonts/VinilaTest-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_9___ = new URL("../../../src/fonts/Vinila Variable.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'Gotham Bold';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
  }
  @font-face {
    font-family: 'Gotham-Light';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
  }
  @font-face {
    font-family: 'Azeret_Mono_Bold';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
  }
  @font-face {
    font-family: 'Azeret_Mono_regular';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('truetype');
  }
  @font-face {
    font-family: 'Gotham Book';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('truetype');
  }
  @font-face {
    font-family: 'Gotham Medium';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('truetype');
  }
  @font-face {
    font-family: 'Vinila Extended ExtraBold';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format('truetype');
  }
  @font-face {
    font-family: 'Vinila Extended Bold';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format('truetype');
  }
  @font-face {
    font-family: 'Vinila Variable Test Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Vinila Variable Test Regular'), url(${___CSS_LOADER_URL_REPLACEMENT_8___}) format('woff');
    }
    @font-face{
      font-family: 'Vinila Variable';
      font-style: normal;
      font-weight: normal;
      src:url(${___CSS_LOADER_URL_REPLACEMENT_9___})format('truetype');
    }`, "",{"version":3,"sources":["webpack://./src/styles/estilos/fotnts.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,+DAA0D;EAC5D;EACA;IACE,2BAA2B;IAC3B,+DAA0D;EAC5D;EACA;IACE,+BAA+B;IAC/B,+DAA6D;EAC/D;EACA;IACE,kCAAkC;IAClC,+DAAgE;EAClE;EACA;IACE,0BAA0B;IAC1B,+DAAyD;EAC3D;EACA;IACE,4BAA4B;IAC5B,+DAA2D;EAC7D;EACA;IACE,wCAAwC;IACxC,+DAAmE;EACrE;EACA;IACE,mCAAmC;IACnC,+DAAgE;EAClE;EACA;IACE,2CAA2C;IAC3C,kBAAkB;IAClB,mBAAmB;IACnB,kGAAqG;IACrG;IACA;MACE,8BAA8B;MAC9B,kBAAkB;MAClB,mBAAmB;MACnB,6DAAmE;IACrE","sourcesContent":["@font-face {\n    font-family: 'Gotham Bold';\n    src: url('/src/fonts/Gotham\\ Bold.otf') format('truetype');\n  }\n  @font-face {\n    font-family: 'Gotham-Light';\n    src: url('/src/fonts/Gotham-Light.otf') format('truetype');\n  }\n  @font-face {\n    font-family: 'Azeret_Mono_Bold';\n    src: url('/src/fonts/AzeretMono-Bold.ttf') format('truetype');\n  }\n  @font-face {\n    font-family: 'Azeret_Mono_regular';\n    src: url('/src/fonts/AzeretMono-Regular.ttf') format('truetype');\n  }\n  @font-face {\n    font-family: 'Gotham Book';\n    src: url('/src/fonts/Gotham Book.otf') format('truetype');\n  }\n  @font-face {\n    font-family: 'Gotham Medium';\n    src: url('/src/fonts/Gotham Medium.otf') format('truetype');\n  }\n  @font-face {\n    font-family: 'Vinila Extended ExtraBold';\n    src: url('/src/fonts/vinila-extended-xbold.otf') format('truetype');\n  }\n  @font-face {\n    font-family: 'Vinila Extended Bold';\n    src: url('/src/fonts/vinilaExtendedBold.otf') format('truetype');\n  }\n  @font-face {\n    font-family: 'Vinila Variable Test Regular';\n    font-style: normal;\n    font-weight: normal;\n    src: local('Vinila Variable Test Regular'), url('../../fonts/VinilaTest-Regular.woff') format('woff');\n    }\n    @font-face{\n      font-family: 'Vinila Variable';\n      font-style: normal;\n      font-weight: normal;\n      src:url('../../../src/fonts/Vinila Variable.ttf')format('truetype');\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
