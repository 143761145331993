import waitingTermsStyles from '../styles/estilos/waitingTerms.module.css'
import logoPng from '../images/Frame.png'
import {useNavigate } from "react-router-dom";

const WaitingTerms= ()=>{

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-2); 
  };

  return(
    <div className={waitingTermsStyles.messageContainer}>
      <img src={logoPng} alt="littleBlueLogo" className={waitingTermsStyles.img}/>
      <h2 className='text-muted'>Esperando a que el Tutor acepte <br /> los términos y condiciones...</h2>
      <div className={waitingTermsStyles.loader}></div>
      <div>
        <button className={waitingTermsStyles.botonAtras} onClick={handleGoBack}>volver</button>
      </div>
    </div>
  )
};
export default WaitingTerms;