import { useNavigate } from "react-router-dom";
import homeStyle from "../styles/Home.module.css";

function LogOut(){
  const navigate = useNavigate()
  const handleLogOut = () => {
    fetch("http://localhost:3005/api/logOut", {
      method: "POST",
      credentials: "include", //cookies?
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("error al cerrar sesion");
        }
        console.log("sesion cerrada");
        navigate("/")
      })
      .catch((error) => {
        console.log("Error en la solicitud de cerrar sesion");
      });
  };
  return(
    <button className={homeStyle.boton} onClick={handleLogOut}>
            Cerrar sesion
          </button>
  )
};
 export default LogOut;