import infoStyles from "../../styles/estilos/infoMetodos.module.css"

function TarjCreditoInfo(){
  return(
    <div className={infoStyles.infoContainer}>
      <div className={infoStyles.title}>TARJETA DE CREDITO</div>
      <div className={infoStyles.firstP}>
    <p className={infoStyles.number}>1</p>
    <p className={infoStyles.text}>Podés abonar a través de getnet con <br/>
cualquier tarjeta de cualquier banco.<br/>
PEDILE EL LINK A TU ASESOR/A.</p>
    </div>

    <div className={infoStyles.firstP}>
  <p className={infoStyles.number}>2</p>
  <p className={infoStyles.text}>Ingresa los datos de la tarjeta y elegí <br/>
la financiacion que prefieras. <br/>
(Al inicio estan los planes AHORA3,6 y 12)</p>
</div>

<div className={infoStyles.firstP}>
  <p className={infoStyles.number}>3</p>
  <p className={infoStyles.text}>
  Ingresa el mail donde vas a recibir el <br/>
comprobante, luego presiona pagar.
  </p>
</div>

<div className={infoStyles.firstP}>
  <p className={infoStyles.number}>4</p>
  <p className={infoStyles.text}>
  Una vez que tengas el comprobante de pago,<br/>
   Vamos a necesitar que lo informes en Mi Cuenta Blue.<br/> 
Recorda que es IMPORTANTE que se vea el ID de transaccion <br/>
y que el comprobante se vea completo. 

  </p>
</div>

    </div>
  )
}
export default TarjCreditoInfo;