import React from 'react';
import tallesStyle from "../styles/estilos/Talles.module.css";
import guideStyle from "../styles/estilos/guide.module.css";
import fantasma from "../images/fantasmaOpinion.png";
import remera30 from "../images/remeras/remera30.png";
import remera31 from "../images/remeras/remera31.png";
import remera38 from "../images/remeras/remera38.png";
import remera39 from "../images/remeras/remera39.png";
import remera32 from "../images/remeras/remera32.png";
import remera33 from "../images/remeras/remera33.png";
import remera34 from "../images/remeras/remera34.png";
import remera36 from "../images/remeras/remera36.png";

const GuiaRemera6 = ({ onClose, onNext,onBack }) =>{ 

  return(
  
  <div className={tallesStyle.modalBackground}>
   <div className={tallesStyle.modalG1}>
    <div className={tallesStyle.modalContent}></div>
    <div className={guideStyle.cellContent}>
   <div className={guideStyle.tipografiascontainer}>
    <h6> TIPOGRAFÍAS DE <span className={guideStyle.blue} > BORDADO</span> </h6>
    <img className={guideStyle.tipografias} src={remera38} alt="tipografias de aplique"/>
    <h6> TIPOGRAFÍAS DE <span className={guideStyle.blue} > ESTAMPADO</span> </h6>
    <img className={guideStyle.tipografias} src={remera39} alt="tipografias de aplique"/>
    <div className={guideStyle.buttonContainer}>
      <button className={guideStyle.one}  onClick={onClose}>Cerrar</button>
      <button className={guideStyle.two} onClick={onBack}>Atras</button>
      <button className={guideStyle.two} onClick={onNext}>Siguiente</button>
    </div>
    </div>
    </div>
    {/**desk */}
    <div className={guideStyle.deskContent}>
      <section className={guideStyle.first3}>
      <div className={guideStyle.number1}>
      <h2 className={guideStyle.num}>8</h2>
      <p> LLEVA TIRAS APLICADAS?</p>
    </div>

    <div className={guideStyle.announce}>
      <p>RECORDA PONER LA UBICACION DE LAS TIRAS Y LA CANTIDAD</p>
      <img  className={guideStyle.fantasma} src={fantasma} alt="fantasma"/>
    </div>
   
    <div className={guideStyle.container} >
     <div className={guideStyle.modelImages}>

       <div className={guideStyle.category}>
         <p>SI</p>
         <img src={remera30} alt="remera"/>
       </div>

       <div className={guideStyle.category}>
         <p>NO</p>
         <img src={remera31} alt="remera"/>
       </div>

     </div>
    </div>

      </section>
      <section className={guideStyle.second3}>
      <div className={guideStyle.number}>
      <h2 className={guideStyle.num}>9</h2>
      <p> LLEVA APLIQUES?</p>
    </div>

    <div className={guideStyle.announce}>
      <p> <span className={guideStyle.underline} >RECTO: </span> TERMINACION BORDADA CON HILO</p>  
      <p> <span className={guideStyle.underline} >RÚSTICO:</span> TERMINACION EN TELA DEFLECADA CON PESPUNTE</p>
      <img  className={guideStyle.fantasma} src={fantasma} alt="fantasma"/>
    </div>
   
    <div className={guideStyle.container} >
     <div className={guideStyle.modelImages}>

       <div className={guideStyle.category}>
         <p>RECTO</p>
         <img src={remera32} alt="remera"/>
       </div>

       <div className={guideStyle.category}>
         <p>Rústico</p>
         <img src={remera33} alt="remera"/>
       </div>

       <div className={guideStyle.category2}>
         <p>RÚSTICO <br/> DOBLE TELA</p>
         <img src={remera34} alt="remera"/>
       </div>

       <div className={guideStyle.category}>
         <p>ESTAMPADO</p>
         <img src={remera36} alt="remera"/>
       </div>

     </div>
    </div>
   
      </section>
    <div className={guideStyle.buttonContainer6}>
      <button className={guideStyle.one}  onClick={onClose}>Cerrar</button>
      <button className={guideStyle.two} onClick={onBack}>Atras</button>
      <button className={guideStyle.two} onClick={onNext}>Siguiente</button>
    </div>
    </div>
  </div>
  </div>
   );
 };
  export default GuiaRemera6;