// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!./fotnts.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.waitingTerms_messageContainer__9ftT5{
    text-align: center;
    font-family: "Vinila Variable Test Regular", sans-serif;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; 
}

.waitingTerms_img__jjQP-{
    height: 45px;
    margin-bottom: 20px;
}

.waitingTerms_loader__O0STm {
    margin-top: 20px;
    height: 4px;
    width: 130px;
    --c:no-repeat linear-gradient(#0071CE 0 0);
    background: var(--c),var(--c),#8ac9fc;
    background-size: 60% 100%;
    animation: waitingTerms_l16__Heo04 3s infinite;
  }
  @keyframes waitingTerms_l16__Heo04 {
    0%   {background-position:-150% 0,-150% 0}
    66%  {background-position: 250% 0,-150% 0}
    100% {background-position: 250% 0, 250% 0}
  }
  .waitingTerms_botonAtras__jPoQc{
    border: #e658c5 solid 1px;
    border-radius: 10px;
    margin-top: 3rem;
    width: 78px;
    height: 34px;
    font-size: 19px;
    color: white;
    background-color: #e658c5;
    box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.25);

    }
    .waitingTerms_botonAtras__jPoQc:hover {
      background-color: #e540be;
}
`, "",{"version":3,"sources":["webpack://./src/styles/estilos/waitingTerms.module.css"],"names":[],"mappings":"AAEA;IACI,kBAAkB;IAClB,uDAAuD;IACvD,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,YAAY;IACZ,0CAA0C;IAC1C,qCAAqC;IACrC,yBAAyB;IACzB,8CAA0B;EAC5B;EACA;IACE,MAAM,mCAAmC;IACzC,MAAM,mCAAmC;IACzC,MAAM,mCAAmC;EAC3C;EACA;IACE,yBAAyB;IACzB,mBAAmB;IACnB,gBAAgB;IAChB,WAAW;IACX,YAAY;IACZ,eAAe;IACf,YAAY;IACZ,yBAAyB;IACzB,2CAA2C;;IAE3C;IACA;MACE,yBAAyB;AAC/B","sourcesContent":["@import url(\"./fotnts.css\");\n\n.messageContainer{\n    text-align: center;\n    font-family: \"Vinila Variable Test Regular\", sans-serif;\n    font-weight: 300;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    height: 100vh; \n}\n\n.img{\n    height: 45px;\n    margin-bottom: 20px;\n}\n\n.loader {\n    margin-top: 20px;\n    height: 4px;\n    width: 130px;\n    --c:no-repeat linear-gradient(#0071CE 0 0);\n    background: var(--c),var(--c),#8ac9fc;\n    background-size: 60% 100%;\n    animation: l16 3s infinite;\n  }\n  @keyframes l16 {\n    0%   {background-position:-150% 0,-150% 0}\n    66%  {background-position: 250% 0,-150% 0}\n    100% {background-position: 250% 0, 250% 0}\n  }\n  .botonAtras{\n    border: #e658c5 solid 1px;\n    border-radius: 10px;\n    margin-top: 3rem;\n    width: 78px;\n    height: 34px;\n    font-size: 19px;\n    color: white;\n    background-color: #e658c5;\n    box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.25);\n\n    }\n    .botonAtras:hover {\n      background-color: #e540be;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"messageContainer": `waitingTerms_messageContainer__9ftT5`,
	"img": `waitingTerms_img__jjQP-`,
	"loader": `waitingTerms_loader__O0STm`,
	"l16": `waitingTerms_l16__Heo04`,
	"botonAtras": `waitingTerms_botonAtras__jPoQc`
};
export default ___CSS_LOADER_EXPORT___;
