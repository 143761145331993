import React, { useState } from 'react';
import arrow from "../images/flecha-magenta.png"
import faqStyle from "../styles/estilos/faq.module.css"
import descargas from "../images/descarga.png"

function FAQ() {
  const baseURL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3005'
  const [isOpen, setIsOpen] = useState([false, false, false, false, false, false]);
  const toggleOpen = (index) => {
    const newIsOpen = [...isOpen];
    newIsOpen[index] = !newIsOpen[index];
    setIsOpen(newIsOpen);
  };
  
  const[detalleAbierto,setDetalleAbierto]=useState(false)
  const openDetalle=()=>{
   setDetalleAbierto(!detalleAbierto);
  }
  const[pedidosOpen,setPedidosOpen]=useState(false)
  const openPedido=()=>{
    setPedidosOpen(!pedidosOpen)
  }
  const[tallesOpen,setTallesOpen]=useState(false)
  const openTalles=()=>{
    setTallesOpen(!tallesOpen)
  }
    
    const[entregaOpen,setEntregaOpen]=useState(false)
  const openEntrega=()=>{
    setEntregaOpen(!entregaOpen)
  }

  const[postOpen,setPostOpen]=useState(false)
  const openPost=()=>{
    setPostOpen(!postOpen)
  }
  return (
    <div  className={faqStyle.mainContainer}>
      <details className={`${faqStyle.details} ${isOpen[0] ? faqStyle.open : ''}`}>
        <summary  className={faqStyle.one}  onClick={openDetalle} >
          USUARIOS
          <img src={arrow} alt="" className={detalleAbierto ? faqStyle.arrowRotate : ''} /> 
        </summary>
        

        <section className={faqStyle.sections}>
          <h3 className={faqStyle.subtitle}>DELEGADO</h3>
          <p className={faqStyle.text}>El delegado del curso es el alumno/a que habla en
               representación del curso.
          </p>
          <h3 className={faqStyle.subtitle}>TUTOR</h3>
          <p className={faqStyle.text}>Según el código civil, no podemos comerciar con personas
          menores de edad, por eso necesitamos un mayor de edad que acepte los términos y condiciones en representación del curso. Idealmente que sea un tutor,
          padre o madre del curso ya que va a ser el nexo entre nosotros y el resto del grupo.</p>
        </section>
        <section>
          <h2 className={faqStyle.titleOne}>CARGA DE USUARIOS</h2>
          <h3 className={faqStyle.subtitle}>MASIVA</h3>
          <p className={faqStyle.text}>Les enviamos un modelo de Excel donde colocaran: 
           NOMBRE, APELLIDO Y CELULAR de cada alumno que desee hacer sus prendas.
            Una vez completo nos envían el excel para poder cargarlo al sistema y generar los usuarios. 
             Les estaremos enviando el USUARIO y CONTRASEÑA de cada uno.</p>
          <h3 className={faqStyle.subtitle}>INDIVIDUAL</h3>
          <p className={faqStyle.text}>Les enviamos el código del curso, para que puedan ingresar y CREAR SU CUENTA BLUE.
             Puedan generarse cada uno su usuario y dni e ingresar sus datos. 
            No deben tener mas de un usuario por alumno para no duplicar las cuentas.
         </p>
        </section>
      </details>

      <details className={`${faqStyle.details} ${isOpen[1] ? faqStyle.open : ''}`}>
        <summary className={faqStyle.two} 
        onClick={openPedido}>
          PEDIDOS
          <img src={arrow} alt="" className={pedidosOpen ? faqStyle.arrowRotate : ''} />
        </summary>
        <section>
          <h3 className={faqStyle.subtitle}>¿Cuál es el numero de pedido?</h3>
          <p className={faqStyle.text}>El número de pedido es un número compuesto de 6 dígitos  Los dos primeros son el año en curso + cuatro numeros dados por tu asesor. Por ejemplo: 240123. 
         Este número se encuentra en la pantalla principal, en color rosa, debajo de la leyenda numero de pedido.  Tengan su número de pedido siempre a mano ya que se los vamos
         a pedir para resolver sus dudas.</p>
        </section>
        <section>
          <h2>Etapas de la producción</h2>
          <h3 className={faqStyle.subtitle}>Pre- producción</h3>
          <p className={faqStyle.text}  >Las prendas aún no iniciaron producción. 
            Deben completar los requisitos (guía, boceto y talle) y abonar las primeras cuotas.</p>
          <h3 className={faqStyle.subtitle}>Producción</h3>
          <p className={faqStyle.text}>Una vez confirmado los bocetos, los talles y al menos el 50% del dinero abonado, se inicia la  producción.
          Es importante que sigan avanzando con el resto de los pagos para no sufrir demoras en la producción. 
          Los días de producción son los pactados al momento de la venta.</p>
          <h3 className={faqStyle.subtitle}>Control de calidad</h3>
          <p className={faqStyle.text}>En esta etapa revisamos que las prendas esten en condiciones óptimas para ser
             entregadas. 
         Para comenzar a coordinar la entrega debe estar abonada la totalidad del pedido y 
         haber seleccionado el modo de entrega. </p>
         <h3 className={faqStyle.subtitle}>Entrega</h3>
         <p className={faqStyle.text}>Las prendas fueron enviadas. Si aún no recibiste los paquetes, estos todavía están en viaje.
         Dentro de AMBA el envió puede demorar hasta 3 días hábiles.
          Para el interior del país los envíos se realizan a través de Correo Argentino, los envíos pueden demorar hasta 5 días hábiles.</p>
        </section>
      </details>

      <details className={`${faqStyle.details} ${isOpen[2] ? faqStyle.open : ''}`}>
        <summary className={faqStyle.three}  onClick={openTalles}>
          TALLES
          <img src={arrow} alt="" className={tallesOpen ? faqStyle.arrowRotate : ''} />
        </summary>
        <section>
          
          <p className={faqStyle.text}>En Little Blue usamos una molderia desarrollada por  nosotros,
             con una curva de talles super amplia que va del talle 0 al talle 12.
            En caso de necesitar una medida especial, consúltalo con tu asesor/a de atención al cliente,
            para que no te quedes sin tu Little.</p>
        </section>
      </details>  

      <details className={`${faqStyle.details} ${isOpen[3] ? faqStyle.open : ''}`}>
        <summary className={faqStyle.four}  onClick={openEntrega}>
          ENTREGA
          <img src={arrow} alt="" className={entregaOpen ? faqStyle.arrowRotate : ''} />
        </summary>
        <section>
          <p className={faqStyle.text}>La entrega de las prendas se realizan todas juntas.
           En la sección ENTREGA van a completar si quieren pasar a retirar las prendas por nuestra oficina en San Isidro (opción más rápida)
          o pueden seleccionar la opción de envió a domicilio. </p>
        </section>
        <section>
          <h3 className={faqStyle.subtitle}>Seguimiento de Envio</h3>
          <p className={faqStyle.text}>El despacho de las prendas se realiza por correo argentino, en cajas. Cada caja cuenta con un número de guía para poder hacer el seguimiento. Cuando el asesor/a les avise que salió su pedido 
            les va a compartir el o los códigos de seguimiento.</p>
            <h3 className={faqStyle.subtitle}>Pasos a seguir</h3>
            <p className={faqStyle.text} >Ingresan en la página de  <a href="http://www.correoargentino.com.ar/" target="_blank">Correo Argentino</a> .

              Seleccionan la opción "Seguimiento de Envíos"
              Luego seleccionan la opción T&T "Correspondencia con origen y destino Nacional"
               Completan el número de guía y listo</p>
         </section>
      </details>

      <details className={`${faqStyle.details} ${isOpen[4] ? faqStyle.open : ''}`}>
        <summary className={faqStyle.five} onClick={openPost}>
          POST VENTA
          <img src={arrow} alt="" className={postOpen ? faqStyle.arrowRotate : ''}/>
        </summary>
        <section>
          <h3 className={faqStyle.subtitle}>Recomendanos</h3>
          <p className={faqStyle.text}>Habla con los chicos de la Promo siguiente y contales como fue el proceso para que sepan como trabajamos, también podes enviarles nuestro Instagram:<a href="https://www.instagram.com/littlebluebuzos/">littlebluebuzos</a>, para que vean las novedades y colecciones 
            que armamos especialmente para cada Promo.</p>
        
          <h3 className={faqStyle.subtitle}>Califícanos</h3>
          <p className={faqStyle.text}>Pueden calificarnos a través de Google:
          
         <a href="https://www.google.com/search?hl=es-AR&gl=ar&q=Little+Blue,+Colectora+Panamericana+1807,+B1607+San+Isidro,+Provincia+de+Buenos+Aires&ludocid=18182758573834736637&lsig=AB86z5UW23ZbmXeSIw4kWXYMUS-F#lrd=0x95bcb137e5fdb26d:0xfc562296261b93fd,3" target="_blank"> calificanos</a> para contarnos su Experiencia Litlle o dentro de la cuenta Blue.
            Todas las reseñas son bienvenidas para poder mejorar como empresa año a año.</p>
        </section>
      </details>

      <details className={`${faqStyle.details} ${isOpen[5] ? faqStyle.open : ''}`}>
        <summary  className={faqStyle.six} onClick={toggleOpen}>
          CONTRATO
          <img src={arrow} alt="arrow"  className={isOpen[5] ? faqStyle.arrowRotate : ''}/>
        </summary>

        <section className={faqStyle.contrato} >
       <img src={descargas} alt=""/> 
      <a href="/documentos/terms.pdf" download>Descargar <br/>contrato</a>
        </section>
      </details>
</div>

  )

};

export default FAQ;