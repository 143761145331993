import prendasStyle from "../styles/Prendas.module.css";
import GroupAprobado from "../images/manoOk.png";
import Danger from "../images/Danger.png";
function AprobarModal(props) {
  const handleCloseModal = () => {
    props.onClose();
  };

  return (
    <div className={prendasStyle.modalBackground}>
      <div className={prendasStyle.modalA}>
        <img className={prendasStyle.danger} src={Danger} alt="Danger" />
        <div className={prendasStyle.modalContent}>
          <div className={prendasStyle.containermodal}>
           
            <img className={prendasStyle.manook} src={GroupAprobado} alt="Aprobado" />
            <h1 className={prendasStyle.boceto}>BOCETO APROBADO</h1>
            <p className={prendasStyle.parrafo}>
              Recorda que a partir de este momento, <br/> ya no se pueden realizar
              cambios!
            </p>
            <div>
            <button className={prendasStyle.cerrarmodalboceto} onClick={handleCloseModal}>
             cerrar
            </button>
            </div>
            </div>
        </div>
      </div>
    </div>
  );
}
export default AprobarModal;
