import DepositoBancario from "./DepositoBancario";
import Efectivo from "./Efectivo";
import PagoFacil from "./PagoFacil";
import TarjetaDeCredito from "./TarjetaDeCredito";
import TarjetaDeDebito from "./TarjetaDeDebito";
import MercadoPago from "./MercadoPago";
import TransferenciaBancaria from "./TransferenciaBancaria";
import { useDataContext } from "../../contexts/AuthContext";
import { useState, useEffect } from "react";
import { getPaymentInformation } from "../../services/api";

export default function InformPayment() {
  const { contextData } = useDataContext();
  const [paymentMethod, setPaymentMethod] = useState("");
  const [formularioVisible, setFormularioVisible] = useState(null);

  useEffect(() => {
    const fetchPaymentInformation = async () => {
      try {
        const data = await getPaymentInformation(contextData.customer.id);
        console.log("Datos de pago:", data);
        setPaymentMethod(data.payment_method);
      } catch (error) {
        console.log(
          `Error en la solicitud de información de pago: ${error.message}`
        );
      }
    };

    fetchPaymentInformation();
  }, [contextData.customer.id]);

  useEffect(() => {
    // Lógica para mostrar el formulario correspondiente
    switch (paymentMethod) {
      case "Depósito Bancario":
        setFormularioVisible(<DepositoBancario />);
        break;
      case "Efectivo":
        setFormularioVisible(<Efectivo />);
        break;
      case "PagoFácil":
        setFormularioVisible(<PagoFacil />);
        break;
      case "Mercado Pago":
        setFormularioVisible(<MercadoPago />);
        break;
      case "Tarjeta de crédito":
        setFormularioVisible(<TarjetaDeCredito />);
        break;
      case "Tarjeta de débito":
        setFormularioVisible(<TarjetaDeDebito />);
        break;
      case "Transferencia Bancaria":
        setFormularioVisible(<TransferenciaBancaria />);
        break;
      default:
        setFormularioVisible(<p>Seleccione un método de pago.</p>);
        break;
    }
  }, [paymentMethod]);

  return (
    <div>
      {/* Mostrar el formulario seleccionado */}
      {formularioVisible}
    </div>
  );
}
