import enviosStyle from "../styles/estilos/envios.module.css";
import { useState, useEffect } from "react";
import camion from "../images/camion.png";

import arrow from "../images/flecha-magenta.png";
import ModalMap from "./ModalMap";
import modalStyle from "../styles/estilos/modalMapStyle.module.css";
import asesor from "../images/fantasmita-asesor.png";
import markerIcon from "../images/marker-icon.png";
import { useDataContext } from "../contexts/AuthContext";
import {
  saveAddress,
  getAllProvinces,
  getDepartments,
  getDistricts,
  getCurrentAddress,
  getSelectedDirection,
  getProvinceById,
  getDepartmentById,
  getDistrictById,
  sendPickUpAtOffice,
  getOrderItemStatus,
} from "../services/api.js";
import Swal from "sweetalert2";
import FormularioAutocompletado from "./FormularioAutocompletado.jsx";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { getPrendasTutor } from "../services/api";

function Entregas() {
  

  //logica del checkbox
  const [contentToShow, setContentToShow] = useState(null);
  const [nombre, setNombre] = useState("");
  const [telefono, setTelefono] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [provincias, setProvincias] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [localidades, setLocalidades] = useState([]);

  const [provinciaSeleccionada, setProvinciaSeleccionada] = useState("");
  const [municipioSeleccionado, setMunicipioSeleccionado] = useState("");
  const [localidadSeleccionada, setLocalidadSeleccionada] = useState("");

  const [currentAddress, setCurrentAddress] = useState("");
  const [currentAddressExists, setCurrentAddressExists] = useState(false);

  const [provinciaName, setProvinciaName] = useState("");
  const [departamentoName, setDepartamentoName] = useState("");
  const [ciudadName, setCiudadName] = useState("");

  const [provinciaMap, setProvinciaMap] = useState("");
  const [departamentoMap, setDepartamentoMap] = useState("");
  const [ciudadMap, setCiudadMap] = useState("");

  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  const [direccion, setDireccion] = useState("");
  const [numero, setNumero] = useState("");
  const [codigo, setCodigo] = useState("");
  const [piso, setPiso] = useState("");
  const [mostrarModalGoogle, setMostrarModalgoogle] = useState(false);
  const [ubicacionMapa, setUbicacionMapa] = useState("");
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const { contextData } = useDataContext();
  const [progress, setProgress] = useState(0);
  const [prendas, setPrendas] = useState([]);

  // logica barra de progreso

  useEffect(() => {
    if (!contextData || !contextData.customer || !contextData.customer.id) {
      return;
    }

    const fetchData = async () => {
      try {
        let data = await getPrendasTutor(contextData.customer.lead_id);

        const prendasConOrderId = data.map((prenda) => {
          return { ...prenda, order_item_id: prenda.order_item_id };
        });

        setPrendas(prendasConOrderId);
        console.log("Prendas con order_item_id:", prendasConOrderId);
      } catch (error) {
        console.error("Error fetching prendas:", error);
      }
    };

    fetchData();
  }, [contextData.customer.lead_id]);


  // useEffect(() => {
  //   const fetchOrderItemStatus = async () => {
  //     try {
  //       const data = await getOrderItemStatus(
  //         contextData.customer.lead_id,
  //         prendas.map((prenda) => prenda.order_item_id)
  //       );
  
  //       const orderStatus = data[0].order_item_status;
  //       // Actualizamos el progreso de la barra en función del estado del pedido
  //       if (orderStatus === 200) {
  //         setProgress(25);
  //       } else if (orderStatus === 300) {
  //         setProgress(50);
  //       } else if (orderStatus === 370) {
  //         setProgress(75);
  //       } else if (orderStatus === 470) {
  //         setProgress(100);
  //       }
  //     } catch (error) {
  //       console.error("Error al obtener el estado del pedido:", error);
  //     }
  //   };
  
  //   fetchOrderItemStatus();
  // }, [contextData.customer.lead_id, prendas]);

  useEffect(() => {
    const fetchOrderItemStatus = async () => {
      try {
        // Verificar si contextData y contextData.customer están definidos
        if (!contextData || !contextData.customer || !contextData.customer.lead_id) {
          console.log("Datos del cliente no válidos. No se puede obtener el estado del pedido.");
          return;
        }
  
        // Verificar si hay prendas para procesar
        if (!prendas || prendas.length === 0) {
          console.log("No hay prendas para obtener el estado del pedido.");
          return;
        }
  
        const data = await getOrderItemStatus(
          contextData.customer.lead_id,
          prendas.map((prenda) => prenda.order_item_id)
        );
  
        // Verificar si se obtuvo correctamente el estado del pedido
        // if (!data || !data[0] || typeof data[0].order_item_status === 'undefined') {
        //   console.log("No se pudo obtener el estado del pedido.");
        //   return;
        // }
  
        const orderStatus = data[0].order_item_status;
        // Actualizar el progreso de la barra en función del estado del pedido
        if (orderStatus === 200) {
          setProgress(25);
        } else if (orderStatus === 300) {
          setProgress(50);
        } else if (orderStatus === 370) {
          setProgress(75);
        } else if (orderStatus === 470) {
          setProgress(100);
        }
      } catch (error) {
        console.error("Error al obtener el estado del pedido:", error);
      }
    };
  
    fetchOrderItemStatus();
  }, [contextData, prendas]);
  

  useEffect(() => {
    if (!contextData.customer.lead_id) {
      return;
    }
    getExistentAddress();
  }, [contextData.customer.lead_id]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          setLatitude(lat);
          setLongitude(lng);
        },
        (error) => {
          console.error(`Error obteniendo la ubicación: ${error.message}`);
          const lat = -34.61315;
          const lng = -58.37723;
          setLatitude(lat);
          setLongitude(lng);
        }
      );
    } else {
      console.error("La geolocalización no es compatible con este navegador.");
    }
  }, []);

  const getExistentAddress = async () => {
    try {
      const data = await getCurrentAddress(contextData.customer.lead_id);
      setCurrentAddress(data.currentAddress);
      setCurrentAddressExists(data.exists);
      if (data.exists === true) {
        const data = await getSelectedDirection(contextData.customer.lead_id);
        setProvinciaName(data.provincia);
        setDepartamentoName(data.departamento);
        setCiudadName(data.ciudad);
      } else {
        return;
      }
    } catch (error) {
      console.log(
        "Ha ocurrido un error al querer obtener la direccion existente",
        error
      );
    }
  };

  // Función para validar caracteres nombre
  const [nombreError, setNombreError] = useState("");
  const validarNombre = () => {
    const specialCharsRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const nameRegex = /^\S.*$/;
  
    if (!nombre.trim()) {
      setNombreError("Por favor, ingresa tu nombre y apellido");
    } else if (specialCharsRegex.test(nombre)) {
      setNombreError(
        "El nombre y apellido no pueden contener caracteres especiales"
      );
    } else if (!nameRegex.test(nombre)) {
      setNombreError("El nombre no puede comenzar con un espacio en blanco");
    } else {
      setNombreError("");
    }
  };
  

  // Función para validar el formato del número de teléfono
  const [telefonoError, setTelefonoError] = useState("");

  const validarTelefono = () => {
    const phoneRegex = /^[1-9]\d{8,}$/;
  
    if (!telefono.trim()) {
      setTelefonoError("Por favor, ingresa tu número de teléfono");
      return false; // Retorna falso si el teléfono está vacío o solo contiene espacios en blanco
    } else if (!phoneRegex.test(telefono)) {
      setTelefonoError("El formato del número de teléfono es incorrecto");
      return false; // Retorna falso si el formato del teléfono es incorrecto
    } else {
      setTelefonoError("");
      return true; // Retorna verdadero si la validación fue exitosa
    }
  };
  
  
  //validar telefono
  const [codigoError, setCodigoError] = useState("");
  const validarCodigo = () => {
    const cpRegex = /^[0-9]+$/;

    if (!codigo.trim()) {
      setCodigoError("Por favor, ingresa el código postal");
    } else if (!cpRegex.test(codigo)) {
      setCodigoError("El código postal solo puede contener números");
    } else {
      setCodigoError("");
    }
  };
  //validar direccion
  const [direccionError, setDireccionError] = useState("");

  const validarDireccion = () => {
    const specialCharsRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const minLetters = 5; // Cambia este valor según tus requisitos

    if (!direccion.trim()) {
      setDireccionError("Por favor, ingresa tu dirección");
    } else if (specialCharsRegex.test(direccion)) {
      setDireccionError("La dirección no puede contener caracteres especiales");
    } else if (direccion.length < minLetters) {
      setDireccionError(
        `La dirección debe tener al menos ${minLetters} letras`
      );
    } else {
      setDireccionError("");
    }
  };
  //validar altura
  const [numeroError, setNumeroError] = useState("");

  const validarNumero = () => {
    const numberRegex = /^[0-9]+$/;

    if (!numero.trim()) {
      setNumeroError("Por favor, ingresa el número.");
    } else if (!numberRegex.test(numero)) {
      setNumeroError("El número solo puede contener dígitos.");
    } else {
      setNumeroError("");
    }
  };
  //validar piso
  const [pisoError, setPisoError] = useState("");
  const validarPiso = () => {
    const floorRegex = /^[0-9]*$/; // El asterisco permite que el campo esté vacío

    if (piso.trim() === "") {
      // Si el campo está vacío, no hay error
      setPisoError("");
    } else if (!floorRegex.test(piso)) {
      // Si el campo no está vacío pero no contiene solo dígitos, muestra un error
      setPisoError("El piso solo puede contener dígitos.");
    } else {
      // Si el campo contiene solo dígitos, no hay error
      setPisoError("");
    }
  };

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    if (!nombre.trim()) {
      Swal.fire({
        icon: "error",
        iconColor: "#d54ab7",
        confirmButtonColor: "#0071ce",
        text: "Por favor, ingresa tu nombre y apellido",
      });
      return;
    }

    // Validación de teléfono

    if (!telefono.trim()) {
      Swal.fire({
        icon: "error",
        iconColor: "#d54ab7",
        confirmButtonColor: "#0071ce",
        
        text: "Por favor, ingresa tu número de teléfono",
      });
      return;
    } else if (!validarTelefono(telefono)) {
      Swal.fire({
        icon: "error",
        iconColor: "#d54ab7",
        confirmButtonColor: "#0071ce",
        
        text: "El formato del número de teléfono es incorrecto",
      });
      return;
    }

    // Validación de calle
    if (!direccion.trim()) {
      Swal.fire({
        icon: "error",
        iconColor: "#d54ab7",
        confirmButtonColor: "#0071ce",
       
        text: "Por favor, ingresa tu dirección",
      });
      return;
    }

    // Validación de altura
    if (!numero.trim()) {
      Swal.fire({
        icon: "error",
        iconColor: "#d54ab7",
        confirmButtonColor: "#0071ce",
        
        text: "Por favor, ingresa la altura de tu dirección",
      });
      return;
    }

    // Validación de código postal
    if (!codigo.trim()) {
      Swal.fire({
        icon: "error",
        iconColor: "#d54ab7",
        confirmButtonColor: "#0071ce",
       
        text: "Por favor, ingresa el código postal",
      });
      return;
    }

    // Validación de caracteres especiales en nombre y apellido

    const formData = {
      address: direccion,
      street: null,
      number: numero,
      apt: piso,
      active: 1,
      latitude: null,
      longitude: null,
      postal_code: codigo,
      phone: telefono,
      receives: nombre,
      details: mensaje,
      comments: null,
      lead_id: contextData.customer.lead_id,
      district_id: localidadSeleccionada,
    };

    saveAddress(formData).then(async (data) => {
      console.log("Respuesta del servidor:", data);
      cerrarModal();
      if (data.success === true) {
        Swal.fire({
          icon: "success",
          iconColor: "#d54ab7",
          confirmButtonColor: "#0071ce",
          title: "Exito!",
          text: "Direccion guardada!",
        });
        setCurrentAddressExists(true);
        setCurrentAddress(data.data);
        const dataDirection = await getSelectedDirection(
          contextData.customer.lead_id
        );
        setProvinciaName(dataDirection.provincia);
        setDepartamentoName(dataDirection.departamento);
        setCiudadName(dataDirection.ciudad);
      }
    });
  };

  const handleEdition = () => {
    setCurrentAddressExists(false);
  };

  

  const abrirModalGoogle = async () => {
    setMostrarModalgoogle(true);
  };

  const cerrarModal = () => {
    setMostrarModalgoogle(false);
  };

  const handleConfirmar = (event) => {
    event.preventDefault();
    cerrarModal();
    handleSubmit();
  };

  useEffect(() => {
    cargarProvincias();
  }, []);

  useEffect(() => {
    if (!provinciaSeleccionada) {
      return;
    }
    if (provinciaSeleccionada !== "") {
      cargarMunicipios();
    }
  }, [provinciaSeleccionada]);

  useEffect(() => {
    if (!municipioSeleccionado) {
      return;
    }

    if (municipioSeleccionado !== "") {
      cargarLocalidades();
    }
  }, [municipioSeleccionado]);

  const cargarProvincias = () => {
    getAllProvinces().then((data) => {
      setProvincias(data);
    });
  };

  const cargarMunicipios = () => {
    getDepartments(provinciaSeleccionada).then((data) => {
      setMunicipios(data);
    });
  };

  const cargarLocalidades = () => {
    getDistricts(municipioSeleccionado).then((data) => {
      setLocalidades(data);
    });
  };

  const handleProvinciaChange = async (event) => {
    setProvinciaSeleccionada(event.target.value);
    if (event.target.value === "") {
      setIsSelectOpen(false);
    } else {
      setIsSelectOpen(true);
      const data = await getProvinceById(event.target.value);
      setProvinciaMap(data.name);
    }
  };

  const handleMunicipioChange = async (event) => {
    setMunicipioSeleccionado(event.target.value);
    const data = await getDepartmentById(event.target.value);
    setProvinciaMap(data.name);
  };

  const handleLocalidadChange = async (event) => {
    setLocalidadSeleccionada(event.target.value);
    const data = await getDistrictById(event.target.value);
    setCiudadMap(data.name);
  };

  const handleDireccionChange = (event) => {
    setDireccion(event.target.value);
  };

  const handleNumeroChange = (event) => {
    setNumero(event.target.value);
  };

  const handleCodigoChange = (event) => {
    setCodigo(event.target.value);
  };
  const handlePisoChange = (event) => {
    setPiso(event.target.value);
  };

  useEffect(() => {
    if (!mostrarModalGoogle || !latitude || !longitude) {
      return;
    }
    if (mostrarModalGoogle) {
      const container = document.getElementById("leafletMap");
      if (container && !container.classList.contains("leaflet-container")) {
        const map = L.map("leafletMap").setView([latitude, longitude], 13);

        L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
          attribution: "© OpenStreetMap contributors",
        }).addTo(map);

        const marker = L.marker([latitude, longitude], {
          icon: L.icon({
            iconUrl: markerIcon,
            iconSize: [20, 25],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
          }),
        }).addTo(map);

        map.on("click", (e) => {
          marker.setLatLng(e.latlng);
        });
      }
    }
  }, [mostrarModalGoogle, latitude, longitude]);

  const setPickUpAtOffice = () => {
    Swal.fire({
      title: "¿Está seguro de que desea retirar el pedido por oficina?",
      showCancelButton: true,
      confirmButtonText: "Sí",
      confirmButtonColor: "#0071ce",
      cancelButtonText: "No",
      cancelButtonColor: "#d54ab7",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const datos = {
          order_id: contextData.customer.order_id,
        };
        const response = await sendPickUpAtOffice(datos).then((response) => {
          console.log(response);
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        console.log("Usted canceló la confirmacion de retiro por oficina.");
      }
    });
  };

  //logica selects
  return (
    <div className={enviosStyle.generalContainerT}>
      {contextData.customer.customer_type === 1 && (
        <>
          <div className={enviosStyle.progressBarContainer}>
            <div
              className={enviosStyle.progressImage}
              style={{ left: `${progress * 0.97}%` }}
            >
              <img src={camion} alt="camion" />
            </div>
            <progress
              className={enviosStyle.progressBar}
              value={progress}
              max={100}
            />
          </div>
          <div className={enviosStyle.progressLabels}>
            <p className={{ width: "25%" }}>Preproducción</p>
            <p className={{ width: "25%" }}>Producción</p>
            <p className={{ width: "25%" }}>Control de Calidad</p>
            <p className={{ width: "25%" }}>Entrega</p>
          </div>

          <div className={enviosStyle.announce}>
            <p>
              EL ENVÍO SE REALIZARÁ DE MANERA GRUPAL A LA DIRECCIÓN <br />{" "}
              PROPORCIONADA POR EL TUTOR/DELEGADO
            </p>
            <img className={enviosStyle.fantasma} src={asesor} alt="asesor" />
          </div>
        </>
      )}

      {/* barra de progreso */}

      {contextData.customer.customer_type !== 1 && (
        <>
         
          <div className={enviosStyle.progressBarContainer}>
            <div
              className={enviosStyle.progressImage}
              style={{ left: `${progress * 0.97}%` }}
            >
              <img src={camion} alt="camion" />
            </div>
            <progress
              className={enviosStyle.progressBar}
              value={progress}
              max={100}
            />
          </div>
          <div className={enviosStyle.progressLabels}>
            <p className={{ width: "25%" }}>Preproducción</p>
            <p className={{ width: "25%" }}>Producción</p>
            <p className={{ width: "25%" }}>Control de Calidad</p>
            <p className={{ width: "25%" }}>Entrega</p>
          </div>

          <h4 className={enviosStyle.title}>Dónde lo enviamos?</h4>
          <div className={enviosStyle.firstflexcontainer}>
            <label className={enviosStyle.formControl}>
              <input
                type="radio"
                name="radio"
                onClick={() => {
                  setContentToShow("retiro");
                  setPickUpAtOffice();
                }}
              />
              <div className={enviosStyle.textContainer}>
                <h3>
                  RETIRO EN LITTLE BLUE{" "}
                  <span className={enviosStyle.pinkSpan}>MÁS RÁPIDO!!</span>
                </h3>
                <p className={enviosStyle.adresse}>
                  Colectora panamericana 1807,1607 san Isidro,Buenos Aires.
                </p>
                <p className={enviosStyle.adresse}>
                  {" "}
                  Lunes a Viernes de 10:00 a 17:00 HS
                </p>
              </div>
            </label>

            <label className={enviosStyle.formControl}>
              <input
                type="radio"
                name="radio"
                onClick={() => {
                  setContentToShow("santiago");
                  setPickUpAtOffice();
                }}
              />
              <div className={enviosStyle.textContainer}>
                <h3>
                  RETIRO EN LITTLE SANTIAGO{" "}
                  <span className={enviosStyle.pinkSpan}>MÁS RÁPIDO!!</span>
                </h3>
                <p className={enviosStyle.adresse}>
                  Garibaldi 64.
                  <br /> Lunes a Viernes de 9:00 a 13:00 HS y de 18:00 a 21:00
                  HS
                </p>
              </div>
            </label>
          </div>

          <label className={enviosStyle.formControl}>
            <input
              type="radio"
              name="radio"
              onClick={() => setContentToShow("envio")}
            />
            <div className={enviosStyle.textContainer}>
              <h3>ENVÍO A DOMICILIO </h3>
            </div>
          </label>

          <div>
            {contentToShow === "retiro" && (
              <div className={enviosStyle.announce}>
                <p>
                  COMUNICATE CON TU ASESOR PARA COORDINAR EL RETIRO DE TU LITTLE
                </p>
                <img
                  className={enviosStyle.fantasma}
                  src={asesor}
                  alt="asesor"
                />
              </div>
            )}

            {contentToShow === "santiago" && (
              <div className={enviosStyle.announce}>
                <p>
                  COMUNICATE CON TU ASESOR PARA COORDINAR EL RETIRO DE TU LITTLE
                </p>
                <img
                  className={enviosStyle.fantasma}
                  src={asesor}
                  alt="asesor"
                />
              </div>
            )}
            {contentToShow === "envio" &&
              contextData.customer.customer_type === 1 && (
                <div className={enviosStyle.announce}>
                  <p>COMUNICATE CON TU ASESOR PARA QUE COMPLETE LOS DATOS</p>
                  <img
                    className={enviosStyle.fantasma}
                    src={asesor}
                    alt="asesor"
                  />
                </div>
              )}

            {contentToShow === "envio" &&
              (contextData.customer.customer_type === 2 ||
                contextData.customer.customer_type === 3) &&
              (currentAddressExists === true ? (
                <FormularioAutocompletado
                  provincia={provinciaName}
                  departamento={departamentoName}
                  ciudad={ciudadName}
                  currentAddress={currentAddress}
                  handleEdition={handleEdition}
                />
              ) : (
                <form action="post" onSubmit={handleSubmit}>
                  <h4 className={enviosStyle.firstTitle}>
                    Completa el siguiente formulario:
                  </h4>

                  <div className={enviosStyle.name}>
                    <div className={enviosStyle.inputs}>
                      <label className={enviosStyle.nameLabel} htmlFor="nombre">
                        NOMBRE Y APELLIDO
                      </label>
                      <input
                        type="text"
                        id="nombre"
                        value={nombre}
                        className={enviosStyle.nameInput}
                        onChange={(event) => setNombre(event.target.value)}
                        onBlur={validarNombre}
                      />
                      {nombreError && (
                        <div className="alert alert-danger small " role="alert">
                          <p className="text-danger">{nombreError}</p>
                        </div>
                      )}
                    </div>

                    <div className={enviosStyle.inputTelefono}>
                      <label
                        className={enviosStyle.labelenvios}
                        htmlFor="telefono"
                      >
                        NUM.DE CELULAR
                      </label>
                      <input
                        type="text"
                        id="telefono"
                        value={telefono}
                        className={enviosStyle.rectangle}
                        onChange={(event) => setTelefono(event.target.value)}
                        onBlur={validarTelefono}
                        placeholder={"5654519"}
                        required
                      />
                      {telefonoError && (
                        <div
                          className="alert  alert-danger small "
                          role="alert"
                        >
                          <p className="text-danger">{telefonoError}</p>
                        </div>
                      )}

                      <span className={enviosStyle.spanenvios}>
                        (SIN 0 Y SIN 15. COD. DE ÁREA + NÚM. DE LINEA){" "}
                      </span>
                    </div>
                  </div>

                  <div className={enviosStyle.localization}>
                    <div className={enviosStyle.inputs}>
                      <div className={enviosStyle.selectContainer}>
                        <select
                          id="provincia"
                          className={enviosStyle.selects}
                          value={provinciaSeleccionada}
                          onChange={handleProvinciaChange}
                          required
                        >
                          <option value="" className={enviosStyle.option}>
                            PROVINCIA
                          </option>
                          {provincias.map((provincia) => (
                            <option key={provincia.id} value={provincia.id}>
                              {provincia.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className={enviosStyle.inputs}>
                      {!isSelectOpen && <label htmlFor="municipio"></label>}
                      <div className={enviosStyle.selectContainer}>
                        <select
                          className={enviosStyle.selects}
                          id="municipio"
                          value={municipioSeleccionado}
                          onChange={handleMunicipioChange}
                          required
                        >
                          <option value="">LOCALIDAD</option>
                          {municipios.map((municipio) => (
                            <option key={municipio.id} value={municipio.id}>
                              {municipio.name}
                            </option>
                          ))}
                        </select>
                        <div className={enviosStyle.arrowIcon}></div>
                      </div>
                    </div>

                    <div className={enviosStyle.inputs}>
                      <div className={enviosStyle.selectContainer}>
                        <select
                          className={enviosStyle.selects}
                          id="localidad"
                          value={localidadSeleccionada}
                          onChange={handleLocalidadChange}
                          required
                        >
                          <option value="">CIUDAD </option>
                          {localidades.map((localidad) => (
                            <option key={localidad.id} value={localidad.id}>
                              {localidad.name}
                            </option>
                          ))}
                        </select>
                        <div className={enviosStyle.arrowIcon}></div>
                      </div>
                    </div>
                  </div>

                  <div className={enviosStyle.street}>
                    <div className={enviosStyle.inputs}>
                      <label htmlFor="codigo" placeholder="codigo Postal">
                        {" "}
                        CP
                      </label>

                      <input
                        type="text"
                        id="codigo"
                        value={codigo}
                        onChange={handleCodigoChange}
                        className={enviosStyle.codigoInput}
                        onBlur={validarCodigo}
                        required
                      />
                      {codigoError && (
                        <div
                          className="alert  alert-danger small "
                          role="alert"
                        >
                          <p className="text-danger">{codigoError}</p>
                        </div>
                      )}
                    </div>

                    <div className={enviosStyle.inputs}>
                      <label htmlFor="direccion">CALLE</label>
                      <input
                        type="text"
                        id="direccion"
                        value={direccion}
                        onChange={(e) => {
                          handleDireccionChange(e); 
                          validarDireccion(); // Valida la dirección después de cada cambio
                        }}
                        className={enviosStyle.streetInput}
                        onBlur={validarDireccion}
                        required
                      />
                      {direccionError && (
                        <div
                          className="alert  alert-danger small "
                          role="alert"
                        >
                          <p className="text-danger">{direccionError}</p>
                        </div>
                      )}
                    </div>

                    <div className={enviosStyle.inputs}>
                      <label htmlFor="numero">ALTURA</label>
                      <input
                        type="text"
                        id="numero"
                        value={numero}
                        onChange={handleNumeroChange}
                        onBlur={validarNumero}
                        className={enviosStyle.heightInput}
                        required
                      />
                      {numeroError && (
                        <div
                          className="alert  alert-danger small "
                          role="alert"
                        >
                          <p className="text-danger">{numeroError}</p>
                        </div>
                      )}
                    </div>
                    <div className={enviosStyle.inputs}>
                      <label htmlFor="numero">PISO</label>
                      <input
                        type="text"
                        id="piso"
                        value={piso}
                        onChange={handlePisoChange}
                        onBlur={validarPiso}
                        className={enviosStyle.pisoInput}
                      />
                      {pisoError && (
                        <div
                          className="alert  alert-danger small "
                          role="alert"
                        >
                          <p className="text-danger">{pisoError}</p>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className={enviosStyle.messInputs}>
                    <label htmlFor="mensaje">ACLARACIONES</label>
                    <textarea
                      id="mensaje"
                      value={mensaje}
                      onChange={(event) => setMensaje(event.target.value)}
                      className={enviosStyle.specialInput}
                    ></textarea>
                  </div>
                  <div className={enviosStyle.nextContainer}>
                    <button
                      className={enviosStyle.next}
                      type="button"
                      onClick={abrirModalGoogle}
                    >
                      Siguiente
                    </button>
                  </div>
                </form>
              ))}
          </div>
         
          {mostrarModalGoogle && (
            <div className={enviosStyle.modalBackground}>
              <div className={enviosStyle.modal}>
                <div className={enviosStyle.contenido}>
                  <p className={enviosStyle.text}>
                    Para que no haya margen de error, te pedimos que localices
                    tu dirección en el mapa. Si no es la que te sugerimos, mové
                    el mapa hasta que esté en la posición exacta.
                  </p>
                  <br />
                  <div
                    id="leafletMap"
                    className={enviosStyle.mapContainer}
                  ></div>
                  <div id="leafletMap" style={{ display: "none" }}></div>
                  <div className={enviosStyle.buttonContainer}>
                    <button className={enviosStyle.next} onClick={cerrarModal}>
                      ATRAS
                    </button>
                    <button
                      className={enviosStyle.next}
                      onClick={handleConfirmar}
                    >
                      CONFIRMAR
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
export default Entregas;
