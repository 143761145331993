import React, { useState, useEffect } from "react";
import prendasModule from "../styles/Prendas.module.css";
import buzodoble from "../images/buzodoble.png";
import pdfdescarga from "../images/pdfdescarga.png";
import archivo from "../images/Capa_1archivo.png";
import { useDataContext } from "../contexts/AuthContext";
import {
  getGuide,
  getSketchByOrderItemId,
  approveSketch,
  getAditionalDataOfItem,
  completeSizes,
} from "../services/api";
import {
  getAllCategories,
  obtainGuideStatus,
  getPrendasTutor,
  getSizes,
} from "../services/api";
import BocetoModal from "./BocetoModal";
import AprobarModal from "./AprobarModal";
import fantasma from "../images/fantasmaOpinion.png";
import MediTuLittleModal from "./MediTuLittleModal.jsx";
import ModalTutorial1 from "./modalTutorial1";
import ModalTutorial2 from "./ModalTutorial2";
import ModalTutorial3 from "./ModalTutorial3";
import ModalTutorial4 from "./ModalTutorial4";
import ModalTutorial5 from "./ModalTutorial5";
import ModalTutorial6 from "./ModalTutorial6";
import ModalTutorial7 from "./ModalTutorial7";
import ModalTutorialIntermediate from "./ModaltutorialIntermediate";
import CargarTalle from "./CargarTalle.jsx";
import GuiaRemera1 from "./GuiaRemera1";
import GuiaRemera2 from "./GuiaRemera2";
import GuiaRemera3 from "./GuiaRemera3";
import GuiaRemera4 from "./GuiaRemera4";
import GuiaRemera5 from "./GuiaRemera5";
import GuiaRemera6 from "./GuiaRemera6";
import GuiaRemera7 from "./GuiaRemera7";
import GuiaRemeraIntermediate from "./GuiaRemeraIntemediate";
import Swal from "sweetalert2";
import SizeTable from "./SizeTable";
import Arrow from "../images/flecha-magenta.png";
import CargarGuia from "../components/CargarGuia.jsx";
import logoPng from "../images/Frame.png";

export default function Prendas() {
  const { contextData } = useDataContext();
  const [guiaDesplegada, setGuiaDesplegada] = useState(false);
  const [bocetoDesplegado, setBocetoDesplegado] = useState(false);
  const [talleDesplegado, setTalleDesplegado] = useState(false);
  const [prendaSeleccionada, setPrendaSeleccionada] = useState(null);
  const [prendas, setPrendas] = useState([]);
  const [orderItemId, setOrderItemId] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [sketch, setSketch] = useState({});
  const [downloadUrl, setDownloadUrl] = useState("");
  const [paxes, setPaxes] = useState("");
  const [requirementExpiration, setRequirementExpiration] = useState("");
  const [showModalCargarGuia, setShowModalCargarGuia] = useState(false);
  const [estadoGuia, setEstadoGuia] = useState("inexistente");
  const [botonesPresionados, setBotonesPresionados] = useState({});
  const [prendaPrevSeleccionada, setPrendaPrevSeleccionada] = useState(null);
  const [completedMessage, setCompleteMessage] = useState("");
  const [approvedMessage, setApprovedMessage] = useState("");
  const [seleccionAutomatica, setSeleccionAutomatica] = useState(false);
  const [sizes, setSizes] = useState([]);
  const [loading, setLoading] = useState(true);

  const toggleGuiaDesplegable = () => {
    setGuiaDesplegada(!guiaDesplegada);
  };
  const toggleBocetoDesplegado = () => {
    setBocetoDesplegado(!bocetoDesplegado);
  };
  const toggleTalleDesplegado = () => {
    setTalleDesplegado(!talleDesplegado);
  };

  useEffect(() => {
    if (!contextData || !contextData.customer || !contextData.customer.id) {
      return;
    }

    const fetchData = async () => {
      try {
        let data;
        if (
          contextData.customer.customer_type === 3 ||
          contextData.customer.customer_type === 2
        ) {
          data = await getPrendasTutor(contextData.customer.lead_id);
        } else {
          data = await getAllCategories(contextData.customer.id);
        }

        const prendasConPath = data.map((prenda) => {
          let path;
          let alternativePath;
          switch (prenda.category_name) {
            case "Buzos":
              path = `buzo.png`;
              alternativePath = `buzo1.png`;
              break;
            case "Chombas":
              path = `chomba.png`;
              alternativePath = `chomba1.png`;
              break;
            case "Remeras":
              path = `remeras.png`;
              alternativePath = `remeras1.png`;
              break;
            case "Joggings":
              path = `joggings.png`;
              alternativePath = `joggings1.png`;
              break;
            case "Mochilas":
              path = `mochilas.png`;
              alternativePath = `mochilas1.png`;
              break;
            case "Camperas":
              path = `camperas.png`;
              alternativePath = `camperas1.png`;
              break;
            case "Otros":
              switch (prenda.product_name) {
                case "Bandera":
                  path = `bandera.png`;
                  alternativePath = `bandera1.png`;
                  break;
                // Aquí puedes agregar más casos según tus necesidades
                default:
                  path = `otros.png`;
                  alternativePath = `otros1.png`;
              }
              break;
            default:
              path = "bandera.png";
              alternativePath = "bandera1.png";
          }
          return { ...prenda, path, alternativePath };
        });
        setPrendas(prendasConPath);
        setLoading(false);

        console.log("prenda que nos trajo:", prendasConPath);
      } catch (error) {
        console.error("Error fetching prendas:", error);
      }
    };

    fetchData();
  }, [contextData]);

  useEffect(() => {
    if (!prendaSeleccionada) {
      return;
    }
    const fetchSketch = async () => {
      try {
        const data = await getSketchByOrderItemId(
          prendaSeleccionada.order_item_id
        );
        setSketch(data);
        setDownloadUrl(data?.filename || "");
      } catch (error) {
        setSketch({});
        setDownloadUrl("");
        console.error("Error fetching sketch:", error);
      }
    };

    if (prendaSeleccionada) {
      fetchSketch();
    }
  }, [prendaSeleccionada]);

  useEffect(() => {
    if (!prendaSeleccionada) {
      return;
    }
    const fetchAditionalDataOfItem = async () => {
      try {
        const data = await getAditionalDataOfItem(
          prendaSeleccionada.order_item_id
        );
        console.log(data);
        setPaxes(data.totalPaxes);
        setRequirementExpiration(data.requirementExpiration);

        if (data.completedMessage) {
          setCompleteMessage(data.completedMessage);
          setBotonHabilitado(false);
        } else {
          setCompleteMessage("");
          setBotonHabilitado(true);
        }

        if (data.approvedMessage) {
          setApprovedMessage(data.approvedMessage);
          setBotonHabilitado(false);
        } else {
          setApprovedMessage("");
          setBotonHabilitado(true);
        }
      } catch (error) {
        console.error("Error fetching aditional data of item:", error);
      }
    };

    if (prendaSeleccionada) {
      fetchAditionalDataOfItem();
    }
  }, [prendaSeleccionada]);

  //funcion para renderizar selectivamente las guias
  const [currentModal, setCurrentModal] = useState(0);
  const [currentModalRemeras, setCurrentModalRemeras] = useState(0);

  // handleCloseAllModals no esta en el codigo
  const handleCloseAllModals = () => {
    setCurrentModal(null);
  };
  const handleNext = () => {
    if (currentModal === 6 && window.innerWidth >= 1024) {
      setCurrentModal(6.5);
    } else if (currentModal === 6.5) {
      setCurrentModal(7);
    } else {
      setCurrentModal((prevModal) => prevModal + 1);
    }
  };
  const handleBack = () => {
    if (currentModal === 6.5) {
      setCurrentModal(6); // Retroceder al modal 6 (previo al intermedio)
    } else if (currentModal === 7 && window.innerWidth >= 1024) {
      setCurrentModal(6.5);
    } else {
      setCurrentModal(currentModal - 1);
    }
  };

  const toggleModaltutorial = () => {
    setCurrentModal(1);
  };

  const toggleModalRemeras = () => {
    setCurrentModalRemeras(1);
  };
  const handleCloseAllModalsRemera = () => {
    setCurrentModalRemeras(null);
  };
  const handleNextRemera = () => {
    if (currentModalRemeras === 6 && window.innerWidth >= 1024) {
      setCurrentModalRemeras(6.5);
    } else if (currentModalRemeras === 6.5) {
      setCurrentModalRemeras(7);
    } else {
      setCurrentModalRemeras((prevModal) => prevModal + 1);
      console.log("next");
    }
  };
  const handleBackRemera = () => {
    if (currentModalRemeras === 6.5) {
      setCurrentModalRemeras(6); // Retroceder al modal 6 (previo al intermedio)
    } else if (currentModalRemeras === 7 && window.innerWidth >= 1024) {
      setCurrentModalRemeras(6.5);
    } else {
      setCurrentModalRemeras(currentModalRemeras - 1);
      console.log("back");
    }
  };

  const handleClickPrenda = async (prenda) => {
    try {
      // Obtén la guía para la nueva prenda
      const data = await getGuide(prenda.order_item_id);
      setOrderItemId(prenda.order_item_id);

      // Actualiza la guía de la prenda seleccionada
      const prendaActualizada = { ...prenda, guide_url: data.guide_url };

      // Actualiza el estado de la prenda seleccionada y la guía
      setPrendaSeleccionada(prendaActualizada);
      setGuiaDesplegada(true); // Para mostrar la guía al mismo tiempo

      // Restaura el estado de 'pressed' de las demás prendas
      const updatedPrendas = prendas.map((item) =>
        item === prenda
          ? { ...item, pressed: true }
          : { ...item, pressed: false }
      );
      setPrendas(updatedPrendas);
    } catch (error) {
      console.error("Error fetching guide:", error);
    }
  };

  //logica para modalA
  const [showModalA, setShowModalA] = useState(false);

  const toggleModalA = async (id) => {
    try {
      const data = await approveSketch(
        orderItemId,
        contextData.customer.id,
        id
      );
      if (data.status === 201) {
        setShowModalA(!showModalA);
        setSketch(data.sketch);
      } else {
        //setShowModalError
      }
    } catch (error) {
      console.log(error);
      //setShowModalError
    }
  };

  const closeModalA = () => {
    setShowModalA(!showModalA);
  };
  //logica para el modal rechazar boceto
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };
  //logica para el mediTuLittleModal

  const [showModalMediTuLittle, setShowModalMediTuLittle] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const toggleModalMediTuLittle = () => {
    setShowModalMediTuLittle(!showModalMediTuLittle);
  };
  //logica modal cargar talles//

  const [showModalCargarTalles, setShowModalCargarTalles] = useState(false);
  const toggleCargarTalles = () =>
    setShowModalCargarTalles(!showModalCargarTalles);
  //logica para abrir la tabla
  const [isOpen, setIsOpen] = useState(false);
  // const toggleOpen = () => {
  //   setIsOpen(!isOpen);
  // };
  const toggleOpen = () => {
    if (prendaSeleccionada === null) {
      Swal.fire({
        // title: 'Selecciona una prenda',
        icon: "warning",
        iconColor: "#d54ab7",
        confirmButtonColor: "#0071ce",
        title: "Atención",
        text: "Debes seleccionar una prenda.",
      });
    } else {
      setIsOpen(!isOpen);
    }
  };

  const handleModalCargarGuia = () => {
    setShowModalCargarGuia(true);
  };

  const cerrarModalCargarGuia = () => {
    setShowModalCargarGuia(false);
  };

  useEffect(() => {
    if (!orderItemId) {
      return;
    }
    obtenerEstadoGuia(orderItemId);
  }, [orderItemId]);

  const obtenerEstadoGuia = async (order_item_id) => {
    try {
      const data = await obtainGuideStatus(order_item_id);
      if (data.rejected === true && data.approved === false) {
        setEstadoGuia("rechazada");
      } else if (data.rejected === false && data.approved === true) {
        setEstadoGuia("aprobada");
      } else if (data.rejected === false && data.approved === false) {
        setEstadoGuia("pendiente");
      } else if (data.exists === false) {
        setEstadoGuia("inexistente");
      }
    } catch (error) {
      console.error("Error fetching aditional data of guide:", error);
    }
  };
  //logica para confirmar talles
  const [botonHabilitado, setBotonHabilitado] = useState(true);
  const handleAlertconfirm = () => {
    if (!prendaSeleccionada) {
      Swal.fire({
        icon: "warning",
        iconColor: "#d54ab7",
        confirmButtonColor: "#0071ce",
        title: "Atención",
        text: "Por favor, selecciona una prenda antes de continuar.",
      });
      return;
    }
    let mensaje;
    if (sizes.length === paxes) {
      mensaje = `¿Está seguro que desea confirmar ${sizes.length} talles para ${prendaSeleccionada.product_name}?`;
    } else {
      mensaje = `¿Desea confirmar ${sizes.length} de ${paxes} ${prendaSeleccionada.product_name} encargados/as originalmente?`;
    }

    Swal.fire({
      title: mensaje,
      showCancelButton: true,
      confirmButtonText: "Sí",
      confirmButtonColor: "#0071ce",
      cancelButtonText: "No",
      cancelButtonColor: "#d54ab7",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // Aquí va la lógica si el usuario selecciona "Sí"
        console.log("Usuario confirmó los talles.");
        setBotonHabilitado(false);
        const datos = {
          order_item_id: orderItemId,
          customer_id: contextData.customer.id,
        };
        const response = await completeSizes(datos).then((response) => {
          console.log(response);
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // Aquí va la lógica si el usuario selecciona "No" o cierra el modal
        console.log("Usuario canceló la confirmación de talles.");
      }
    });
  };

  // cantidad de prendas

  useEffect(() => {
    // if (!prendas || !seleccionAutomatica) {
    //   return;
    // }
    if (prendas.length === 1 && !seleccionAutomatica) {
      handleClickPrenda(prendas[0]);
      setSeleccionAutomatica(true);
    }
  }, [prendas, seleccionAutomatica]);

  useEffect(() => {
    fetchData2();
  }, [orderItemId]);

  // const fetchData2 = async () => {
  //   try {
  //     const data = await getSizes(orderItemId);
  //     console.log("Datos recibidos:", data);
  //     setSizes(data);
  //   } catch (error) {
  //     console.error("Error al obtener los datos:", error);
  //   }
  // };

  const fetchData2 = async () => {
    try {
      if (!orderItemId) {
        console.log("No se ha seleccionado ningún ítem.");
        return;
      }

      const data = await getSizes(orderItemId);
      console.log("Datos recibidos:", data);
      setSizes(data);
    } catch (error) {
      console.error("Error al obtener los datos:", error);
    }
  };

  return (
    <>
      {loading ? (
        <div className="contcargando">
          <img src={logoPng} alt="littleBlueLogo" className="imgcargando" />
          <h2 className="text-muted">CARGANDO...</h2>
          <div className="loader"></div>
        </div>
      ) : (
        <div className={`container ${prendasModule.container1}`}>
          {/* <h5 className={prendasModule.subtitle}>Selecciona una prenda:</h5> */}
          {prendas.length > 1 && (
            <h5 className={prendasModule.subtitle}>Selecciona una prenda:</h5>
          )}

          <div className={`row ${prendasModule.containerbotonprendas}`}>
            {prendas.map((prenda) => (
              <div
                key={prenda.order_item_id}
                className={`col ${prendasModule.prendaContainer}`}
              >
                <button
                  className={`${prendasModule.prendaBoton} ${
                    prendaSeleccionada === prenda
                      ? prendasModule.seleccionado
                      : ""
                  } ${prenda.pressed ? prendasModule.agrandarBoton : ""}`}
                  onClick={() => handleClickPrenda(prenda)}
                >
                  <img
                    // src={require(`../images/imagenesPrendas/${prenda.path}`)}
                    src={require(`../images/imagenesPrendas/${
                      prenda.pressed ? prenda.alternativePath : prenda.path
                    }`)}
                    alt={`Prenda ${prenda.order_item_id}`}
                    className={prendasModule.prendas}
                  />

                  <p className={prendasModule.nombrePrenda}>
                    {prenda.product_name} {prenda.alias}

                  </p>
                </button>
              </div>
            ))}
          </div>

          {/* guia */}
          {contextData.customer.customer_type !== 1 && (
            <div className={`mt-3 ${prendasModule.guiaContainer}`}>
              <div>
                <button
                  className={`btn ${prendasModule.guiaBoton}`}
                  onClick={toggleGuiaDesplegable}
                >
                  Guía de Diseño
                  {guiaDesplegada ? (
                    <i className="bi bi-caret-up-fill"></i>
                  ) : (
                    <i className="bi bi-caret-down-fill"></i>
                  )}
                </button>
              </div>

              <div className={`mt-2 collapse ${guiaDesplegada ? "show" : ""}`}>
                <div className={prendasModule.containerprincipalguia}>
                  <div>
                    <h1 className={prendasModule.descargaGuia}>
                      DESCARGÁ LA GUÍA
                    </h1>
                  </div>
                  <div className={prendasModule.mensajeContainer}>
                    <p className={prendasModule.mensaje}>
                      Completá la GUÍA, lo mas detallada posible, con tu diseño
                      para que podamos realizar el boceto
                    </p>

                    {guiaDesplegada && (
                      <div className={prendasModule.imagenContainer}>
                        <img
                          src={pdfdescarga}
                          alt="Referencia de diseño"
                          className={prendasModule.imagen}
                        />

                        {prendaSeleccionada && (
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={`${prendaSeleccionada.guide_url}`}
                            className={prendasModule.descargarBoton}
                          >
                            Descargar
                          </a>
                        )}
                      </div>
                    )}
                  </div>
                  <button
                    className={prendasModule.botoncompletar}
                    onClick={() => {
                      if (!prendaSeleccionada) {
                        // Mostrar un warning si no hay una prenda seleccionada
                        Swal.fire({
                          icon: "warning",
                          iconColor: "#d54ab7",
                          confirmButtonColor: "#0071ce",
                          title: "Atención",
                          text: "Por favor, selecciona una prenda antes de continuar.",
                        });
                      } else if (
                        prendaSeleccionada.category_name === "Buzos" ||
                        prendaSeleccionada.category_name === "Anorak" ||
                        prendaSeleccionada.category_name === "Camperas"
                      ) {
                        console.log(
                          " no seleccionaste remera",
                          prendaSeleccionada.category_name
                        );
                        toggleModaltutorial();
                      } else if (
                        prendaSeleccionada.category_name === "Remeras" ||
                        prendaSeleccionada.category_name === "Chombas"
                      ) {
                        console.log(
                          "hola  seleccionaste remera",
                          prendaSeleccionada.category_name
                        );
                        toggleModalRemeras();
                      } else {
                        Swal.fire({
                          icon: "info",
                          iconColor: "#d54ab7",
                          confirmButtonColor: "#0071ce",
                          title: "Información",
                          text: `No hay tutorial disponible para ${prendaSeleccionada.category_name}.`,
                        });
                        console.log("nada");
                      }
                    }}
                  >
                    como la completo
                  </button>

                  {prendaSeleccionada &&
                    (prendaSeleccionada.category_name === "Buzos" ||
                      prendaSeleccionada.category_name === "Anorak" ||
                      prendaSeleccionada.category_name === "Camperas") && (
                      <>
                        {currentModal === 1 && (
                          <ModalTutorial1
                            onNext={handleNext}
                            onBack={handleBack}
                            onClose={handleCloseAllModals}
                          />
                        )}
                        {currentModal === 2 && (
                          <ModalTutorial2
                            onNext={handleNext}
                            onBack={handleBack}
                            onClose={handleCloseAllModals}
                          />
                        )}
                        {currentModal === 3 && (
                          <ModalTutorial3
                            onNext={handleNext}
                            onBack={handleBack}
                            onClose={handleCloseAllModals}
                          />
                        )}
                        {currentModal === 4 && (
                          <ModalTutorial4
                            onNext={handleNext}
                            onBack={handleBack}
                            onClose={handleCloseAllModals}
                          />
                        )}
                        {currentModal === 5 && (
                          <ModalTutorial5
                            onNext={handleNext}
                            onBack={handleBack}
                            onClose={handleCloseAllModals}
                          />
                        )}
                        {currentModal === 6 && (
                          <ModalTutorial6
                            onNext={handleNext}
                            onBack={handleBack}
                            onClose={handleCloseAllModals}
                          />
                        )}
                        {currentModal === 6.5 && (
                          <ModalTutorialIntermediate
                            onNext={handleNext}
                            onBack={handleBack}
                            onClose={handleCloseAllModals}
                          />
                        )}

                        {currentModal === 7 && (
                          <ModalTutorial7
                            onNext={handleNext}
                            onBack={handleBack}
                            onClose={handleCloseAllModals}
                          />
                        )}
                      </>
                    )}
                  {prendaSeleccionada &&
                    (prendaSeleccionada.category_name === "Remeras" ||
                      prendaSeleccionada.category_name === "Chombas") && (
                      <>
                        {currentModalRemeras === 1 && (
                          <GuiaRemera1
                            onNext={handleNextRemera}
                            onClose={handleCloseAllModalsRemera}
                          />
                        )}
                        {currentModalRemeras === 2 && (
                          <GuiaRemera2
                            onNext={handleNextRemera}
                            onBack={handleBackRemera}
                            onClose={handleCloseAllModalsRemera}
                          />
                        )}
                        {currentModalRemeras === 3 && (
                          <GuiaRemera3
                            onNext={handleNextRemera}
                            onBack={handleBackRemera}
                            onClose={handleCloseAllModalsRemera}
                          />
                        )}
                        {currentModalRemeras === 4 && (
                          <GuiaRemera4
                            onNext={handleNextRemera}
                            onBack={handleBackRemera}
                            onClose={handleCloseAllModalsRemera}
                          />
                        )}
                        {currentModalRemeras === 5 && (
                          <GuiaRemera5
                            onNext={handleNextRemera}
                            onBack={handleBackRemera}
                            onClose={handleCloseAllModalsRemera}
                          />
                        )}
                        {currentModalRemeras === 6 && (
                          <GuiaRemera6
                            onNext={handleNextRemera}
                            onBack={handleBackRemera}
                            onClose={handleCloseAllModalsRemera}
                          />
                        )}
                        {currentModalRemeras === 6.5 && (
                          <GuiaRemeraIntermediate
                            onNext={handleNextRemera}
                            onBack={handleBackRemera}
                            onClose={handleCloseAllModalsRemera}
                          />
                        )}
                        {currentModalRemeras === 7 && (
                          <GuiaRemera7
                            onNext={handleNextRemera}
                            onBack={handleBackRemera}
                            onClose={handleCloseAllModalsRemera}
                          />
                        )}
                        {/* Renderiza los demás modals según su estado */}
                      </>
                    )}
                  <div>
                    <h2 className={prendasModule.descargaGuia}>
                      YA COMPLETASTE LA GUÍA?
                    </h2>
                  </div>
                  <div className={prendasModule.mensajeContainer}>
                    <p className={prendasModule.mensaje}>
                      Buenisimo!, Podes subirla acá junto a cualquier referencia
                      que nos sirva para entender tu diseño: escudos, logos,
                      imagenes, dibujos, etc.
                    </p>
                    {prendaSeleccionada !== null ? (
                      <div className={prendasModule.containercargarguia}>
                        <img
                          src={archivo}
                          alt="Referencia de diseño"
                          className={prendasModule.imagenarchivo}
                        />
                        {estadoGuia === "aprobada" && (
                          <button
                            className={prendasModule.cargarBoton}
                            onClick={handleModalCargarGuia}
                          >
                            Guía aprobada <i className="bi bi-check-circle"></i>
                          </button>
                        )}
                        {estadoGuia === "rechazada" && (
                          <button
                            className={prendasModule.cargarBoton}
                            onClick={handleModalCargarGuia}
                          >
                            Guía rechazada <i className="bi bi-dash-circle"></i>
                          </button>
                        )}
                        {estadoGuia === "pendiente" && (
                          <button
                            className={prendasModule.cargarBoton}
                            onClick={handleModalCargarGuia}
                          >
                            Guia pendiente <i className="bi bi-clock"></i>
                          </button>
                        )}
                        {estadoGuia === "inexistente" && (
                          <button
                            className={prendasModule.cargarBoton}
                            onClick={handleModalCargarGuia}
                          >
                            Carga tu guía
                          </button>
                        )}
                      </div>
                    ) : (
                      <div className={prendasModule.containercargarguia}>
                        <img
                          src={archivo}
                          alt="Referencia de diseño"
                          className={prendasModule.imagenarchivo}
                        />
                        <button className={prendasModule.cargarBoton} disabled>
                          Seleccione una prenda
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          <section className={`mt-3 ${prendasModule.guiaContainer}`}>
            <button
              className={`btn ${prendasModule.guiaBoton}`}
              onClick={() => {
                if (!prendaSeleccionada) {
                  // Mostrar un warning si no hay una prenda seleccionada
                  Swal.fire({
                    icon: "warning",
                    iconColor: "#d54ab7",
                    confirmButtonColor: "#0071ce",
                    title: "Atención",
                    text: "Por favor, selecciona una prenda antes de continuar.",
                  });
                } else {
                  {
                    toggleBocetoDesplegado();
                  }
                }
              }}
            >
              Ver Boceto
              {bocetoDesplegado ? (
                <i className="bi bi-caret-up-fill"></i>
              ) : (
                <i className="bi bi-caret-down-fill"></i>
              )}
            </button>
            <div className={`mt-2 collapse ${bocetoDesplegado ? "show" : ""}`}>
              {prendaSeleccionada && (
                <>
                  {sketch && sketch.filename ? (
                    <div>
                      <div className="text-center">
                        <img
                          className={prendasModule.imagenboceto}
                          src={sketch.filename}
                          alt="Buzo"
                        />
                      </div>
                      {bocetoDesplegado && (
                        <div className={prendasModule.buttonsContainerB}>
                          {contextData.customer.customer_type === 3 ||
                          contextData.customer.customer_type === 2 ? (
                            <>
                              <a
                                href={downloadUrl}
                                download={`${prendaSeleccionada.product_name}_Boceto.png`}
                                target="_blank"
                                className={prendasModule.buttonOne}
                              >
                                DESCARGAR BOCETO
                              </a>
                              {sketch.approved_at === null ? (
                                <>
                                  <button
                                    className={prendasModule.buttonTwo}
                                    onClick={() => toggleModalA(sketch.id)}
                                  >
                                    APROBAR
                                  </button>
                                  <button
                                    className={prendasModule.buttonThree}
                                    onClick={toggleModal}
                                  >
                                    RECHAZAR
                                  </button>
                                </>
                              ) : (
                                <button
                                  className={prendasModule.buttonTwo}
                                  disabled
                                >
                                  BOCETO APROBADO
                                </button>
                              )}
                            </>
                          ) : (
                            <a
                              href={downloadUrl}
                              download={`${prendaSeleccionada.product_name}_Boceto.png`}
                              target="_blank"
                              className={prendasModule.buttonOne}
                            >
                              DESCARGAR BOCETO
                            </a>
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    <>
                      <div className={prendasModule.noBoceto}>
                        <p>
                          QUERIAS UN BOCETO? <br />
                          NO HAY TAL BOCETO{" "}
                        </p>
                        <img src={fantasma} alt="fantasma" />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
            {showModal && <BocetoModal onClose={toggleModal} boceto={sketch} />}
            {showModalA && <AprobarModal onClose={closeModalA} />}
            {showModalCargarGuia && (
              <CargarGuia
                cerrarModalCargarGuia={cerrarModalCargarGuia}
                prendaSeleccionada={prendaSeleccionada}
                orderItemId={orderItemId}
              />
            )}
          </section>

          {/* Talles */}
          {prendaSeleccionada &&
          prendaSeleccionada.product_name.toLowerCase() === "bandera" ? null : (
            <div className={`mt-3 ${prendasModule.guiaContainer}`}>
              <button
                className={`btn ${prendasModule.guiaBoton}`}
                onClick={toggleTalleDesplegado}
              >
                Talles
                {talleDesplegado ? (
                  <i className="bi bi-caret-up-fill"></i>
                ) : (
                  <i className="bi bi-caret-down-fill"></i>
                )}
              </button>
              <div className={`mt-2 collapse ${talleDesplegado ? "show" : ""}`}>
                <div className={prendasModule.sizeContainer}>
                  <p className={prendasModule.text}>
                    La cantidad total de prendas puede variar hasta que{" "}
                    <span className={prendasModule.strong}>
                      {" "}
                      el tutor confirma <br />y cierra el listado de talles.
                    </span>{" "}
                    <br />
                    Una vez hecho esto,
                    <span className={prendasModule.strong}>
                      {" "}
                      no se pueden realizar mas cambios ni de apodo ni de
                      cantidades.
                    </span>
                  </p>
                  <div className={prendasModule.buttonsContainerT}>
                    {contextData.customer.customer_type !== 1 && (
                      <button
                        className={prendasModule.buttonOne}
                        onClick={handleAlertconfirm}
                      >
                        CONFIRMAR TALLES
                      </button>
                    )}

                    <button
                      className={prendasModule.buttonTwo}
                      onClick={() => {
                        if (!prendaSeleccionada) {
                          // Mostrar un warning si no hay una prenda seleccionada
                          Swal.fire({
                            icon: "warning",
                            iconColor: "#d54ab7",
                            confirmButtonColor: "#0071ce",
                            title: "Atención",
                            text: "Por favor, selecciona una prenda antes de continuar.",
                          });
                        } else {
                          // Realizar la acción deseada si hay una prenda seleccionada
                          toggleModalMediTuLittle();
                        }
                      }}
                    >
                      MEDI TU LITTLE
                    </button>

                    <button
                      disabled={!botonHabilitado}
                      className={`${prendasModule.buttonThree} ${
                        !botonHabilitado && prendasModule.disabled
                      }`}
                      onClick={() => {
                        console.log(prendaSeleccionada);
                        if (!prendaSeleccionada) {
                          Swal.fire({
                            icon: "warning",
                            iconColor: "#d54ab7",
                            confirmButtonColor: "#0071ce",
                            title: "Atención",
                            text: "Por favor, selecciona una prenda antes de continuar.",
                          });
                        } else if (
                          prendaSeleccionada &&
                          prendaSeleccionada.product_name.toLowerCase() ===
                            "bandera"
                        ) {
                          Swal.fire({
                            icon: "warning",
                            iconColor: "#d54ab7",
                            confirmButtonColor: "#0071ce",
                            title: "Atención",
                            text: " Bandera no tiene talle.",
                          });
                        } else {
                          toggleCargarTalles();
                        }
                      }}
                    >
                      CARGAR TALLES
                    </button>
                  </div>
                </div>

                {showModalMediTuLittle && (
                  <MediTuLittleModal
                    prendaSeleccionada={prendaSeleccionada}
                    onClose={toggleModalMediTuLittle}
                  />
                )}

                {showModalCargarTalles && (
                  <CargarTalle
                    onClose={toggleCargarTalles}
                    orderItemId={orderItemId}
                    prenda={prendaSeleccionada.product_name}
                    prendasConPath={prendas}
                  />
                )}

                <summary
                  className={prendasModule.toggleContainer}
                  onClick={toggleOpen}
                >
                  <h5 className={prendasModule.talleCargado}>
                    {prendaSeleccionada !== null
                      ? ` TALLES CARGADOS PARA ${prendaSeleccionada.product_name.toUpperCase()} ${prendaSeleccionada.alias}`
                      : // ? `0 DE ${paxes} TALLES CARGADOS PARA ${prendaSeleccionada.product_name}`
                        "Selecciona una prenda"}
                  </h5>
                  <img
                    src={Arrow}
                    alt=""
                    className={isOpen ? prendasModule.arrowRotate : ""}
                  />
                </summary>
                {isOpen && (
                  <>
                    <div className={prendasModule.titleTableContainer}>
                      <h6>
                        Vencimiento de requisitos: {requirementExpiration}
                      </h6>
                      <h6>Completado: {completedMessage}</h6>
                      <h6>Aprobado: {approvedMessage}</h6>
                      <SizeTable
                        paxes={paxes}
                        orderItemId={orderItemId}
                        onClose={toggleCargarTalles}
                        botonHabilitado={botonHabilitado}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
