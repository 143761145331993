import rifasStyle from "../styles/estilos/rifas.module.css";
import React, { useState } from "react";
import danger from "../images/Danger.png";

const Rifas = () => {
  const currentyear = new Date().getFullYear();
  const [codigo, setCodigo] = useState("");
  const [number, setNumber] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModa2] = useState(false);

  const handleCodigoChange = (event) => {
    setCodigo(event.target.value);
  };
  const handleNumberChange = (event) => {
    setNumber(event.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    // Aquí podrías manejar la lógica para enviar el formulario o realizar otras acciones con los datos
    console.log("Código:", codigo);
    console.log("Número:", number);
    setShowModal(true);
  };

  const handleModal2 = (event) => {
    event.preventDefault();
    setShowModa2(true);
  };
  return (
    <>
      <h1 className={rifasStyle.title}>RIFAS {currentyear}</h1>
      <form onSubmit={handleSubmit} className={rifasStyle.form}>
        <section className={rifasStyle.inputcontainer}>
          <div className={rifasStyle.one}>
            <label className={rifasStyle.labelPrincipal} htmlFor="codigo">Código:</label>
            <input
              className={rifasStyle.input}
              placeholder="Por ejemplo x21w70"
              type="text"
              id="codigo"
              value={codigo}
              onChange={handleCodigoChange}
              required
            />
            <p className={rifasStyle.parrafo}>Ingresá los 6 caractéres alfanúmericos.</p>
          </div>
          <div className={rifasStyle.one}>
            <label className={rifasStyle.labelPrincipal} htmlFor="number">Número:</label>
            <input
              className={rifasStyle.input}
              placeholder="Por ejemoplo:004123"
              type="number"
              id="number"
              value={number}
              onChange={handleNumberChange}
              required
            />
            <p className={rifasStyle.parrafo}>Ingresá el número de tu rifa.</p>
          </div>
        </section>
        <div className={rifasStyle.containerBotones}>
          <div>
            <button className={rifasStyle.button} onClick={handleModal2}>
              Informacion
            </button>
          </div>
          <div>
            <button className={rifasStyle.button} type="submit">
              VALIDAR
            </button>
          </div>
        </div>
      </form>
      {/* modal */}

      {showModal && (
        <div
          className="modal show"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog" role="document">
            <div className={`modal-content ${rifasStyle.containerPrincipal}`}>
              <div className={rifasStyle.cruzCerrar}>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowModal(false)}
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  {/* <div className="mb-3">
                    <label className="mb-1" htmlFor="Codigo">Codigo:</label>
                    <input
                      type="text"
                      className={`form-control ${rifasStyle.inputs}`}
                      id="Codigo"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label  className="mb-1" htmlFor="Numero">Numero:</label>
                    <input
                      type="text"
                      className={`form-control ${rifasStyle.inputs}`}
                      id="Numero"
                      required
                    />
                  </div> */}
                  <div>
                    <h5 className={`modal-title ${rifasStyle.subtitulo}`}>
                      Completa con los datos del participante
                    </h5>
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="nombre"
                      className={rifasStyle.labelParticipante}
                    >
                      NOMBRE PARTICIPANTE:
                    </label>
                    <input
                      type="text"
                      className={`form-control ${rifasStyle.inputs}`}
                      id="nombre"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="dni"
                      className={rifasStyle.labelParticipante}
                    >
                      DNI DEL PARTICIPANTE:
                    </label>
                    <input
                      type="number"
                      className={`form-control ${rifasStyle.inputs}`}
                      id="dni"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="fecha"
                      className={rifasStyle.labelParticipante}
                    >
                      FHECHA DE NACIMIENTO:
                    </label>
                    <input
                      type="text"
                      className={`form-control ${rifasStyle.inputs}`}
                      id="fecha"
                      required
                    />
                  </div>
                </form>
              </div>
              <div className={rifasStyle.containerBotonmodal}>
                <button
                  type="submit"
                  className={`btn ${rifasStyle.botonValidarModal}`}
                  onClick={handleSubmit}
                >
                  VALIDAR
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showModal2 && (
        <div
          className="modal show"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog" role="document">
            <div className={`modal-content ${rifasStyle.containerPrincipal}`}>
              <div className={rifasStyle.cruzCerrar}>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowModa2(false)}
                ></button>
              </div>
              <div className={rifasStyle.containericono}>
                <img className={rifasStyle.imageDanger} src={danger} alt="" />
                <p className={rifasStyle.tituloinfo}>
                  {" "}
                  IMPORTANTE: <br /> Validar las rifas de lo contrario no forman
                  parte del sorteo.
                </p>
              </div>
              <div>
                <h6 className={rifasStyle.subtituloinfo}>
                  ¿CÓMO OBTENGO MIS RIFAS?
                </h6>
                <p className={rifasStyle.parrafosInfo}>
                  Las rifas podés solicitarlas a tu asesor/a de Atención al
                  cliente y las van <br /> a gestionar de manera online. Les
                  vamos a enviar un talonario para que <br /> puedan llevar
                  llevar tambien ustedes un control de las rifas que venden,{" "}
                  <br /> pero van a tener que cargarlas en nuestra plataforma.
                </p>
                <div>
                  <h6 className={rifasStyle.subtituloinfo}>
                    ¿CUÁNTO SALE CADA RIFA?
                  </h6>
                  <p className={rifasStyle.parrafosInfo}>
                    El valor de la rifa le ponen ustedes, pueden ponerse de
                    acuerdo entre el <br />
                    grupo o hacerlo de forma individual.
                  </p>
                </div>
                <div>
                  <h6 className={rifasStyle.subtituloinfo}>
                    ¿CUÁNTAS RIFAS TENGO?
                  </h6>
                  <p className={rifasStyle.parrafosInfo}>
                    Cada alumno cuenta con 10 rifas.
                  </p>
                </div>
                <div>
                  <h6 className={rifasStyle.subtituloinfo}>
                    ¿CÚANDO SE SORTEA?
                  </h6>
                  <p className={rifasStyle.parrafosInfo}>
                    El sorteo se realiza el 01/07/24 a las 16 hs.
                  </p>
                </div>
                <div>
                  <h6 className={rifasStyle.subtituloinfo}>
                    ¿POR DONDE SE SORTEA?
                  </h6>
                  <p className={rifasStyle.parrafosInfo}>
                    Van a poder ver los resultados a través de nuestras redes
                    sociales.
                  </p>
                </div>
                <div>
                  <h6 className={rifasStyle.subtituloinfo}>
                    ¿HASTA CUANDO PUEDO VALIDAR MIS RIFAS?
                  </h6>
                  <p className={rifasStyle.parrafosInfo}>
                    Podes validar tus rifas hasta un dia antes del sorteo.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <section className={rifasStyle.premioscontainer}>
        <div className={rifasStyle.premios}>
          <img src="" alt="Segundo premio" />
          <p className={rifasStyle.premios2}>SEGUNDO PREMIO</p>
        </div>
        <div className={rifasStyle.premios}>
          <img src="" alt="Primer premio" />
          <p className={rifasStyle.premios2}>PRIMER PREMIO</p>
        </div>
        <div className={rifasStyle.premios}>
          <img src="" alt="Tercer premio" />
          <p className={rifasStyle.premios2}>TERCER PREMIO</p>
        </div>
      </section>
      <div className={rifasStyle.terminos}>
        <a href="/bases.pdf">Bases y Condiciones rifas</a>
      </div>
    </>
  );
};
export default Rifas;
