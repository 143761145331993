import React from 'react';
import tallesStyle from "../styles/estilos/Talles.module.css";
import Guiaprimaria from "../images/guiafrenteCell.png";
import tablaPrimaria from "../images/tablaGrande.png";
import guiaPrimaria from "../images/guiaPrimariaDesk.png";
import TablaPrimaria from "../images/TablaPrimariaDesk.png";
import talleDesk from "../images/talleDeskRosa.png";
import talleAzul from "../images/tallesDeskAzul.png";
function GuiaPrimaria(props){
 const handlecloseModal=()=>{
  props.onClose();
 } 


return(
<div className={tallesStyle.modalBackground}>
    <div className={tallesStyle.modalGuias}>
    

      <div className={tallesStyle.modalContentGuiasDesk}>
         <h2 className={tallesStyle.titleDesk}>TALLES <span  className={tallesStyle.pinkspan}> PRIMARIA</span> </h2>
         <p className={tallesStyle.texto}>
         Elegí una prenda que te quede cómoda y tomá la medida
          sobre una superficie plana y bien estirada.
          Si te da ancho Talle 3 y alto Talle 4, elegí, siempre, 
          el más grande de los dos.    

         </p>
       <div className={tallesStyle.deskContainer}>
         
          <div className={tallesStyle.imagenes}>
            <div className={tallesStyle.imagen}>
             <img src={talleDesk} alt="talle"  className={tallesStyle.rosa}/>
             <img src={talleAzul} alt="talle"  className={tallesStyle.azul}/>
            </div>
            <div className={tallesStyle.frentes}>   
             <img src={guiaPrimaria} alt="buzo" className={tallesStyle.frente} />
            </div>
          </div>
         
         <div className={tallesStyle.tablaDesk} >
             <img src={TablaPrimaria} alt="tabla" className={tallesStyle.tabladesk} />
         </div>
         
     </div>

        <button className={tallesStyle.closeDesk} onClick={handlecloseModal}>CERRAR</button>

     </div> 
     <img src={Guiaprimaria} alt=""  className={tallesStyle.guiaCell}/> 
     <p className={tallesStyle.pcell}>Las medidas pueden variar hasta 2cm una vez confeccionadas</p>
     <img src={tablaPrimaria} alt="" className={tallesStyle.tablaCell}/>
     <button className={tallesStyle.closeCell} onClick={handlecloseModal}>CERRAR</button>
    </div>
  </div>
  );

}
export default GuiaPrimaria;