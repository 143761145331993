import { useDataContext } from './AuthContext';
import { useEffect, useState, useContext, createContext } from 'react';
import{getDesignExtra} from"../services/api"



export const ExtraCostContext = createContext();

export const ExtraCostProvider = ({ children }) => {
  const { contextData } = useDataContext();
  const [extraCost, setExtraCost] = useState(0);

  useEffect(() => {
    const fetchExtraCost = async () => {
      try {
        const data = await getDesignExtra(contextData.customer.lead_id);
        setExtraCost(data); // Establece el costo extra en el estado
      } catch (error) {
        console.error('Error al obtener el costo extra:', error);
      }
    };
  
    if (contextData.customer.lead_id) {
      fetchExtraCost();
    }
  }, [contextData.customer.lead_id]);

  return (
    <ExtraCostContext.Provider value={{ extraCost, setExtraCost }}>
      {children}
    </ExtraCostContext.Provider>
  );
};

export function useExtraCostContext() {
  const context = useContext(ExtraCostContext);
  if (!context) {
    throw new Error('useExtraCostContext debe ser utilizado dentro de un ExtraCostProvider');
  }
  return context;
}
