import prendasStyle from "../styles/Prendas.module.css";
import bocetoBuzo from "../images/buzodoble.png";
import AdjuntarImage from "../images/adjuntar.png";
import Danger from "../images/Danger.png";

import { useState } from "react";
import { rejectSketch } from "../services/api.js";
import Swal from "sweetalert2";

import { useDataContext } from "../contexts/AuthContext";

function BocetoModal(props) {
  const [file, setFile] = useState(null);
  const [rejectedComment, setRejectedComment] = useState("");
  const { contextData } = useDataContext();

  const handleCloseModal = () => {
    props.onClose(); // Llama a la función onClose del componente padre para cerrar el modal
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    console.log("Archivo seleccionado:", selectedFile);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();

      formData.append("archivoAdjunto", file);
      formData.append("rejected_comment", rejectedComment);
      formData.append("customer_id", contextData.customer.id);

      const response = await rejectSketch(
        formData,
        props.boceto.order_item_id,
        props.boceto.id
      );
      console.log(response.status);
      if (response.status === 201) {
        Swal.fire({
          icon: "success",
          title: "Boceto rechazado",
          confirmButtonColor: "#0071ce",
        }).then(() => {
          handleCloseModal();
        });
        console.log("Boceto rechazado correctamentee");
      } else {
        console.error("Error al rechazar el boceto!");
         Swal.fire({
          icon: "error",
          title: "Error",
          text: "Hubo un problema al rechazar el boceto.",
          confirmButtonColor: "#0071ce",

        });
      }
    } catch (error) {
      console.error("Error al rechazar el boceto!", error);
       Swal.fire({
        icon: "error",
        title: "Error",
        text: "Hubo un problema al rechazar el boceto.",
        confirmButtonColor: "#0071ce",

      });
    }
  };

  return (
    <div className={prendasStyle.modalBackground}>
      <div className={prendasStyle.modal}>
        <img className={prendasStyle.danger} src={Danger} alt="Danger" />
        <div className="modal-content">
          <img
            className={prendasStyle.imagenBuzo}
            src={props.boceto.filename}
            alt="buzo"
          />
          <form onSubmit={handleSubmit}>
            <div className={prendasStyle.comments}>
              <label
                className={prendasStyle.labelTextarea}
                htmlFor="myTextarea"
              >
                Contanos que cambios queres hacerle al boceto:
              </label>
              <textarea
                className={prendasStyle.commentsRectangle}
                id="myTextarea"
                name="rejectedComment"
                value={rejectedComment}
                onChange={(e) => setRejectedComment(e.target.value)}
              ></textarea>
            </div>
            <p className={prendasStyle.subText}>
              {" "}
              Adjuntanos referencias para poder entenderte mejor
            </p>
            <label className={prendasStyle.labelAdjuntar} htmlFor="fileInput">
              <img
                className={prendasStyle.imagenAdjuntar}
                src={AdjuntarImage}
                alt="Adjuntar archivo"
              />
              Adjuntar
            </label>
            <input
              type="file"
              onChange={handleFileChange}
              style={{ display: "none" }}
              accept=".pdf, .jpg, .png"
              id="fileInput"
              name="fileInput"
            />
            <div className={prendasStyle.buttonsContainer}>
              <button
                className={prendasStyle.buttonThree}
                onClick={handleCloseModal}
              >
                Cerrar
              </button>
              <button className={prendasStyle.buttonThree} type="submit">
                Enviar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default BocetoModal;
