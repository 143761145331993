import { useDataContext } from './AuthContext';
import { useEffect, useState, useContext, createContext } from 'react';
import { getExtraLowerQuantity } from '../services/api'; // Ajusta la ruta según sea necesario


export const LowerQuantityContext = createContext();

export const LowerQuantityProvider = ({ children }) => {
  const { contextData } = useDataContext();
  const [lowerQuantity, setLowerQuantity] = useState(0);

  useEffect(() => {
    const fetchLowerQuantity = async () => {
      if (contextData.customer.lead_id) {
        try {
          const data = await getExtraLowerQuantity(contextData.customer.lead_id);
          setLowerQuantity(data); // Establece el costo extra en el estado
        } catch (error) {
          console.error('Error al obtener el costo extra por menor cantidad de alumnos:', error);
        }
      }
    };
  
    fetchLowerQuantity();
  }, [contextData.customer.lead_id]);
  

  return (
    <LowerQuantityContext.Provider value={{lowerQuantity , setLowerQuantity }}>
      {children}
    </LowerQuantityContext.Provider>
  );
};

export function useLowerQuantityContext() {
  const context = useContext(LowerQuantityContext);
  if (!context) {
    throw new Error('useLowerQuantityContext debe ser utilizado dentro de un ExtraCostProvider');
  }
  return context;
}
