import React from 'react';
import tallesStyle from "../styles/estilos/Talles.module.css";
import guideStyle from "../styles/estilos/guide.module.css";
import fantasma from "../images/fantasmaOpinion.png";
import remera from "../images/guideImages/remera.jpg";
import imagen24 from "../images/guideImages/imagen24.png";
import imagen25 from "../images/guideImages/imagen25.png";
import imagen26 from "../images/guideImages/imagen26.png";
import imagen27 from "../images/guideImages/imagen27.png";
import imagen28 from "../images/guideImages/imagen28.png";
import cartacolores from "../images/guideImages/cartaColoresBuzo.png"
import imagen29 from "../images/guideImages/imagen29.png"
import imagen30 from "../images/guideImages/imagen30.png"
import imagen31 from "../images/guideImages/imagen31.png"
import imagen32 from "../images/guideImages/imagen32.png"
import imagen33 from "../images/guideImages/imagen33.png"



const ModalTutorial4 = ({ onClose, onNext,onBack }) =>{ 

  return(
  
  <div className={tallesStyle.modalBackground}>
   <div className={tallesStyle.modalG1}>
    <div className={tallesStyle.modalContent}></div>

   <div className={guideStyle.cellContent}>
       <div className={guideStyle.number}>
         <h2 className={guideStyle.num}>4</h2>
         <p>CARTA DE COLORES FRISA</p>
       </div>
      <div className={guideStyle.announce}>
        <p>COLORES SUJETOS A DISPONIBILIDAD AL MOMENTO DE PRODUCCIÓN</p>
        <img  className={guideStyle.fantasma} src={fantasma} alt="fantasma"/>
      </div>

      <img className={guideStyle.carta} src={imagen29} alt="carta de colores"/>
    
     <div className={guideStyle.number}>
       <h2 className={guideStyle.num}>5</h2>
       <p>BOLSILLO</p>
     </div>
   <section>
      
   <div className={guideStyle.modelimages2}>

      <div className={guideStyle.category}>
        <p>SIN BOLSILLO</p>
        <img className={guideStyle.img} src={imagen30} alt="sin bolsillo"/>
     </div>

      <div className={guideStyle.category}>
        <p>CANGURO</p>
        <img className={guideStyle.img} src={imagen31} alt="remera"/>
      </div> 

       <div className={guideStyle.category}>
        <p>RECTO</p>
        <img  className={guideStyle.img} src={imagen32} alt="remera"/>
       </div> 
       <div className={guideStyle.category}>
        <p>DIAGONAL</p>
        <img  className={guideStyle.img} src={imagen33} alt="remera"/>
       </div> 

     </div>
   </section>
    
    <div className={guideStyle.buttonContainer}>
      <button className={guideStyle.one}  onClick={onClose}>Cerrar</button>
      <button className={guideStyle.two} onClick={onBack}>Atras</button>
      <button className={guideStyle.two} onClick={onNext}>Siguiente</button>
    </div>
   </div> 
  {/**Escritorio */}
  <div className={guideStyle.deskContent}>

    <div className={guideStyle.number}>
      <h2 className={guideStyle.num}>3</h2>
      <p>TELA</p>
    </div>
  
    <section className={guideStyle.telasDesk}>
      
      <div className={guideStyle.modelimages2}>

      <div className={guideStyle.category}>
        <p>FRISA 100%</p>
        <img  className={guideStyle.img}
        src={imagen24} alt="remera"/>
      </div>

      <div className={guideStyle.category}>
        <p>ROMPEVIENTOS</p>
        <img className={guideStyle.img} src={imagen25} alt="remera"/>
       </div>

       <div className={guideStyle.category}>
        <p>SOFTSHELL</p>
        <img  className={guideStyle.img}
        src={imagen26} alt="remera"/>
      </div> 
  
    </div>
   
    <div className={guideStyle.secondTelas}>
    <h6>TELA  <span className={guideStyle.blue} >SUBLIMABLE</span> </h6>

      <div className={guideStyle.modelimages2}>

     

      <div className={guideStyle.category}>
        <p>FRISA SUBLIMABLE</p>
        <img  className={guideStyle.img}
        src={imagen27} alt="remera"/>
      </div>

      <div className={guideStyle.category}>
        <p>DEPORTIVO SUBLIMABLE</p>
        <img className={guideStyle.img} src={imagen28} alt="remera"/>
       </div>
     </div>
     </div>
    </section>

    <div className={guideStyle.number}>
      <h2 className={guideStyle.num}>4</h2>
      <p>CARTA DE COLORES FRISA</p>
    </div>
    <img className={guideStyle.cartaC} src={cartacolores} alt="carta de colores"/>
  <div className={guideStyle.buttonContainer4}>
      <button className={guideStyle.one}  onClick={onClose}>Cerrar</button>
      <button className={guideStyle.two} onClick={onBack}>Atras</button>
      <button className={guideStyle.two} onClick={onNext}>Siguiente</button>
    </div>
  </div>
 
  </div>
  </div>
   );
 };
  export default ModalTutorial4;