import { useNavigate } from "react-router-dom";
import "../styles/estilos/cargaCorrecta.css";
import manook from "../images/manoOk.png"

export default function CargaExitosa() {
  const navigate = useNavigate();

  const handleVolver = () => {
    navigate("/mcb/prendas");
  };

  return (
    <div className="container-fluid d-flex align-items-center justify-content-center fondo">
      <div className="text-center p-4">
      <img className="correcto-icono" src={manook} alt="" />
        {/* <i class="bi bi-hand-thumbs-up correcto-icono"></i> */}
        {/* <h1 className="enviado">Enviado!!</h1> */}
        <div className="mensaje mt-5">
          <h3 className="text-break">
            Tu talle fue cargado correctamente!
          </h3>
        </div>
        <button className="btn boton" onClick={handleVolver}>Volver</button>
      </div>
    </div>
  );
}
