import React, { useState } from "react";
import "../styles/estilos/registro.css";
import { userLogin } from "../services/api";
import { registrarUsuario } from "../services/api";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export default function Register() {
  const navigate = useNavigate();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const [showEmptyFieldsError, setShowEmptyFieldsError] = useState(false);
  const [formData, setFormData] = useState({
    // mcb_code: "",
    loginCode: "",
    dni: "",
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
  });

  const [errorMessages, setErrorMessages] = useState({
    // mcb_code: "",
    loginCode: "",
    dni: "",
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
  });

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let errorMessage = "";
    // Realizar validaciones y establecer mensaje de error
    if (name === "mcb_code" && value.length >= 6) {
      errorMessage = "Debe tener hasta 6 caracteres.";
    } else if (name === "loginCode" && !/^\d+$/.test(value)) {
      errorMessage = "Debe contener solo números.";
    } else if (name === "dni" && !/^\d{8}$/.test(value)) {
      errorMessage = "El DNI debe ser un número de 8 dígitos.";
    } else if (name === "firstname" && !/^[a-zA-Z ]+$/.test(value)) {
      errorMessage = "No debe tener números ni caracteres especiales.";
    } else if (name === "lastname" && !/^[a-zA-Z ]+$/.test(value)) {
      errorMessage = "No debe tener números ni caracteres especiales.";
    } else if (name === "phone" && !/^\d+$/.test(value)) {
      errorMessage = "El teléfono debe contener solo números.";
    } else if (name === "email" && !/\S+@\S+\.\S+/.test(value)) {
      errorMessage = "Ingrese un email válido.";
    }
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrorMessages({
      ...errorMessages,
      [name]: errorMessage,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validar que todos los campos estén completos
    const emptyFields = Object.values(formData).some((value) => value === "");

    if (emptyFields) {
      setShowEmptyFieldsError(true);
      return;
    }
    // Construir el objeto de datos a enviar
    const datos = {
      // mcb_code: formData.mcb_code,
      loginCode: formData.loginCode,
      dni: formData.dni,
      firstname: formData.firstname,
      lastname: formData.lastname,
      phone: formData.phone,
      email: formData.email,
    };
    console.log(datos);

    registrarUsuario(formData)
      .then((data) => {
        // Aquí puedes manejar la respuesta del servidor
        console.log("Respuesta del servidor:", data);
        // Mostrar mensaje de éxito y botón de ingresar
        setShowSuccessMessage(true);
      })
      .catch((error) => {
        // Aquí puedes manejar errores de la solicitud
        console.error("Error al enviar la solicitud:", error);

        Swal.fire({
          icon: "error",
          title: "Error",
          text: "A ingresado algun dato incorrecto, por favor verifique.",
        });
      });
  };

  const handleGoBack = () => {
    navigate(-1); 
  };

  return (
    <>
      <div className="container-fluid p-0">
        <form
          onSubmit={handleSubmit}
          className="bg-dark text-white p-5 estiloform bg-image formRegistro"
        >
          <div className="text-center mb-5">
            <h1 className="mb-3 textoh1">
              Crear <span style={{ color: "#d54ab7" }}>Cuenta</span> Blue!
            </h1>
          </div>

          <div className="input-container">
            {showEmptyFieldsError && (
              <div className="alert alert-danger mb-3" role="alert">
                Por favor, complete todos los campos.
              </div>
            )}
          </div>

          {/* <div className="mb-3">
            <label htmlFor="mcb_code" className="form-label">
              Código de curso:
            </label>
            <input
              type="text"
              name="mcb_code"
              value={formData.mcb_code}
              onChange={handleChange}
              className="custom-input2"
            />
            <div className="invalid-feedback d-block">
              {errorMessages.mcb_code}
            </div>
          </div>
 */}
          <div className="mb-3">
            <label htmlFor="loginCode" className="form-label">
              Código de acceso:
            </label>
            <input
              type="text"
              name="loginCode"
              value={formData.loginCode}
              onChange={handleChange}
              className="custom-input2"
              required
            />
            <div className="invalid-feedback d-block">
              {errorMessages.loginCode}
            </div>
          </div>

          <div className="mb-3">
            <label htmlFor="dni" className="form-label">
              DNI:
            </label>
            <input
              type="text"
              name="dni"
              value={formData.dni}
              onChange={handleChange}
              className=" custom-input2"
              required
            />
            <div className="invalid-feedback d-block">{errorMessages.dni}</div>
          </div>

          <div className="mb-3">
            <label htmlFor="firstname" className="form-label">
              Nombre:
            </label>
            <input
              type="text"
              name="firstname"
              value={formData.firstname}
              onChange={handleChange}
              className="custom-input2"
              required
            />
            <div className="invalid-feedback d-block">
              {errorMessages.firstname}
            </div>
          </div>

          <div className="mb-3">
            <label htmlFor="lastname" className="form-label">
              Apellido:
            </label>
            <input
              type="text"
              name="lastname"
              value={formData.lastname}
              onChange={handleChange}
              className=" custom-input2"
              required
            />
            <div className="invalid-feedback d-block">
              {errorMessages.lastname}
            </div>
          </div>

          <div className="mb-3">
            <label htmlFor="phone" className="form-label">
              Teléfono:
            </label>
            <input
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className=" custom-input2"
              required
            />
            <div className="invalid-feedback d-block">
              {errorMessages.phone}
            </div>
          </div>

          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email:
            </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="custom-input2"
              required
            />
            <div className="invalid-feedback d-block">
              {errorMessages.email}
            </div>
          </div>
          {showSuccessMessage && (
            <div
              className="alert alert-success mb-3 messegeregistro"
              role="alert"
            >
              Cuenta creada Exitosamente.
              <button
                className="btn btn-success ml-2 botoningresar"
                onClick={() => navigate("/")}
              >
                Ingresar
              </button>
            </div>
          )}

          <div className="mt-3 mb-1 d-flex justify-content-center align-items-center">
          <button className="btn btn-primary btn-color2"  onClick={handleGoBack}>volver</button>
            <button type="submit" className="btn btn-primary btn-color">
              Registrarse
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
