// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Estilos para el fondo oscuro detrás del modal */
.modalMapStyle_modal-background__29\\+jP {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo oscuro con opacidad */
  z-index: 1000; /* Asegura que esté por encima de otros elementos */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Estilos para el contenedor del modal */
.modalMapStyle_modal-container__hSHxh {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  width: 80%;
  max-width: 600px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5); /* Sombra suave */
}

/* Estilos para el botón de cerrar el modal */
.modalMapStyle_modal-close-button__AKtQr {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

/* Estilos para el contenido del modal */
.modalMapStyle_modal-content__vGF2H {
  /* Agrega estilos adicionales según tus necesidades */
}

/* Estilos para el iframe del mapa */
.modalMapStyle_modal-map-iframe__3FGEE {
  width: 100%;
  height: 400px; /* Altura del mapa ajustable según tus necesidades */
}
`, "",{"version":3,"sources":["webpack://./src/styles/estilos/modalMapStyle.module.css"],"names":[],"mappings":"AAAA,kDAAkD;AAClD;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oCAAoC,EAAE,8BAA8B;EACpE,aAAa,EAAE,mDAAmD;EAClE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA,yCAAyC;AACzC;EACE,uBAAuB;EACvB,kBAAkB;EAClB,aAAa;EACb,UAAU;EACV,gBAAgB;EAChB,+CAA+C,EAAE,iBAAiB;AACpE;;AAEA,6CAA6C;AAC7C;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,eAAe;AACjB;;AAEA,wCAAwC;AACxC;EACE,qDAAqD;AACvD;;AAEA,oCAAoC;AACpC;EACE,WAAW;EACX,aAAa,EAAE,oDAAoD;AACrE","sourcesContent":["/* Estilos para el fondo oscuro detrás del modal */\n.modal-background {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.5); /* Fondo oscuro con opacidad */\n  z-index: 1000; /* Asegura que esté por encima de otros elementos */\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n/* Estilos para el contenedor del modal */\n.modal-container {\n  background-color: white;\n  border-radius: 8px;\n  padding: 20px;\n  width: 80%;\n  max-width: 600px;\n  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5); /* Sombra suave */\n}\n\n/* Estilos para el botón de cerrar el modal */\n.modal-close-button {\n  position: absolute;\n  top: 10px;\n  right: 10px;\n  cursor: pointer;\n}\n\n/* Estilos para el contenido del modal */\n.modal-content {\n  /* Agrega estilos adicionales según tus necesidades */\n}\n\n/* Estilos para el iframe del mapa */\n.modal-map-iframe {\n  width: 100%;\n  height: 400px; /* Altura del mapa ajustable según tus necesidades */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal-background": `modalMapStyle_modal-background__29+jP`,
	"modal-container": `modalMapStyle_modal-container__hSHxh`,
	"modal-close-button": `modalMapStyle_modal-close-button__AKtQr`,
	"modal-content": `modalMapStyle_modal-content__vGF2H`,
	"modal-map-iframe": `modalMapStyle_modal-map-iframe__3FGEE`
};
export default ___CSS_LOADER_EXPORT___;
