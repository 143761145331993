import React, { useEffect, useState } from "react";
import { useDataContext } from "../contexts/AuthContext";
import AccountsSummaryStyles from "../styles/AccountsSummary.module.css";
import { fetchCustomersSummary } from "../services/api.js";
import { useNavigate } from "react-router-dom";

const AccountsSummary = () => {
  const navigate = useNavigate();
  const { contextData } = useDataContext();
  const [usersSummary, setUsersSummary] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState("all"); // Estado local para el filtro
  const [totalPages, setTotalPages] = useState(1);

  const fetchData = async (page) => {
    try {
      const data = await fetchCustomersSummary(
        contextData.customer.lead_id,
        page
      );
      setUsersSummary(data.users);
      setTotalPages(data.totalPages);
      console.log(data);
    } catch (error) {
      console.error("Error fetching user summary data:", error);
    }
  };

  useEffect(() => {
    if (contextData.customer && contextData.customer.lead_id) {
      fetchData(currentPage);
    }
  }, [contextData, currentPage]);

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const applyFilter = (user) => {
    if (filter === "all") {
      return true; // Mostrar todos los usuarios
    } else if (filter === "delayed") {
      return user.estado === "Demorado"; // Mostrar solo los demorados
    } else if (filter === "onTime") {
      return user.estado === "Al día"; // Mostrar solo los que están al día
    } else if (filter === "paidOff") {
      return user.estado === "Saldado"; //Mostrar los que ya estan saldados
    } else if (filter === "withoutItem") {
      return user.estado === "Sin prenda elegida"; //Mostrar los que ya estan saldados
    }

    return true;
  };

  const comprarPrenda = () => {
    const userKey = `hide-cloth-selection-modal-${contextData.customer.id}`;
    localStorage.setItem(userKey, "true");
    navigate("/mcb");
  };
  return (
    <div className="table-responsive">
      <div className={AccountsSummaryStyles.container}>
        <h2 className={AccountsSummaryStyles.component_title}>
          Resumen de Cuentas
        </h2>
        <select
          onChange={(e) => setFilter(e.target.value)}
          value={filter}
          className={AccountsSummaryStyles.filterSelect}
        >
          <option value="all">Todos</option>
          <option value="delayed">Demorados</option>
          <option value="onTime">Al día</option>
          <option value="paidOff">Saldado</option>
          <option value="withoutItem">Sin prenda</option>
        </select>
      </div>
      <table className={`${AccountsSummaryStyles.table_accountSummary}`}>
        <thead>
          <tr>
            <th className="th_accountSummary">ALUMNO</th>
            <th className="th_accountSummary">ESTADO DE CUOTA</th>
            <th className="th_accountSummary">ABONADO</th>
            <th className="th_accountSummary">PENDIENTE A ABONAR</th>
          </tr>
        </thead>
        <tbody>
          {usersSummary.filter(applyFilter).map((user, index) => (
            <tr key={index}>
              <td>{user.nombre}</td>
              <td
                className={
                  user.estado === "Demorado" || user.estado === "Sin prenda elegida"
                    ? AccountsSummaryStyles.deudor
                    : AccountsSummaryStyles.alDia
                }
              >
                {user.estado}
              </td>
              <td> ${user.abonado}</td>
              <td> ${user.pendiente}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <span className={AccountsSummaryStyles.pageNumber}>
        {currentPage}/{totalPages}
      </span>
      <div>
        <button
          onClick={comprarPrenda}
          className={AccountsSummaryStyles.botones}
        >
          Comprar prendas
        </button>
      </div>
      <div className={AccountsSummaryStyles.buttonsContainer}>
        <button
          onClick={handlePreviousPage}
          // className={AccountsSummaryStyles.botones}
          className={`${AccountsSummaryStyles.botones} ${
            currentPage === 1 ? AccountsSummaryStyles.disabledButton : ""
          }`}
          disabled={currentPage === 1}
        >
          VOLVER
        </button>
        <button
          onClick={handleNextPage}
          className={`${AccountsSummaryStyles.botones} ${
            currentPage === totalPages
              ? AccountsSummaryStyles.disabledButton
              : ""
          }`}
          disabled={currentPage === totalPages}
        >
          SIGUIENTE
        </button>
      </div>
    </div>
  );
};

export default AccountsSummary;
