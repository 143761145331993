// Importa los contextos
import { useDataContext } from './AuthContext';
import { useEffect, useState, useContext, createContext } from 'react';
import { getUserDebts } from '../services/api';
// Crea un nuevo contexto para las deudas del usuario
export const DebtsContext = createContext();

// Proveedor del contexto para las deudas
export function DebtsContextProvider(props) {
  
  const { contextData } = useDataContext();
  const userID = contextData.customer.id;

  // Estado para almacenar las deudas del usuario
  const [userDebts, setUserDebts] = useState([]);

  // Función para obtener las deudas del usuario utilizando su ID
  useEffect(() => {
    const fetchUserDebts = async () => {
      if (userID) {
        try {
          const data = await getUserDebts(userID);
          setUserDebts(data); // Establece las deudas en el estado
        } catch (error) {
          console.error('Error al obtener las deudas del usuario:', error);
        }
      }
    };
  
    fetchUserDebts();
  }, [userID]);
  
  const value = { userDebts, setUserDebts };

  return (
    <DebtsContext.Provider value={value}>
      {props.children}
    </DebtsContext.Provider>
  );
}

// Utiliza este hook en tus componentes para acceder a las deudas del usuario
export function useDebtsContext() {
  const context = useContext(DebtsContext);
  if (!context) {
    throw new Error('useDebtsContext debe ser utilizado dentro de un DebtsContextProvider');
  }
  return context;
}

