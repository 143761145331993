import { useEffect, useState } from "react";
import iconoEditar from "../images/iconoEditar.png";
import iconoPapelera from "../images/iconoPapelera.png";
import SizeStyle from "../styles/estilos/Size.module.css";
import Swal from "sweetalert2";
import { getSizes, deleteSize } from "../services/api";
import EditarTalle from "./EditarTalle";
import { useDataContext } from "../contexts/AuthContext";
import Prendas from "./Prendas";

const SizeTable = ({ orderItemId, onClose, paxes, botonHabilitado }) => {
  const [sizes, setSizes] = useState([]);
  const [editingSizeId, setEditingSizeId] = useState(null);
  const { contextData } = useDataContext();
  const [sizeName, setSizeName] = useState(null);

  const [sizeNickname, setsizeNickname] = useState(null);

  useEffect(() => {
    // if(!orderItemId || !editingSizeId){
    //   return
    // }
    const fetchData = async () => {
      try {
        const data = await getSizes(orderItemId);
        console.log("Datos recibidos:", data);
        setSizes(data);
        console.log("talles:", sizes);
        console.log("talles1:", sizes[0].size);
        const countByCategoryAndSize = {
          primaria: {},
          secundaria: {},
          oversize: {},
        };

        data.forEach((item) => {
          let category = "";
          let size = item.size.toLowerCase(); // Convertir a minúsculas para normalizar
          size = size.replace(/\W/g, "");
          // Determinar la categoría
          if (size.includes("p")) {
            category = "primaria";
          } else if (["xs", "s", "m", "l", "xl", "xxl"].includes(size)) {
            category = "oversize";
          } else if (/^\d{1,2}$/.test(size)) {
            category = "secundaria";
          } else {
            category = "otros"; // Opcional: categoría para casos no reconocidos
          }

          // Inicializar el contador para la categoría si es necesario
          if (!countByCategoryAndSize[category][size]) {
            console.log(countByCategoryAndSize[category][size]);
            countByCategoryAndSize[category][size] = 0;
          } else {
            countByCategoryAndSize[category][size]++;
          }

          // Incrementar el contador para la talla y categoría correspondientes
          countByCategoryAndSize[category][size]++;
          console.log("aca estoyyyyy");
          // Console.log para ver cómo se cuentan los tamaños
          console.log(`Tamaño ${size} en categoría ${category}`);
        });

        console.log("Tamaños por categoría y talla:", countByCategoryAndSize);
      } catch (error) {
        console.error("Error fetching sizes:", error);
      }
    };

    fetchData();
  }, [orderItemId, editingSizeId]);

  const deleteAlert = async (sizeId) => {
    const { id, customer_type } = contextData.customer;
    Swal.fire({
      title: "Seguro desea borrar este talle?",
      icon: "warning",
      iconColor: "#d54ab7",
      showCancelButton: true,
      confirmButtonColor: "#0071ce",
      confirmButtonText: "confirmar",
      cancelButtonText: "Cancelar",
      cancelButtonColor: "#d54ab7",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteSize(sizeId);
          // Elimina el tamaño del estado para reflejar el cambio
          setSizes((prevSizes) =>
            prevSizes.filter((size) => size.id !== sizeId)
          );
          Swal.fire("Talle eliminado!", "", "success");
        } catch (error) {
          console.error("Error deleting size:", error);
          Swal.fire("Error", "Error al eliminar el tamaño", "error");
        }
      } else if (result.isDenied) {
        Swal.fire("Talle no eliminado", "", "info");
      }
    });
  };

  const editSize = (sizeId, name, nickname) => {
    const { id, customer_type } = contextData.customer;
    const sizeToEdit = sizes.find((size) => size.id === sizeId);
    console.log(sizeToEdit);
    if (id === sizeToEdit?.customer_id) {
      setEditingSizeId(sizeId);
      setSizeName(name)
      setsizeNickname(nickname)
    } else if (customer_type === 3 || customer_type === 2) {
      setEditingSizeId(sizeId);
      setSizeName(name)

    } else {
      Swal.fire({
        title: "Puedes editar tu talle, no el de otra persona.",

        icon: "info",
        iconColor: "#d54ab7",
        showCancelButton: false,
        confirmButtonColor: "#0071ce",
        denyButtonText: `no`,
      });
    }
  };

  const closeEditModal = () => {
    setEditingSizeId(null);
  };

  return (
    <div className={SizeStyle.tableContainer}>
      <h4>
        {" "}
        Cargados: {sizes.length} de {paxes}
      </h4>
      <table className={SizeStyle.table}>
        <thead>
          <tr className={SizeStyle.tr}>
            <th className={SizeStyle.th}></th>
            <th className={SizeStyle.th}>ALUMNO</th>
            <th className={SizeStyle.th}>TALLES</th>
            <th className={SizeStyle.th}>APODO</th>
            <th className={SizeStyle.th}>PERSONAL</th>
            <th className={SizeStyle.th}></th>
          </tr>
        </thead>
        <tbody>
          {sizes.map((size) => {
            const sizeClean = size.size
              .toLowerCase()
              .replace(/\s*\(.*?\)\s*/g, "");
            const isEditableByCurrentUser =
              contextData.customer.customer_type !== 1 ||
              size.customer_id === contextData.customer.id;
            return (
              <tr key={size.id}>
                <td>
                  {botonHabilitado && isEditableByCurrentUser && (
                    <button
                      className={SizeStyle.editar}
                      onClick={() => editSize(size.id, size.name)}
                    >
                      <img
                        className={SizeStyle.imgeditar}
                        src={iconoEditar}
                        alt="icono1"
                      />
                    </button>
                  )}
                </td>
                <td>{size.name}</td>
                <td>{sizeClean}</td>
                <td>{size.nickname}</td>
                <td>{size.initials}</td>
                <td>
                  {botonHabilitado && isEditableByCurrentUser && (
                    <button
                      onClick={() => {
                        console.log(
                          "usuario",
                          size.customer_id,
                          contextData.customer.id
                        );
                        deleteAlert(size.id);
                      }}
                      className={SizeStyle.eliminar}
                      //disabled={contextData.customer.customer_type === 1}
                    >
                      <img
                        className={SizeStyle.imgeeliminar}
                        src={iconoPapelera}
                        alt="icono2"
                      />
                    </button>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {editingSizeId !== null && (
        <EditarTalle
          sizeId={editingSizeId}
          sizeName={sizeName}
          sizeNickname={sizeNickname}
          onCloseDos={onClose}
          onClose={closeEditModal}
          setEditingSizeId={setEditingSizeId}
        />
      )}
    </div>
  );
};

export default SizeTable;
