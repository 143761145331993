import React from 'react';
import tallesStyle from "../styles/estilos/Talles.module.css";
import guideStyle from "../styles/estilos/guide.module.css";
import fantasma from "../images/fantasmaOpinion.png";
import imagen42 from "../images/guideImages/imagen42.png";
import imagen43 from "../images/guideImages/imagen43.png";
import imagen44 from "../images/guideImages/imagen44.png";
const ModalTutorialIntermidiate=({ onClose,onBack,onNext }) =>{ 

  return(
<div className={tallesStyle.modalBackground}>
   <div className={tallesStyle.modalG1}>
    <div className={tallesStyle.modalContent}></div>
    <div className={guideStyle.deskContent}>

    <div className={guideStyle.number}>
      <h2 className={guideStyle.num}>10</h2>
      <p>TIPOGRAFIAS</p>
    </div>
    <section className={guideStyle.tipografiasContainer}>
      <div>
      <h6>TIPOGRAFIAS DE  <span className={guideStyle.blue}>BORDADO</span></h6>
      <img className={guideStyle.tipografias} src={imagen42} alt="tipografias de bordado"/>
      
    <h6>TIPOGRAFIAS DE  <span className={guideStyle.blue}>ESTAMPADO</span></h6>
    <img className={guideStyle.tipografias} src={imagen44} alt="tipografias de estampado"/>
    </div>
    <div>
     <h6>TIPOGRAFIAS DE  <span className={guideStyle.blue}>APLIQUE</span></h6>
     <img className={guideStyle.tipografias} src={imagen43} alt="tipografias de aplique"/>
   </div>
   </section>
     <div className={guideStyle.buttonContainerInter}>
       <button className={guideStyle.one}  onClick={onClose}>Cerrar</button>
       <button className={guideStyle.two} onClick={onBack}>Atras</button>
       <button className={guideStyle.two} onClick={onNext}>Siguiente</button>
     </div>
</div>     
</div>
</div>
  )
};
 export default ModalTutorialIntermidiate;