import React from 'react';
import tallesStyle from "../styles/estilos/Talles.module.css";
import guideStyle from "../styles/estilos/guide.module.css";
import fantasma from "../images/fantasmaOpinion.png";

import remera40 from "../images/remeras/remera40.png";


const GuiaRemera7 = ({ onClose,onBack }) =>{ 

  return(
  
  <div className={tallesStyle.modalBackground}>
   <div className={tallesStyle.modalG1}>
    <div className={tallesStyle.modalContent}></div>
    <div className={guideStyle.cellContent}>
     
     <div className={guideStyle.number}>
      <h2 className={guideStyle.num}>11</h2>
      <p>UBICACIONES</p>
    </div>
   
    <img  className={guideStyle.onlyimg} src={remera40} alt="medidas"/>
    
    <div className={guideStyle.buttonContainer}>
      <button className={guideStyle.one}  onClick={onClose}>Cerrar</button>
      <button className={guideStyle.two} onClick={onBack}>Atras</button>
    </div>
    </div>
    {/**desk */}
    <div className={guideStyle.deskContent}>
      <div className={guideStyle.imagecontent}>
      <div className={guideStyle.number}>
        <h2 className={guideStyle.num}>11</h2>
        <p>UBICACIONES</p>
      </div>
      <img  className={guideStyle.onlyimgdesk} src={remera40} alt="medidas"/>
      </div>
     <div className={guideStyle.buttonContainerlast}>
        <button className={guideStyle.one}  onClick={onClose}>Cerrar</button>
        <button className={guideStyle.two} onClick={onBack}>Atras</button>
     </div>
    </div>
  </div>
  </div>
   );
 };
  export default GuiaRemera7;