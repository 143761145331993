  

import React, { createContext, useContext, useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { jwtDecode } from 'jwt-decode';

export const DataContext = createContext();

export function DataContextProvider(props){
    const cookies = new Cookies();
    const [contextData, setContextData] = useState({customer: ""});

    useEffect(() => {
        const savedToken = cookies.get('token');
        if (savedToken) {
            try {
                const decodedToken = jwtDecode(savedToken);
                console.log('Decoded token:', decodedToken);
                setContextData(decodedToken);
            } catch (error) {
                console.error('Error decoding token:', error);
            }
        }
    }, []);

    const updatedSetContextData = (newContextData) => {
        setContextData(jwtDecode(newContextData));
        cookies.set('token', newContextData, { path: '/'});
    };

    const value = { contextData, setContextData: updatedSetContextData };

    return (
        <DataContext.Provider value={value}>
            {props.children}
        </DataContext.Provider>
    );
}

export function useDataContext(){
    const context = useContext(DataContext);
    if(!context){
        throw new Error('useDataContext must be used within a DataContextProvider')
    }
    return context;
}

