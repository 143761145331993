import enviosStyle from "../styles/estilos/envios.module.css";
import { useDataContext } from "../contexts/AuthContext";
import { uploadOrderItemGuide } from "../services/api.js"
import { useState } from "react";
import Swal from 'sweetalert2'

const CargarGuia = ({
  cerrarModalCargarGuia,
  prendaSeleccionada,
  orderItemId,
}) => {
  const [files, setFiles] = useState([]);
  const { contextData } = useDataContext();

  const handleCloseModal = () => {
    cerrarModalCargarGuia();
  };

  const handleFileInputChange = (e) => {
    e.preventDefault();
    const selectedFiles = e.target.files;
    setFiles([...files, ...selectedFiles]);
  };

  const renderFileList = () => {
    return (
      <ul>
        {files.map((file, index) => (
          <li key={index}>
            {file.name}
          </li>
        ))}
      </ul>
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();

      formData.append("order_item_id", orderItemId);
      formData.append("order_item_file_type", 2);
      formData.append("comment", null);
      formData.append("uploaded_by_id", contextData.customer.id);
      formData.append("filename", null);

      files.forEach((file) => {
        formData.append(`archivosAdjuntos`, file);
      });

      const response = await uploadOrderItemGuide(
        formData,
        orderItemId
      );
      if (response.status === 201) {
        console.log("Guias subidas correctamente!");
        Swal.fire({
            icon: 'success',
            iconColor: '#d54ab7',
            confirmButtonColor:'#0071ce',
            title: 'Exito!',
            text: 'Guías subidas correctamente!',
        });
        handleCloseModal();
      } else {
        console.error("Error al subir las guías!");
        Swal.fire({
            icon: 'error',
            iconColor: '#d54ab7',
            confirmButtonColor:'#0071ce',
            title: 'Hubo un error!',
            text: 'Ha ocurrido un error al subir las guías!',
        });
        //setErrorModal
      }
    } catch (error) {
      console.error("Error al subir las guías!", error);
    }
  };

  return (
    <div className={enviosStyle.modalBackground}>
      <div className={enviosStyle.modal}>
        <div className={enviosStyle.contenido}>
          <form onSubmit={handleSubmit}>
            <h3 className="mb-3">
              Cargar Guías para {prendaSeleccionada.product_name}
            </h3>
            <div class="mb-3">
              <label for="formFile mb-3">
                Seleccione las guías para enviar:
              </label>
              <input
                type="file"
                accept=".pdf, .jpg, .png"
                id="formFile"
                className="mt-3 form-control"
                onChange={handleFileInputChange}
                required
                multiple
              />
            </div>
            {files.length > 0 && (
              <div className="mb-3">
                <p>Archivos seleccionados:</p>
                {renderFileList()}
              </div>
            )}
            <div className={enviosStyle.buttonContainer}>
              <button className={enviosStyle.next} onClick={handleCloseModal}>
                CERRAR
              </button>
              <button className={enviosStyle.next} type="submit">
                CARGAR
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CargarGuia;
