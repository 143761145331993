import React, { useState, useEffect } from "react";
import Estilos from "../styles/CargarTalles.module.css";
import confirmar from "../styles/ConfirmarTalle.module.css";
import danger from "../images/Danger.png";
import fantasmita2 from "../images/fantasmita2.png";
import arcoiris2 from "../images/arcoiris2.png";
import x from "../images/x.png";
import catalogoEmojis from "../images/catalogoemojis.png";
import prendasStyle from "../styles/Prendas.module.css";
import { useDataContext } from "../contexts/AuthContext";
import Swal from "sweetalert2";
import { fetchAllUsers, getSizeById } from "../services/api";
import { editSize } from "../services/api";

const EditarTalle = ({
  orderItemId,
  sizeId,
  setEditingSizeId,
  onClose,
  onCloseDos,
  sizeName,
  sizeNickname,
}) => {
  const [alumno, setAlumno] = useState("");
  const [selectedAlumno, setSelectedAlumno] = useState("");
  const [apodo, setApodo] = useState("");
  const [personalizado, setPersonalizado] = useState("");
  const [emojiOpen, setEmojiOpen] = useState(false);
  const [jardinVisible, setJardinVisible] = useState(false);
  const [jardinSizes, setJardinSizes] = useState(Array(11).fill(false));
  const [oversize, setOversize] = useState(false);
  const [oversizeSizes, setOversizeSizes] = useState(Array(6).fill(false));
  const [secundariaVisible, setSecundariaVisible] = useState(false);
  const [secundariaSizes, setSecundariaSizes] = useState(Array(12).fill(false));
  const [allUsers, setAllUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedSizeType, setSelectedSizeType] = useState("");
  const [newSize, setNewSize] = useState("");
  const { contextData } = useDataContext();
  const { customer } = contextData;
  const customer_id = customer.id;
  const [sizeData, setSizeData] = useState(null);
  const [currentSize, setCurrentSize] = useState("");
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if(!customer.customer_type || !customer.first_name){
      return
    }
    if (customer.customer_type === 2 || customer.customer_type === 3) {
      fetchAllUsers(contextData.customer.lead_id)
        .then((users) => {
          setAllUsers(users);
        })
        .catch((error) => console.error("Error al obtener usuarios:", error));
    } else {
      setAllUsers([]);
      setAlumno(customer.first_name || "");
    }
  }, [customer.customer_type, customer.first_name]);

  useEffect(() => {
    if(!orderItemId){
      return
    }
    const fetchSizeInfo = async (sizeId) => {
      const data = await getSizeById(sizeId);
      setSizeData(data);

      setAlumno(data.name || "");
      setApodo(data.nickname || "");
      setPersonalizado(data.comments || "");
      const sizeValue = data.size;
      setCurrentSize(sizeValue);
    };

    fetchSizeInfo(sizeId);
  }, [orderItemId]);

  const handleAlumnoChange = (e) => {
    setAlumno(e.target.value);
  };

  const handleSelectedAlumnoChange = (e) => {
    const selectedUserId = parseInt(e.target.value); // Convertir a número entero
    setSelectedAlumno(selectedUserId);

    // Buscar el usuario seleccionado en la lista de usuarios
    const selectedUser = allUsers.find((user) => user.id === selectedUserId);

    if (selectedUser) {
      setAlumno(selectedUser.first_name + " " + selectedUser.last_name);
    } else {
      setAlumno("");
      console.log(
        "Usuario no encontrado. Nombre del alumno actualizado a vacío."
      );
    }
  };

  const uploadSizes = async (e) => {
    e.preventDefault();

    // Verificar la disponibilidad de customer_id
    if (!customer_id) {
      console.error("customer_id no está disponible");
      // Puedes mostrar un mensaje de error al usuario o tomar otra acción apropiada
      return;
    }

    console.log("orderItemId:", orderItemId);
    const selectedSizes = jardinVisible
      ? jardinSizes
      : oversize
      ? oversizeSizes
      : secundariaSizes;

    const newSizeIndex = selectedSizes.findIndex((size) => size);
    let newSize = "";
    if (newSizeIndex >= 0) {
      if (jardinVisible) {
        newSize = `p${newSizeIndex * 2 + 4}`;
      } else if (oversize) {
        newSize = ["XS", "S", "M", "L", "XL", "XXL"][newSizeIndex];
      } else if (secundariaVisible) {
        newSize =
          newSizeIndex === secundariaSizes.length - 1
            ? "12"
            : `${newSizeIndex}`;
      }
    }
    setNewSize(newSize);
    if (newSize === "") {
      Swal.fire({
        icon: "warning",
        iconColor: "#d54ab7",
        confirmButtonColor: "#0071ce",
        title: "Atención",
        text: "Debes seleccionar un talle.",
      });
      return;
    } else if (sizeName === "") {
      Swal.fire({
        icon: "warning",
        iconColor: "#d54ab7",
        confirmButtonColor: "#0071ce",
        title: "Atención",
        text: "Debes seleccionar un alumno.",
      });
      return;
    }

    const data = {
      name: sizeName,
      nickname: apodo,
      initials: personalizado,
      comments: null,
      gender: "f",
      width: 0.0,
      height: 0.0,
      size: newSize,
      order_item_id: orderItemId,
      created_by_id: customer_id,
      customer_id: customer_id,
    };

    console.log("Datos a enviar:", data);
    console.log("Customer ID:", customer_id);

    try {
      const jsonResponse = await editSize(data, sizeId);
      console.log("Talle editado exitosamente:", jsonResponse);
      // Aquí mostrar SweetAlert de éxito
      Swal.fire({
        icon: "success",
        iconColor: "#d54ab7",
        title: "Carga correcta!",
        text: "¡El talle fue editado correctamente!",
        confirmButtonColor: "#0071ce",
      }).then(async (result) => {
        if (result.isConfirmed) {
          handleCancelarEnviar();
        }
      });
    } catch (error) {
      console.error("Error al crear el talle:", error.message);
      // Aquí podrías mostrar un SweetAlert de error si hay un problema al cargar las tallas
      Swal.fire({
        icon: "error",
        title: "Error al editar talles",
        text: "Hubo un problema al editar los talles. Por favor, intenta de nuevo más tarde.",
      });
    }
  };

  const handleApodoChange = (e) => {
    setApodo(e.target.value);
  };

  const handlePersonalizadoChange = (e) => {
    setPersonalizado(e.target.value);
  };

  const toggleEmojiMenu = () => {
    setEmojiOpen(!emojiOpen);
  };

  const togglePrimaria = () => {
    setJardinVisible(!jardinVisible);
    setSelectedSizeType("Jardin/Primaria");
  };

  const toggleOversize = () => {
    setOversize(!oversize);
    setSelectedSizeType("Oversize");
  };

  const toggleSecundaria = () => {
    setSecundariaVisible(!secundariaVisible);
    setSelectedSizeType("Secundaria");
  };

  const handleCheckboxChange = (index) => {
    setJardinSizes((prevSizes) => {
      const updatedJardinSizes = prevSizes.map((size, i) =>
        i === index ? !size : false
      );
      const newSizeIndex = updatedJardinSizes.findIndex((size) => size);
      const newSize = newSizeIndex >= 0 ? `p${newSizeIndex * 2 + 4}` : "";
      setNewSize(newSize);
      setOversizeSizes(Array(6).fill(false));
      setSecundariaSizes(Array(12).fill(false));
      return updatedJardinSizes;
    });
  };

  const handleOversizeCheckboxChange = (index) => {
    setOversizeSizes((prevSizes) => {
      const updatedOversizeSizes = prevSizes.map((size, i) =>
        i === index ? !size : false
      );
      const newSizeIndex = updatedOversizeSizes.findIndex((size) => size);
      const newSize =
        newSizeIndex >= 0
          ? ["XS", "S", "M", "L", "XL", "XXL"][newSizeIndex]
          : "";
      setNewSize(newSize);
      // Si se selecciona un nuevo talle en oversize,
      // deseleccionar los talles de primaria y secundaria
      setJardinSizes(Array(11).fill(false));
      setSecundariaSizes(Array(12).fill(false));
      return updatedOversizeSizes;
    });
  };

  const handleSecundariaCheckboxChange = (index) => {
    setSecundariaSizes((prevSizes) => {
      const updatedSecundariaSizes = prevSizes.map((size, i) =>
        i === index ? !size : false
      );

      let newSize = "";
      if (index === 11) {
        newSize = "12"; // Si el índice es 11, el tamaño es "12"
      } else {
        // Obtener el nuevo tamaño indexado según la selección
        const newSizeIndex = updatedSecundariaSizes.findIndex((size) => size);
        newSize = newSizeIndex >= 0 ? `${newSizeIndex}` : "";
      }

      // Actualizar el estado del nuevo tamaño
      setNewSize(newSize);

      // Si se selecciona un nuevo tamaño en secundaria,
      // deseleccionar los tamaños de primaria y oversize
      setJardinSizes(Array(11).fill(false));
      setOversizeSizes(Array(6).fill(false));

      return updatedSecundariaSizes;
    });
  };

  const handleVerGuiaTallesJArdin = () => {
    console.log("Ver guía de talles");
  };
  const handleVerGuiaTallesOversize = () => {
    console.log("Ver guía de talles");
  };
  const handleVerGuiaTallesSecundaria = () => {
    console.log("Ver guía de talles");
  };
  // modal
  const handleEnviarClick = (e) => {
    e.preventDefault();
    setShowModal(true);
    if (editMode === false) {
      if (newSize === currentSize) {
        console.log("El tamaño es el mismo que ya estaba");
        return;
      } else {
        setNewSize(currentSize);
      }
    }
  };

  const handleCancelarEnviar = () => {
    setShowModal(false);
    setEditingSizeId(null);
  };

  return (
    <>
      <div className={prendasStyle.modalBackground}>
        <div className={prendasStyle.modalC}>
          <div className={`container mt-5 ${Estilos.containerprincipal}`}>
            <div className="d-flex">
              <button onClick={onClose} className={Estilos.cruzcerrar}>
                <img className={Estilos.imagcruz} src={x} alt="" />
              </button>
            </div>
            <div>
              <h2 className={`mb-4 ${Estilos.titulo}`}>Editar Talles</h2>
              <p className={Estilos.subtitulo}>
                Puedes editar la siguiente información:
              </p>
            </div>
            <form className={Estilos.containerform}>
            <div className={` ${Estilos.containerinputs}`}>
                  <label htmlFor="alumno" className={`mr-2 ${Estilos.labels}`}>
                    Alumno
                  </label>
                  <input
                    name="name"
                    type="text"
                    className={`form-control ${Estilos.inputs}`}
                    id="alumno"
                    value={sizeName}
                    onChange={handleAlumnoChange}
                  />
                </div>

              <div className={` ${Estilos.containerinputs}`}>
                <label htmlFor="apodo" className={`mr-2 ${Estilos.labels}`}>
                  Apodo
                </label>
                <input
                  name="nickname"
                  type="text"
                  className={`form-control ${Estilos.inputsapodo}`}
                  id="apodo"
                  value={apodo}
                  onChange={handleApodoChange}
                />
              </div>
              <div className="d-flex">
                <p className={Estilos.mensajeemoji}>
                  El emoji solo puede ir ADELANTE Y/O ATRAS del apodo, NO EN
                  MEDIO.
                </p>
                <div>
                  <img src={danger} className={Estilos.danger} alt="" />
                </div>
              </div>
              <div>
                <h5 className={Estilos.tips}>Tips a tener en cuenta:</h5>
                <div className="d-flex">
                  <div>
                    <img
                      src={x}
                      alt="Icono de información"
                      className={Estilos.x}
                    />
                  </div>
                  <p className={`mb-4 ${Estilos.apodos}`}>
                    Si no quieres que lleve apodo, deja este campo vacío
                  </p>
                  <div>
                    <img
                      src={x}
                      alt="Icono de información"
                      className={Estilos.xx}
                    />
                  </div>
                </div>
                <div className="d-flex">
                  <div>
                    <img
                      src={fantasmita2}
                      alt="Icono de información"
                      className={Estilos.fantasma}
                    />
                  </div>
                  <p className={`mb-5 text-center ${Estilos.apododos}`}>
                    Los emojis pueden llegar a tener un costo extra,
                    <br /> consultalo con tu asesor/a{" "}
                  </p>
                  <div>
                    <img
                      src={arcoiris2}
                      alt="Icono de información"
                      className={Estilos.arcoiriss}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex mb-4">
                <h3 className={Estilos.catalogo}>Catalogo de emojis</h3>
                <button
                  type="button"
                  className={`btn  ${Estilos.emojiboton}`}
                  onClick={toggleEmojiMenu}
                >
                  {emojiOpen ? (
                    <i className="bi bi-caret-down-fill"></i>
                  ) : (
                    <i className="bi bi-caret-up-fill"></i>
                  )}
                </button>
              </div>
              <div>
                {emojiOpen && (
                  <div>
                    <img
                      src={catalogoEmojis}
                      alt="Icono de información"
                      className={Estilos.emojis}
                    />
                  </div>
                )}
              </div>
              <div className={` ${Estilos.containerinputs}`}>
                <label
                  htmlFor="personalizado"
                  className={`mr-2 ${Estilos.labels}`}
                >
                  Personalizado
                </label>
                <input
                  name="comments"
                  type="text"
                  className={`form-control ${Estilos.inputs}`}
                  id="personalizado"
                  value={personalizado}
                  onChange={handlePersonalizadoChange}
                />
              </div>
              <div>
                <p className={`form-text text-muted ${Estilos.parrafo}`}>
                  En caso que tu prenda lleve algo mas personalizado aclaralo en
                  este campo.
                  <br />
                  Ej modalidad, opcionales, numeros personalizados.
                </p>
              </div>

              <div>
                <h3 className={Estilos.subtitulo}> TALLES</h3>
              </div>

              {!editMode && (
                <div>
                  <p className={Estilos.talleelegido}>
                    Talle elegido: {currentSize}
                    <button
                      className={Estilos.botoneditar}
                      onClick={() => setEditMode(true)}
                    >
                      Editar
                    </button>
                  </p>
                </div>
              )}
              {editMode && (
                <>
                  <div className="d-flex mb-4">
                    <p className={Estilos.titulotalle}>Jardin/Primaria</p>
                    <button
                      type="button"
                      className={`btn  ${Estilos.tallesboton}`}
                      onClick={togglePrimaria}
                    >
                      {jardinVisible ? (
                        <i className="bi bi-caret-down-fill"></i>
                      ) : (
                        <i className="bi bi-caret-up-fill"></i>
                      )}
                    </button>
                  </div>
                  {jardinVisible && (
                    <div>
                      <div className={`d-flex ${Estilos.checkboxContainer}`}>
                        {jardinSizes.map((checked, index) => (
                          <label
                            key={index}
                            className={`btn ${Estilos.checkboxLabel} ${
                              checked ? Estilos.checkboxChecked : ""
                            }`}
                          >
                            <input
                              name="size"
                              type="checkbox"
                              className={`btn-check ${Estilos.numero}`}
                              checked={checked}
                              onChange={() => handleCheckboxChange(index)}
                            />
                            <span className={Estilos.numero}>
                              p{index * 2 + 4}
                            </span>
                          </label>
                        ))}
                      </div>
                      <button
                        type="button"
                        className={`btn btn-primary ${Estilos.botonguiadetalles}`}
                        onClick={handleVerGuiaTallesJArdin}
                      >
                        Ver guía de talles
                      </button>
                    </div>
                  )}

                  <div className="d-flex mb-4">
                    <p className={Estilos.titulotalle}>Oversize</p>
                    <button
                      type="button"
                      className={`btn  ${Estilos.tallesboton}`}
                      onClick={toggleOversize}
                    >
                      {oversize ? (
                        <i className="bi bi-caret-down-fill"></i>
                      ) : (
                        <i className="bi bi-caret-up-fill"></i>
                      )}
                    </button>
                  </div>
                  <div>
                    {oversize && (
                      <div>
                        <div className={`d-flex ${Estilos.checkboxContainer}`}>
                          {["XS", "S", "M", "L", "XL", "XXL"].map(
                            (size, index) => (
                              <label
                                key={index}
                                className={`btn ${Estilos.checkboxLabel} ${
                                  oversizeSizes[index]
                                    ? Estilos.checkboxChecked
                                    : ""
                                }`}
                              >
                                <input
                                  name="size"
                                  type="checkbox"
                                  className={`btn-check ${Estilos.numero}`}
                                  checked={oversizeSizes[index]}
                                  onChange={() =>
                                    handleOversizeCheckboxChange(index)
                                  }
                                />
                                {size}
                              </label>
                            )
                          )}
                        </div>
                        <button
                          type="button"
                          className={`btn btn-primary ${Estilos.botonguiadetalles}`}
                          onClick={handleVerGuiaTallesOversize}
                        >
                          Ver guía de talles
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="d-flex mb-4">
                    <p className={Estilos.titulotalle}>Secundaria</p>
                    <button
                      type="button"
                      className={`btn  ${Estilos.tallesboton}`}
                      onClick={toggleSecundaria}
                    >
                      {secundariaVisible ? (
                        <i className="bi bi-caret-down-fill"></i>
                      ) : (
                        <i className="bi bi-caret-up-fill"></i>
                      )}
                    </button>
                  </div>
                  <div>
                    {secundariaVisible && (
                      <div>
                        <div className={`d-flex ${Estilos.checkboxContainer}`}>
                          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12].map(
                            (size, index) => (
                              <label
                                key={index}
                                className={`btn ${Estilos.checkboxLabel} ${
                                  secundariaSizes[index]
                                    ? Estilos.checkboxChecked
                                    : ""
                                }`}
                              >
                                <input
                                  name="size"
                                  type="checkbox"
                                  className="btn-check"
                                  checked={secundariaSizes[index]}
                                  onChange={() =>
                                    handleSecundariaCheckboxChange(index)
                                  }
                                />
                                {size}
                              </label>
                            )
                          )}
                        </div>
                        <button
                          type="button"
                          className={`btn btn-primary ${Estilos.botonguiadetalles}`}
                          onClick={handleVerGuiaTallesSecundaria}
                        >
                          Ver guía de talles
                        </button>
                      </div>
                    )}
                  </div>
                  <button
                    className={Estilos.botoncancelar}
                    onClick={() => {
                      setNewSize(currentSize);
                      setEditMode(false);
                    }}
                  >
                    CANCELAR EDICION
                  </button>
                </>
              )}
              <div className={Estilos.containerbotonenviar}>
                <button className={Estilos.botonatras} onClick={onClose}>
                  ATRAS
                </button>

                <button
                  className={`btn btn-primary ${Estilos.botonenviar}`}
                  onClick={handleEnviarClick}
                  // onClick={uploadSizes}
                >
                  ENVIAR
                </button>
              </div>
            </form>
            {showModal && (
              <div
                className="modal"
                tabIndex="-1"
                role="dialog"
                style={{
                  display: "block",
                  overflowX: "visible",
                  overflowY: "visible",
                }}
              >             
                <div className="modal-dialog" role="document">
                  <div className={`modal-content ${confirmar.containermodal}`}>
                  <div>
                      <button
                        onClick={handleCancelarEnviar}
                        className={Estilos.cruzcerrar2}
                      >
                        <img className={Estilos.imagcruz2} src={x} alt="" />
                      </button>
                    </div>
                    <div className="modal-header">
                      <h3 className={confirmar.titulo}>
                        Confirmas que los datos editados sean correctos?
                      </h3>
                    </div>
                    <div className="modal-body">
                      <p className={confirmar.titulosselect}>
                        Alumno:{" "}
                        <span className={confirmar.alumnoselect}>
                          {" "}
                          {/* {alumno} */}
                          {sizeName}
                        </span>
                      </p>
                      <p className={confirmar.titulosselect}>
                        Apodo:{" "}
                        <span  className={apodo ? confirmar.apodoselect : ""}>{apodo}</span>
                      </p>
                      <p className={confirmar.titulosselect}>
                        Personalizado:{" "}
                        <span className={personalizado ? confirmar.personalizadoselect : ""}>
                          {" "}
                          {personalizado}{" "}
                        </span>
                      </p>
                      <p className={confirmar.tittalle}>Talle</p>
                      <p className={confirmar.titulosselect}>
                        {" "}
                        {selectedSizeType}{" "}
                        <span className={confirmar.talleselect}>{newSize}</span>
                      </p>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className={`btn btn-secondary ${confirmar.botoncancelar}`}
                        onClick={handleCancelarEnviar}
                      >
                        Cancelar
                      </button>
                      <button
                        type="button"
                        className={`btn ${confirmar.botonconfirmar}`}
                        onClick={uploadSizes}
                      >
                        Confirmar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default EditarTalle;
