import infoStyles from "../../styles/estilos/infoMetodos.module.css";

export default function EfectivoInfo() {
  return (
    <div className={infoStyles.infoContainer}>
      <div className={infoStyles.title}>Efectivo</div>
      <div className={infoStyles.firstP}>
        <p className={infoStyles.number}>1</p>
        <p className={infoStyles.text2}>Si estas en Buenos Aires:</p>
        <p className={infoStyles.text2}>
          Colectora panamericana 1807,1607 san Isidro,Buenos Aires. Lunes a
          Viernes de 10:00 a 17:00 HS
        </p>
      </div>
      <div className={infoStyles.firstP}>
        <p className={infoStyles.number}>2</p>
        <p className={infoStyles.text2}>Si estas en Santiago:</p>
        <p className={infoStyles.text2}>
          Garibaldi 64. Lunes a Viernes de 9:00 a 13:00 HS y de 18:00 a 21:00 HS
        </p>
      </div>
    </div>
  );
}
