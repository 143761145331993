import pagoFacil from "../../images/pagoFacil.png"
import infoStyles from "../../styles/estilos/infoMetodos.module.css"
function PagoFacil(){

return(
  <div className={infoStyles.infoContainer}>
<div className={infoStyles.title}>PAGO FACIL</div>
<div className={infoStyles.firstP}>
  <p className={infoStyles.number}>1</p>
  <p className={infoStyles.text}>Pedile a tu delegado/a del curso o a tu <br/>
                     asesor  de Little la Credencial de Pago Fácil.</p>
</div>
<div>
  <img  className={infoStyles.image} src={pagoFacil} alt="comprobante"/>
</div>
<div className={infoStyles.firstP}>
  <p className={infoStyles.number}>2</p>
  <p className={infoStyles.text}>Con esa credencial te acercas a uno de los<br/>
locales de pago y le indicas el monto que <br/>
deseas abonar. <br/> 
IMPORTANTE: Saber cuanto deben abonar</p>
</div>
<div className={infoStyles.firstP}>
  <p className={infoStyles.number}>3</p>
  <p className={infoStyles.text}>
  Una vez que tengas el comprobante de <br/>
  pago, Vamos a necesitar que lo informes <br/>
  en Mi Cuenta Blue. 
  <b> Recorda que es IMPORTANTE que el <br/>
   comprobante se vea completo.</b>
  </p>
</div>
</div>
)

}
export default PagoFacil;