import { NavLink, Outlet, useLocation, Link } from 'react-router-dom';
import homeStyle from "../styles/Home.module.css";
import React, { useEffect, useState } from 'react'
import { useDataContext } from "../contexts/AuthContext";
import logoAzul from '../images/logoAzul.png'; 
import fantasmaopinion from "../images/fantasmaOpinion.png"
import fantasmitaAsesor from "../images/fantasmita-asesor.png"
import sesion from "../images/sesion.png"
import { getAgentInfo, getUserDebts } from '../services/api';
import { useNavigate } from 'react-router-dom';
import { logOut } from '../services/api';
function LayOut() {
  const navigate = useNavigate();
  const location = useLocation()
  const { contextData } = useDataContext();
  const [showAsesor, setShowAsesor] = useState(false);
  const [agentFirstName, setAgentFirstName] = useState("");
  const [agentSurname, setAgentSurname] = useState("");
  const [agentPhone, setAgentPhone] = useState("")
  const [agentEmail, setAgentEmail] = useState("")
  //manejo de la aparicion de cerrar sesion en la navbar cuando la pantalla <700px
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  //manejo del despliqgue de informacion asesor
  const toggleAsesor = () => {
    setShowAsesor(!showAsesor);
  };

  useEffect(() => {
    if(!contextData ||!contextData.customer|| !contextData.customer.lead_id){
      return;
    }
    getAgentInfo(contextData.customer.lead_id)
      .then(data => {
        setAgentFirstName(data.first_name);
        setAgentSurname(data.last_name);
        setAgentPhone(data.agentPhone);
        setAgentEmail(data.email);
      })
      .catch(error => console.log(error));
  }, [contextData,contextData.customer.lead_id]);

  
  const handleLogOut = () => {
    logOut().then(() => {
      console.log("Sesión cerrada");
       navigate('/')
    }).catch(error => {
      console.error("Error en la solicitud de cerrar sesión:", error.message);
      
    });
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const customer = contextData?.customer;
  const customerFirstName = customer?.first_name ? capitalizeFirstLetter(customer.first_name) : '';


  return (
    <div className={homeStyle.generalconteiner}>
      <section className={homeStyle.barraLogo}>
        <img src='/logo-blanco.png'
          alt="logo"
          className={homeStyle.logo} />
        <NavLink className={`${homeStyle.sesion} ${location.pathname === "/mcb/logOut" && homeStyle.active}`} to="/">
          <img src={sesion} alt="Cerrar Sesión" className={homeStyle.cerrarSesionImage} />
        </NavLink>
      </section>

      <section className={homeStyle.workingcontainer}>
         <div className={homeStyle.main}>
          <nav className={homeStyle.nav}>
            <NavLink className={`${homeStyle.link} ${location.pathname === "/mcb" && homeStyle.active}`} to="/mcb">HOME</NavLink>
            <NavLink className={`${homeStyle.link} ${location.pathname === "/mcb/prendas" && homeStyle.active}`} to="/mcb/prendas">PRENDAS</NavLink>
            <NavLink className={`${homeStyle.link} ${location.pathname === "/mcb/entregas" && homeStyle.active}`} to="/mcb/entregas">ENVIOS</NavLink>
            <NavLink className={`${homeStyle.link} ${location.pathname === "/mcb/faq" && homeStyle.active}`} to="/mcb/faq">FAQ</NavLink>
            {/* <NavLink className={`${homeStyle.link} ${location.pathname === "/mcb/rifas" && homeStyle.active}`} to="/mcb/rifas">RIFAS</NavLink> */}
            
          </nav>
          <section>
            <Outlet />
          </section>
         </div>
        <aside className={homeStyle.mainmenu}>
          <img src={logoAzul} className={homeStyle.blue} alt="logo azul"/>
          <div className={homeStyle.pedido}>
            <h2> <span className={homeStyle.spanPedido} >Nº pedido</span>  {contextData.customer.username}</h2>
          </div>
          <div>
            <h1 className={homeStyle.saludo}> <span className={homeStyle.saludoS}> Hola </span>   {customerFirstName}!</h1>
          </div>
          <div className={homeStyle.representante}>
            <div className={homeStyle.asesor}>
              <h4 className={homeStyle.asesorText}>MI ASESOR/A</h4>
              <img src={fantasmitaAsesor} className={homeStyle.fantasmitaAsesor} alt="Fantasmita-Asesor" />
            </div>
            
            <div className={homeStyle.datosAsesorContainer}>
              <p className={homeStyle.text}>LUN-VIE 9-13 /14-18 hs</p>
              <section><i className="bi bi-emoji-smile"></i> <b>{agentFirstName} {agentSurname}</b></section>
              <section><i className="bi bi-telephone-fill"></i> 39600101</section>
              <section><i className="bi bi-whatsapp"></i> <a
              className={homeStyle.a} href={`https://wa.me/${agentPhone}`}>{agentPhone}</a></section>
              <section><i className="bi bi-envelope-fill"></i> {agentEmail}</section>
              

            </div>
          </div>
          <div className={homeStyle.gris}>
            <img src={fantasmaopinion} alt="fantasmaopinion" className={homeStyle.fantasmaopinion} />
            <p className={homeStyle.opiniontitulo}>Nos interesa tu opinión</p>
            
              <p className={homeStyle.texto}>Completa nuestra <br></br>
              <a className={homeStyle.auno} href="https://forms.gle/8bTDRNQw1tT3VkSy5">Encuesta de calidad</a>
              </p>
            
          </div>
        </aside>
      </section>
    </div>
  )
};
export default LayOut;