import React from "react";
import tallesStyle from "../styles/estilos/Talles.module.css";
import guideStyle from "../styles/estilos/guide.module.css";
import fantasma from "../images/fantasmaOpinion.png";
import danger from "../images/Danger.png";
import imagen1 from "../images/guideImages/imagenbuzo1.png";
import imagen2 from "../images/guideImages/imagenbuzo2.png";
import imagen3 from "../images/guideImages/guia1Imagen3.png";
import imagen4 from "../images/guideImages/guia1Imagen4.png";
const ModalTutorial1 = ({ onClose, onNext }) => {
  return (
    <div className={tallesStyle.modalBackground}>
      <div className={tallesStyle.modalG1}>
        {/*<img className={guideStyle.danger} src={danger} alt="Danger" />*/}
        <div className={tallesStyle.modalContent}></div>
        <div className={guideStyle.cellContent}>
          <h2 className={guideStyle.title}>
            AYUDA PARA COMPLETAR TU GUÍA LITTLE
          </h2>
          <h6 className={guideStyle.subtitle}>
            TENÉ TU GUIA LISTA PARA COMPLETARLO EN CONJUNTO
          </h6>
          <div className={guideStyle.announce}>
            <p>
              IMPORTANTE: SI AGREGAN cosas que no estan en el presupuesto que
              les pasaron anteriormente, su precio puede AUMENTAR.
            </p>
            <img
              className={guideStyle.fantasma}
              src={fantasma}
              alt="fantasma"
            />
          </div>
          <div className={guideStyle.number}>
            <h2 className={guideStyle.num}>1</h2>
            <p>COMO ES TU MODELO?</p>
          </div>

          <div className={guideStyle.container}>
            <div className={guideStyle.modelImages}>
              <div className={guideStyle.category}>
                <p>Buzo</p>
                <img src={imagen1} alt="remera" />
              </div>

              <div className={guideStyle.category}>
                <p>Campera</p>
                <img src={imagen2} alt="remera" />
              </div>
            </div>
            <h6>QUE MOLDERIA ES?</h6>
            <div className={guideStyle.modelImages}>
              <div className={guideStyle.category}>
                <p>Normal</p>
                <img src={imagen3} alt="remera" />
              </div>
              <div className={guideStyle.category}>
                <p>Oversize</p>
                <img src={imagen4} alt="remera" />
              </div>
            </div>
          </div>
          <div className={guideStyle.buttonContainer}>
            <button className={guideStyle.one} onClick={onClose}>
              Cerrar
            </button>
            <button className={guideStyle.two} onClick={onNext}>
              Siguiente
            </button>
          </div>
        </div>
        {/**Escritorio */}
        <div className={guideStyle.deskContent}>
          {/*headers */}
          <h2 className={guideStyle.title}>
            AYUDA PARA COMPLETAR TU GUÍA LITTLE
          </h2>
          <h6 className={guideStyle.subtitle}>
            TENÉ TU GUIA LISTA PARA COMPLETARLO EN CONJUNTO
          </h6>
          <div className={guideStyle.announce1}>
            <p>
              IMPORTANTE: SI AGREGAN cosas que no estan en el presupuesto que
              les pasaron anteriormente, su precio puede AUMENTAR.
            </p>
            <img
              className={guideStyle.fantasma}
              src={fantasma}
              alt="fantasma"
            />
          </div>
          <div className={guideStyle.number}>
            <h2 className={guideStyle.num}>1</h2>
            <p>COMO ES TU MODELO?</p>
          </div>
          {/**contenido flex */}
          <div className={guideStyle.deskContainer}>
            <div className={guideStyle.modelImages}>
              <div className={guideStyle.category}>
                <p>Buzo</p>
                <img src={imagen1} alt="remera" />
              </div>

              <div className={guideStyle.category}>
                <p>Campera</p>
                <img src={imagen2} alt="remera" />
              </div>
            </div>

            <div className={guideStyle.modelImages1}>
              <h6>QUE MOLDERIA ES?</h6>
              <section className={guideStyle.imageSection}>
                <div className={guideStyle.category}>
                  <p>Normal</p>
                  <img src={imagen3} alt="remera" />
                </div>
                <div className={guideStyle.category}>
                  <p>Oversize</p>
                  <img src={imagen4} alt="remera" />
                </div>
              </section>
            </div>
          </div>
          <div className={guideStyle.buttonContainer}>
            <button className={guideStyle.one} onClick={onClose}>
              Cerrar
            </button>
            <button className={guideStyle.two} onClick={onNext}>
              Siguiente
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ModalTutorial1;

