
import React, { useState } from 'react';
import prendasStyle from "../styles/Prendas.module.css"
import tallesStyle from "../styles/estilos/Talles.module.css";
import Danger from "../images/Danger.png";
import GuiaPrimariaModal from './GuiaPrimariaModal';
import GuiaSecundariaModal from "./GuiaSecundariaModal";
import GuiaOversizeModal from "./GuiaOversizeModal";
import videoBuzo from "../videos/videoBuzos.mp4"
import videoRemeras from "../videos/video2.mp4"

const MediTuLittleModal = (props) => {
  
  
  console.log("Prop prendaSeleccionada en el componente hijo:", props.prendaSeleccionada);
  
  const handlecloseModal = () => {
    props.onClose();  
  };
  const[showOversizeModal,setShowoversizeModal]=useState(false);
  const toggleOversizeModal=()=>setShowoversizeModal(!showOversizeModal);

  const[showSecundariaModal,setShowSecundariaModal]=useState(false);
  const toggleSecundariaModal=()=>setShowSecundariaModal(prevState=>!prevState);

  const [showPrimariaModal, setShowPrimariaModal] = useState(false);
  const togglePrimariaModal = () => setShowPrimariaModal(prevState => !prevState);
  return (
  <div className={prendasStyle.modalBackground}>
    <div className={prendasStyle.modalT}>
    <img className={prendasStyle.danger} src={Danger} alt="Danger" />
      <div className={tallesStyle.modalContent}>
        
        <h2 className={tallesStyle.title}>COMO MEDIR TU LITTLE</h2>
        <h6>Te mostramos  como tomarte las medidas para que<br/>
              TU LITTLE te quede perfecto.</h6>
              {(props.prendaSeleccionada.category_name === 'Buzos'|| props.prendaSeleccionada.category_name==='Camperas') && (
        <video className={tallesStyle.video} controls>
          <source src={videoBuzo} type="video/mp4" />
          Tu navegador no admite el elemento <code>video</code>.
        </video>
      )}

      {/* Condición para renderizar el video de la remera */}
      {(props.prendaSeleccionada.category_name === 'Remeras'|| props.prendaSeleccionada.category_name==="Chombas") && (
        <video className={tallesStyle.video} controls>
          <source className={tallesStyle.video} src={videoRemeras} type="video/mp4" />
          Tu navegador no admite el elemento <code>video</code>.
        </video>
      )}
      <div className={tallesStyle.nobuttonContainer}>
        <p className={tallesStyle.p} >Jardin/Primaria</p>
        <button className={tallesStyle.nobutton}onClick={togglePrimariaModal}  >VER GUIA DE TALLES</button>
      </div>   
     
       <div className={tallesStyle.nobuttonContainer}>
        <p className={tallesStyle.p} >Secundaria</p>
        <button className={tallesStyle.nobutton} onClick={toggleSecundariaModal}>VER GUIA DE TALLES</button>
       </div>  

      <div className={tallesStyle.nobuttonContainer}>
        <p className={tallesStyle.p} >Oversize</p>
        <button className={tallesStyle.nobutton} onClick={toggleOversizeModal}>VER GUIA DE TALLES</button>
      </div>  
         <button className={tallesStyle.close} onClick={handlecloseModal}>CERRAR</button>
      </div>
      {showPrimariaModal && <GuiaPrimariaModal onClose={togglePrimariaModal} />}
      {showSecundariaModal && <GuiaSecundariaModal onClose={toggleSecundariaModal} />}
      {showOversizeModal && <GuiaOversizeModal onClose={toggleOversizeModal}/>}
    </div>
  </div>
  );
};

export default MediTuLittleModal;