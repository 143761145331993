import React from "react";
import modalStyle from "../styles/estilos/modalMapStyle.module.css";

const ModalMap = ({ onClose, children, ubicacionMapa }) => {
  return (
    <div className={modalStyle.modalBackground} onClick={onClose}>
      <div
        className={modalStyle.modalContainer}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={modalStyle.modalCloseButton} onClick={onClose}>
          Cerrar
        </div>
        <div className={modalStyle.modalContent}>
          {/* Contenido del modal */}
          <h3>Ubicación en Google Maps:</h3>
          {/* Aquí puedes mostrar la ubicación del mapa */}
          <iframe
            title="Google Maps"
            className={modalStyle.modalMapIframe}
            src={ubicacionMapa}
            allowFullScreen
          ></iframe>
          {/* Puedes pasar otros elementos como children */}
          {children}
        </div>
      </div>
    </div>
  );
};

export default ModalMap;
