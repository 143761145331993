import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AccountsSummaryStyles from "../styles/AccountsSummary.module.css";
import TermsStyle from "../styles/estilos/TermsStyle.module.css";
import { useDataContext } from "../contexts/AuthContext";
import Swal from "sweetalert2";
import { verifyTermsAccepted } from "../services/api";
import { postTerms } from "../services/api";
import logoPng from "../images/Frame.png";
import modal1 from "../components/Modal1";

const AceptarTerminos = () => {
  const navigate = useNavigate();
  const [mayorAceptadas, setMayorAceptadas] = useState(false);
  const [botonHabilitado, setBotonHabilitado] = useState(true);
  const [accepted, setAccepted] = useState(null);
  const [loading, setLoading] = useState(true);
  const { contextData } = useDataContext();
  const [compraAceptada, setCompraAceptada] = useState(false);

  useEffect(() => {
    if (
      !contextData ||
      !contextData.customer ||
      !contextData.customer.lead_id
    ) {
      return;
    }
    verifyTermsAccepted(contextData.customer.lead_id)
      .then((data) => {
        if (data.accepted === true) {
          console.log(data.accepted);
          setAccepted(true);
        } else if (data.accepted === false) {
          setAccepted(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setAccepted(false);
        setLoading(false);
      });
  }, [contextData.customer.lead_id, setAccepted, setLoading]);

  const handleRedirect = () => {
    if (accepted === null) return;

    if (contextData.customer.customer_type === 3 && accepted === true) {
      navigate("/mcb");
    } else if (contextData.customer.customer_type !== 3 && accepted === true) {
      navigate("/mcb");
    } else if (contextData.customer.customer_type !== 3 && accepted === false) {
      navigate("/esperando");
    }
  };

  useEffect(() => {
    // if(!accepted || !contextData.customer.customer_type){
    //   return;
    // }
    handleRedirect();
  }, [accepted, contextData.customer.customer_type]);

  const handleCheckboxChange = () => {
    setMayorAceptadas(true);
  };

  const handleAceptarClick = () => {
    console.log("Botón de aceptar clicado");

    if (mayorAceptadas) {
      const fechaActual = new Date(Date.now());
      const año = fechaActual.getFullYear();
      const mes = (fechaActual.getMonth() + 1).toString().padStart(2, "0");
      const dia = fechaActual.getDate().toString().padStart(2, "0");
      const horas = fechaActual.getHours().toString().padStart(2, "0");
      const minutos = fechaActual.getMinutes().toString().padStart(2, "0");
      const segundos = fechaActual.getSeconds().toString().padStart(2, "0");

      const fechaFormateada = `${año}-${mes}-${dia} ${horas}:${minutos}:${segundos}`;

      const datos = {
        terms_accepted_at: fechaFormateada,
        terms_accepted_by: contextData.customer.id,
      };

      postTerms(datos)
        .then((data) => {
          console.log(`data recibida: ${JSON.stringify(datos)}`);
          setMayorAceptadas(true);
          setBotonHabilitado(false);

        //   Swal.fire({
        //     icon: "question",
        //     html: `<h3 class=${TermsStyle.modalTitle}>Deseas <span style="color: #d54ab7">comprar</span> alguna prenda?</h3>`,
        //     showDenyButton: true,
        //     confirmButtonText: "Si",
        //     confirmButtonColor: "#D54AB7",
        //     denyButtonText: `No gracias!`,
        //     denyButtonColor: "#0072CE",
        //   }).then((result) => {
        //     if (result.isConfirmed) {
        //       localStorage.setItem("compraAceptada", "true");
        //       // setCompraAceptada(true);
        //       navigate("/mcb");
        //     } else if (result.isDenied) {
        //       localStorage.setItem("compraAceptada", "false");
        //       // setCompraAceptada(false);
        //       navigate("/mcb/accountsSummary");
        //     }
        //   });
        // })
        // .catch((error) => {
        //   console.error(error);
        // });

        Swal.fire({
          icon: "question",
          html: `<h3 class=${TermsStyle.modalTitle}>Deseas <span style="color: #d54ab7">comprar</span> alguna prenda?</h3>`,
          showDenyButton: true,
          confirmButtonText: "Si",
          confirmButtonColor: "#D54AB7",
          denyButtonText: `No gracias!`,
          denyButtonColor: "#0072CE",
        }).then((result) => {
          const userKey = `hide-cloth-selection-modal-${contextData.customer.id}`;
          if (result.isConfirmed) {
            localStorage.setItem(userKey, "true");
            navigate("/mcb");
          } else if (result.isDenied) {
            localStorage.setItem(userKey, "false");
            navigate("/mcb/accountsSummary");
          }
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }
};

return (
    <>
      {loading ? ( // Mostrar indicador de carga mientras se verifica
        <div className={TermsStyle.contcargando}>
          <img
            src={logoPng}
            alt="littleBlueLogo"
            className={TermsStyle.imgcargando}
          />
          <h2 className="text-muted">CARGANDO...</h2>
          <div className={TermsStyle.loader}></div>
        </div>
      ) : (
        <div>
          {accepted !== true && (
            <>
              <div className={TermsStyle.terminos}>
                <h1 className={TermsStyle.title}>
                  Términos y Condiciones de littleblue.com.ar
                </h1>
                <p>
                  Esta sección detalla los términos y condiciones generales
                  sobre el acceso y uso de los servicios ofrecidos por
                  littleblue.com.ar en este sitio.
                </p>
                <p>
                  Al ingresar como usuario a www.littleblue.com.ar significa que
                  se conocen y aceptan éstas condiciones de acceso y uso. Si el
                  usuario no está de acuerdo con las condiciones de acceso o de
                  uso, o con cualquier cambio o modificación de las mismas, no
                  deberá continuar utilizando el sitio ni los servicios
                  ofrecidos en el mismo.
                </p>
                <p>
                  Si un usuario utiliza este sitio, significa que ha aceptado
                  plenamente los Términos y Condiciones de littleblue.com.ar,
                  obligándose a cumplir expresamente con los mismos.
                </p>

                <hr />

                <h3>
                  01. Usuarios que pueden utilizar el servicio de
                  littleblue.com.ar{" "}
                </h3>
                <p>
                  Los servicios pueden ser utilizados por tres tipos de
                  usuarios:
                </p>
                <p>
                  {" "}
                  A- Usuario tutor: sólo personas que tengan capacidad legal
                  para contratar.
                </p>
                <p> B- Usuario delegado: tiene capacidad de edición.</p>
                <p> C-Otros usuarios: Sólo pueden ver pero no editar.</p>
                <p>
                  La validez de los términos y condiciones queda sujeta al
                  registro del USUARIO TUTOR. Quien sea registrado como usuario
                  tutor debe ser una persona jurídica, deberá tener capacidad
                  para contratar a nombre de tal entidad y de obligar a la misma
                  en los términos de estas Condiciones de Uso.
                </p>

                <hr />

                <h3>02. Registración como usuario de littleblue.com.ar</h3>
                <p>
                  La registración en littleblue.com.ar es gratuita. El usuario
                  al realizar el proceso de registración, se compromete a
                  completar los datos solicitados con datos válidos, exactos y
                  precisos, asumiendo el compromiso de actualizar los datos
                  personales según corresponda. Los Usuarios garantizan y
                  responden, en cualquier caso, de la veracidad, exactitud,
                  vigencia y autenticidad de los datos personales ingresados. Al
                  registrarse, el usuario dispondrá de un nombre y contraseña de
                  acceso segura y confidencial, pudiendo cambiar la misma, según
                  el procedimiento establecido para tal fin. Littleblue.com.ar
                  se reserva el derecho de rechazar cualquier solicitud de
                  registración o de cancelar una registración previamente
                  aceptada, sin que esté obligado a comunicar o exponer las
                  razones de su decisión y sin que ello genere algún derecho a
                  indemnización o resarcimiento.
                </p>

                <hr />

                <h3>03. Utilización de la contraseña de usuario</h3>
                <p>
                  El Usuario se compromete a no divulgar su contraseña (clave de
                  acceso) a terceros. Ésta clave permite efectuar compras,
                  solicitar servicios y obtener información. Dicha clave es de
                  uso personal y su entrega a terceros, no involucra
                  responsabilidad de littleblue.com.ar en caso de mala
                  utilización.
                </p>
                <p>
                  El usuario se compromete a notificar a littleblue.com.ar en
                  forma inmediata y por medio idóneo y fehaciente, cualquier uso
                  no autorizado de su Cuenta.
                </p>
                <hr />

                <h3>04. Confidencialidad de datos</h3>
                <p>
                  Littleblue.com.ar cumplimentó los requisitos que exige la Ley
                  No 25.326 de Protección de Datos Personales difundida por la
                  Dirección Nacional de Protección de Datos Personales. En
                  cumplimiento con la Disposición N° 10, publicada en el Boletín
                  Oficial el 18-09-08, se informa que: (i) Los titulares de
                  datos personales tienen la facultad de ejercer el derecho de
                  acceso a los mismos en forma gratuita a intervalos no
                  inferiores a seis meses, salvo que se acredite un interés
                  legítimo al efecto (en cuyo caso, la limitación temporal
                  referida anteriormente no será aplicable). (ii) La Dirección
                  Nacional de Datos de Protección de Datos Personales tiene la
                  atribución de atender las denuncias y reclamos que se
                  interpongan con relación al incumplimiento de las normas sobre
                  protección de datos personales.
                </p>
                <p>
                  Los datos personales que el usuario brinde a
                  littleblue.com.ar, tales como nombres, fecha de nacimiento,
                  domicilio, número telefónico, país, código postal, correo
                  electrónico y/o cualquier otro que el usuario voluntariamente
                  suministre, son incluidos en archivos automatizados,
                  procesados bajo normas de estricta confidencialidad y
                  protección de datos.
                </p>
                <p>
                  La información es utilizada por littleblue.com.ar con el único
                  objeto de mejorar la oferta de servicios a sus usuarios y
                  podrá ser utilizada para acciones promocionales de marketing.
                </p>
                <p>
                  La información personal obtenida del usuario no será revelada
                  a terceras personas sin su consentimiento previo, salvo
                  requerimiento de autoridad competente.
                </p>
                <p>
                  El usuario podrá, en cualquier momento, ejercer su derecho a
                  acceder, rectificar, actualizar, cancelar y/o suprimir sus
                  datos personales de conformidad a lo previsto en la Ley
                  25.326, por medio de nota o presentación escrita con firma
                  certificada, medio postal o telegráfico y/o carta documento
                  acreditando fehacientemente su identidad.
                </p>
                <p>
                  Los servicios de emisión de información a nuestros usuarios
                  vía correo electrónico, correo postal y mensaje de texto
                  (SMS), cuentan con la autorización del usuario, y en el caso
                  de que el usuario no deseara recibir este tipo de información
                  podrá solicitarlo a littleblue.com.ar por medio fehaciente, o
                  telefónicamente al Centros de Atención al Cliente al (011)
                  3960-0101 o por email info@littleblue.com.ar. La utilización
                  de cualquiera de los servicios de littleblue.com.ar será
                  considerado como aceptación de los términos de esta Política
                  de Privacidad por parte del usuario.
                </p>

                <hr />

                <h3>
                  05. Compra de productos o servicios en littleblue.com.ar
                </h3>
                <p>
                  Una vez de aceptada la compra por el usuario, le llegará un
                  email a su casilla de correo con el número de operación y los
                  detalles de la misma. También podrá ver el detalle de su
                  pedido en MI CUENTA BLUE (MCB) la cual será habilitada una vez
                  confirmada la misma.
                </p>
                <p>
                  En caso de que el usuario al efectuar la compra realice un
                  pago a cuenta de precio y con posterioridad decida cancelar
                  dicha compra, perderá la suma abonada en dicho concepto
                  entendiéndose tal operación como confirmatoria del precio sin
                  perjuicio de las penalidades establecidas por mora en los
                  pagos, en la entrega y/o por falta de compromiso que pudieren
                  corresponder y aplicarse en cada caso.
                </p>
                <p>
                  Las compras no podrán ser canceladas una vez iniciado el
                  proceso de producción lo que sucede una vez abonado el 50%
                  inicial del pedido. En tal caso será requisito indispensable
                  para la entrega de la mercadería el pago total del precio.
                </p>
                <p>
                  De igual modo puede ocurrir excepcionalmente, que luego de la
                  aceptación de la compra, littleblue.com.ar cancele la misma
                  por alguno de los siguientes motivos: a) disponibilidad de
                  stock; b) falta de autorización de la compra por parte de la
                  entidad emisora o administradora de su tarjeta de crédito o;
                  c) problemas identificados por el departamento de prevención
                  de fraude.
                </p>
                <p>
                  littleblue.com.ar puede necesitar información adicional o
                  realizar verificaciones antes de aceptar cualquier compra. Si
                  se cancela su compra o si se necesita información adicional,
                  nos pondremos en contacto con el usuario.
                </p>
                <p>
                  Los comprobantes que se emiten para las compras de productos
                  es Factura Electrónica TIPO “A” o “B” según el caso. Dichos
                  comprobantes son enviados en oportunidad de entrega de los
                  productos a la dirección de correo electrónico proporcionada
                  por el cliente.
                </p>

                <hr />

                <h3>06. Medios de pagos en littleblue.com.ar</h3>
                <p>
                  Los medios de pago habilitados para realizar compras en
                  littleblue.com.ar son: Pago fácil, depósito y/o transferencia
                  bancaria en la cuenta que se indique en la sección de pagos de
                  MCB, y con tarjetas de débito y/o crédito mediante las
                  plataformas de GOCUOTAS y/o GET NET respectivamente.
                </p>
                <p>
                  Para más información acerca de GoCuotas puede ingresar a
                  www.gocuotas.com/
                </p>
                <p>
                  Para más información acerca de GET NET puede ingresar a
                  https://www.getnet.com.ar/
                </p>
                <p>
                  En cualquier caso, se solicita al usuario cargar el pago junto
                  con el comprobante en MCB o bien el envío del comprobante de
                  pago y/o transferencia por correo electrónico a la casilla de
                  “cobranzas@littleblue.com.ar” indicando en todos los casos el
                  nombre y apellido de la persona que abona y el Nº de pedido
                  para su correcta imputación en el sistema.
                </p>
                <p>
                  De igual forma se deja expresa constancia que en caso de
                  constatarse pagos fuera de término de acuerdo al calendario de
                  pagos que surge de la plataforma MI CUENTA BLUE la empresa se
                  reserva el derecho de aplicar las penalidades dispuestas en la
                  cláusula 16.
                </p>

                <hr />

                <h3>07. Servicio de Entrega de productos</h3>
                <p>
                  El costo del servicio de entrega esta bonificado, siempre y
                  cuando cumplan en tiempo y forma con los requisitos
                  establecidos en MI CUENTA BLUE.
                </p>
                <p>
                  El tiempo de finalización de producción se encuentra detallado
                  en MI CUENTA BLUE y puede ser de 30 (producción “EXPRESS”),
                  35, 40 o 45 días hábiles, dependiendo el/los tipo/s de
                  producto/s elegido/s por EL COMPRADOR. El tiempo de envío
                  dependerá de la provincia y del proveedor de correo utilizado,
                  quien recibirá la/s prenda/s a ser enviadas en un plazo no
                  mayor a los 5 (cinco) días hábiles de finalizada la producción
                  y su posterior control de calidad. Una vez entregado el
                  paquete en el proveedor de correo los tiempos de entrega
                  dependerán de este último.
                </p>
                <p>
                  Por logística interna, para que el usuario disfrute de los
                  productos más rápido, la compra puede ser enviada particionada
                  en más de una entrega, esto se le informará previamente vía
                  email. Se reitera que la compra no se enviará al domicilio si
                  no se encuentra cancelado el precio total de la compra.
                </p>

                <hr />

                <h3>08. Servicio de Envío a domicilio</h3>
                <p>
                  La Empresa se reserva el derecho de definir el medio de
                  entrega de los productos, acorde la disponibilidad del
                  servicio.
                </p>
                <p>
                  En el caso de que la entrega se realice con logística propia,
                  el servicio de envío a domicilio tendrá el siguiente alcance:
                </p>

                <p>
                  <strong>
                    La Empresa se compromete a hacer la entrega en domicilio:
                  </strong>
                </p>
                <ul>
                  <li>Con embalaje adecuado y en condiciones.</li>
                  <li>En el primer ambiente techado.</li>
                  <li>
                    Hasta el 1er piso por escalera, en caso de departamento, si
                    la estructura edilicia lo permite. · Sin ticket/factura, al
                    titular o persona que éste autorice, acreditando identidad.
                  </li>
                </ul>

                <p>
                  <strong>Está prohibido al personal de reparto:</strong>
                </p>
                <ul>
                  <li>Ingresar productos por ventanas y balcones.</li>
                  <li>Desembalar, armar o instalar.</li>
                  <li>Entregar a un menor de edad.</li>
                  <li>
                    Retirar productos, no respaldados por una operación de
                    cambio.
                  </li>
                </ul>

                <p>
                  <strong>
                    Estas condiciones podrán ser modificadas por la Empresa.
                  </strong>
                </p>
                <p>
                  <strong>
                    En el caso de que la entrega se realice con logística de
                    Correo Argentino, domicilio tendrá el siguiente alcance:
                  </strong>
                </p>
                <ul>
                  <li>Entrega en el domicilio consignado en factura.</li>
                  <li>Con embalaje adecuado y en condiciones</li>
                  <li>La entrega se realiza en el 1er acceso del domicilio</li>
                  <li>
                    De no encontrarse nadie en el domicilio, se enviara el
                    pedido al Correo Argentino mas cercano por el plazo de 5
                    días hábiles, transcurrido dicho plazo, el pedido volverá a
                    destino y nos pondremos en contacto para coordinar un nuevo
                    envío. Este nuevo envío deberá ser abonado previamente por
                    el Usuario.
                  </li>
                </ul>
                <p>
                  El envío puede demorar para Ciudad Autónoma de Buenos Aires y
                  el Gran Buenos Aires 48 hs. Y para el interior del país hasta
                  96 hs. Desde que el pedido figura LISTO en MI CUENTA BLUE
                </p>

                <hr />

                <h3>09. Precios de productos y promociones</h3>
                <p>
                  Los precios y promociones que se ofrecen en littleblue.com.ar,
                  son exclusivos para el sitio, y no son aplicables a otros
                  canales de ventas como ser: nuestras oficinas, ventas
                  telefónicas, catálogos.
                </p>
                <p>
                  La validez de las promociones, depende de la fecha de vigencia
                  de las mismas o de la disponibilidad del stock informado.
                </p>
                <p>
                  Las promociones no son acumulables entre sí, salvo que se
                  exprese lo contrario en el sitio.
                </p>

                <hr />

                <h3>10. Imágenes y descripciones de producto</h3>
                <p>
                  Las fotos de los productos son ilustrativas y no
                  contractuales.
                </p>

                <hr />

                <h3>11. Reclamos de la compra</h3>
                <p>
                  Todas las prendas poseen garantía de fabricación por el plazo
                  de seis (6) meses, a excepción de aquellas en las que
                  expresamente se estipulen plazos mayores.
                </p>
                <p>
                  Si el usuario reclamara la readecuación de las prendas que
                  presentaran diferencias a lo solicitado o desperfectos de
                  fabricación, deberá informarlo a littleblue.com.ar, en un
                  plazo no mayor a los diez (10) días hábiles de haber recibido
                  la/s prenda/s, y presentar la/s prenda/s en el domicilio de
                  este último para su adecuación o reparación. Los costos de
                  envío serán asumidos por littleblue.com.ar. El Vendedor deberá
                  restituir la prenda en condiciones solicitadas dentro del
                  plazo de 20 (veinte) días hábiles contados desde la recepción
                  del producto en la empresa ello para efectuar el control de
                  calidad pertinente.
                </p>

                <hr />

                <h3>12. Comprar en Little Blue es SEGURO</h3>
                <p>
                  Los datos ingresados de la tarjeta no son almacenados en
                  nuestra base de datos.
                </p>

                <hr />

                <h3>13. Cambios y arreglos de productos</h3>
                <p>
                  Todo cambio y/o arreglo, deberá ser autorizado, para ello el
                  Usuario deberá contactarse con littleblue.com.ar antes del
                  plazo mencionado anteriormente y presentar su caso.
                </p>
                <p>
                  Para cambios y arreglos autorizados, el producto debe estar en
                  perfecto estado de uso, completo, con embalaje original,
                  factura de compra o número de pedido. Una vez recibido el
                  producto en littleblue.com.ar se verificará el mismo y si
                  cumple con los requisitos se procederá al cambio y/o arreglo,
                  según corresponda.
                </p>
                <p>
                  Para tramitar la devolución o cambio del producto, es
                  necesario contactarse al Servicio de Atención al cliente,
                  donde un asesor le informará los pasos a seguir. El usuario
                  puede enviar un email a info@littleblue.com.ar o llamar al
                  (011) 3960-0101 de lunes a viernes de 13 a 18hs.
                </p>
                <p>
                  Los cambios tienen una demora de veinte (20) días hábiles y
                  los arreglos una demora de quince (15) días hábiles,
                  dependiendo del arreglo.
                </p>
                <hr />

                <h3>14. Inconvenientes de Sistemas</h3>
                <p>
                  littleblue.com.ar no puede garantizar el acceso y uso
                  continuado o ininterrumpido de su sitio. El sistema puede
                  eventualmente no estar disponible, debido a dificultades
                  técnicas o fallas de Internet, o por cualquier otra
                  circunstancia ajena a la empresa; en tales casos se procurará
                  restablecerlo con la mayor celeridad posible.
                </p>

                <hr />

                <h3>15. Incumplimiento de El Vendedor</h3>
                <p>
                  En caso de incumplimiento por parte de El Vendedor del plazo
                  de entrega establecido en la cláusula quinta del presente
                  contrato, se descontará a favor de El Solicitante el uno coma
                  cinco por ciento (1,5%) del precio final de compra por cada
                  semana de mora. Transcurrido treinta y cinco (35) días hábiles
                  del vencimiento del plazo de entrega sin que EL VENDEDOR
                  hubiera hecho entrega de la indumentaria, EL SOLICITANTE podrá
                  -siempre y cuando no haya incurrido en incumplimientos de su
                  parte- solicitar la restitución de las sumas abonadas en
                  concepto de pago a cuenta, quedando concluido el contrato sin
                  más derechos y/o indemnizaciones que reclamar.
                </p>

                <hr />

                <h3>16. Incumplimiento de El Solicitante</h3>
                <p>
                  En el supuesto que El Solicitante incumpliese con alguna de
                  las obligaciones a su cargo, esto es la realización de pagos
                  fuera de término por más de diez días corridos, el cambio de
                  diseño y/o la falta de confección de guías para el diseño de
                  las prendas luego de transcurridos más de diez días corridos
                  se entenderán tales conductas como faltas de compromiso.
                </p>
                <p>
                  El calendario de pagos y vencimientos estará disponible para
                  El Solicitante en la plataforma de MI CUENTA BLUE.
                </p>
                <p>
                  En tal caso el vendedor podrá aplicar penalidades de hasta un
                  15% del precio total por cada uno de los incumplimientos en
                  los que haya incurrido El Solicitante y que serán informados
                  por cualquiera de los canales de atención utilizados.
                </p>
                <p>
                  Sin perjuicio de ello y en caso de reiterarse los
                  incumplimientos a cargo de” El Solicitante”, El Vendedor podrá
                  a su elección: Exigir el cumplimiento del contrato, con más
                  una multa del uno por ciento (1%) diario de mora o bien podrá
                  resolver el mismo en forma totalmente automática, quedándose
                  con todas las sumas de dinero recibidas hasta el momento de
                  producirse el incumplimiento en concepto de indemnización, sin
                  perjuicio de iniciar las acciones legales por daños y
                  perjuicios si el mismo fuera mayor.
                </p>

                <hr />

                <h3>17. Política de descuentos y promociones</h3>
                <p>
                  El incumplimiento de las cuotas en termino y/o el vencimiento
                  de un requisito (guia, talles, aprobación de boceto, pagos)
                  implica la PERDIDA de los beneficios y/o descuentos obtenidos
                  al momento de la compra.
                </p>
              </div>
              <div className={TermsStyle.form}>
                {/* Checkbox para aceptar los términos */}
                <label className={TermsStyle.label}>
                  <input
                    className={TermsStyle.check}
                    type="checkbox"
                    checked={mayorAceptadas}
                    onChange={handleCheckboxChange}
                  />
                  Soy mayor de 18 años, he leído y acepto los términos y
                  condiciones.
                </label>

                {/* Botón de aceptar */}
                <button
                  className={TermsStyle.boton}
                  onClick={handleAceptarClick}
                  disabled={!botonHabilitado}
                >
                  Aceptar
                </button>
              </div>
            </>
          )}
        </div>
      )}
      {/* <div>
      <modal1 compraAceptada={compraAceptada} />
    </div> */}
    </>
  );
};

export default AceptarTerminos;
