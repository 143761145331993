import React, { useState, useRef, useEffect } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ticket from "../../images/Capa_1.png";
import formulario from "../../styles/FormularioMP.module.css";
import { useDataContext } from "../../contexts/AuthContext";
import ticketmp from "../../images/ticketMercadoPago.jpeg";
import { useNavigate } from "react-router-dom";

export default function TransferenciaBancaria() {
  const navigate = useNavigate();
  const { contextData } = useDataContext();

  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [nombre, setNombre] = useState("");
  const [digitos, setDigitos] = useState("");
  const [amount, setAmount] = useState("");
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Lógica para enviar el formulario
  };

  const handleFileInputChange = (e) => {
    e.preventDefault();
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    console.log(selectedFile);
  };

  return (
    <div className="container-fluid d-flex align-items-center justify-content-center vh-100 ">
      <div style={{ maxWidth: "900px", width: "100%" }}>
        <form
          onSubmit={handleSubmit}
          className={`d-flex flex-wrap justify-content-between ${formulario.contenedorform}`}
        >
          <div className={`w-48 ${formulario.containermp}`}>
            <h2 className={`text-center ${formulario.titulomp}`}>
              Mercado pago
            </h2>

            <div className="form-group mb-3">
              <label className={formulario.label}>Fecha:</label>
              <DatePicker
                className={`form-control ${formulario.input}`}
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date)}
              />
            </div>

            <div className="form-group mb-3">
              <label className={formulario.label}>
                Ingresá el nombre de quien realiza la transferencia
              </label>
              <input
                className={`form-control ${formulario.input}`}
                type="text"
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
                attern="[A-Za-z\s]*"
                required
              />
              {nombre && !/^[A-Za-z\s]+$/.test(nombre) && (
                <div className="text-danger">Ingrese solo letras</div>
              )}
            </div>

            <div className="form-group mb-3">
              <label className={formulario.label}>
                Ingresá los últimos 6 dígitos del número de transacción
                <button
                  type="button"
                  className="btn btn-link"
                  data-bs-toggle="modal"
                  data-bs-target="#imageModal"
                >
                  <i className="bi bi-question-circle"></i>
                </button>
              </label>
              <input
                className={`form-control ${formulario.input}`}
                type="text"
                value={digitos}
                onChange={(e) => setDigitos(e.target.value)}
                pattern="[0-9]"
                required
              />
              {digitos && !/^\d+$/.test(digitos) && (
                <div className="text-danger">Ingresa solo números</div>
              )}
            </div>
            <div className="form-group mb-3">
              <label className={formulario.label}>Ingrese el monto</label>
              <input
                className={`form-control ${formulario.input}`}
                type="text"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                pattern="[0-9]*"
                required
              />
              {amount && !/^\d+$/.test(amount) && (
                <div className="text-danger">Ingresa solo números</div>
              )}
            </div>
          </div>
          <div className="w-48">
            <div
              className={`form-group mb-3 text-center ${formulario.contenedorimagenmp}`}
            >
              <div className="mb-3">
                <img
                  src={ticket}
                  alt="Descripción de la imagen"
                  className="imagen"
                />
              </div>

              <div className="form-group mb-3 text-center">
                <input
                  className="form-control-file"
                  type="file"
                  accept=".pdf, .jpg, .png"
                  style={{ display: "none" }}
                  onChange={handleFileInputChange}
                  ref={fileInputRef}
                />
                <button
                  className={`btn btn-primary btn-block ${formulario.botonadjuntarmp}`}
                  onClick={(e) => {
                    e.preventDefault();
                    fileInputRef.current.click();
                  }}
                >
                  {file ? `Adjuntar Otro Comprobante` : "Adjuntar Comprobante"}
                </button>
              </div>

              {file && (
                <div className="mb-3 text-center">
                  <img
                    src={URL.createObjectURL(file)}
                    alt="Comprobante adjunto"
                    className={formulario.imagenchicamp}
                  />
                </div>
              )}
            </div>
          </div>

          <div className={`w-100 mt-3 ${formulario.containerbotonmp}`}>
            <button
              type="submit"
              className={`btn btn-primary btn-block ${formulario.botonenviarmp}`}
              disabled={!file}
            >
              Enviar
            </button>
            {!file && (
              <div className={`text-danger mt-2 ${formulario.mensajemp}`}>
                Debes cargar el comprobante
              </div>
            )}
          </div>
        </form>
      </div>

      <div
        className={`modal fade ${isModalOpen ? "show" : ""}`}
        id="imageModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: isModalOpen ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  setIsModalOpen(false);
                  console.log("Modal cerrado");
                }}
                aria-label="Close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className={`modal-body ${formulario.modalimagenmp}`}>
              <img
                src={ticketmp}
                alt="Comprobante adjunto"
                className={formulario.imagenchicamp}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
