import React from 'react';
import Estilos from "../styles/ConfirmarTalle.module.css"

export default function ConfirmarTalles() {
  const handleSubmit = (event) => {
    event.preventDefault(); 
    console.log('Formulario enviado');
  };

  return (
    <>
    <div className={Estilos.containerprincipal}>
    <form onSubmit={handleSubmit}>
      <div>
        <h1 className={Estilos.titulo}>Confirmas que los datos <br/> ingresados sean correctos?</h1>
        <div className={` ${Estilos.containerinputs}`}>
              <label htmlFor="alumno" className={`mr-2 ${Estilos.labels}`}>
                Alumno
              </label>
              <input
                name="name"
                type="text"
                className={`form-control ${Estilos.inputs}`}
                id="alumno"
                // value={alumno}
                // onChange={handleAlumnoChange}
              />
            </div>
            <div className={` ${Estilos.containerinputs}`}>
              <label htmlFor="apodo" className={`mr-2 ${Estilos.labels}`}>
                Apodo
              </label>
              <input
                name="nickname"
                type="text"
                className={`form-control ${Estilos.inputsapodo}`}
                id="apodo"
                // value={apodo}
                // onChange={handleApodoChange}
              />
            </div>
        <div className={` ${Estilos.containerinputs}`}>
          <label htmlFor="personalizado"  className={`mr-2 ${Estilos.labels}`}>Personalizado</label>
          <div className="col-sm-10">
            <input type="text" className={`form-control ${Estilos.inputpersonalizado}`} id="personalizado" />
          </div>
        </div>
        <div className="form-group row">
          <h4  className={`mr-2 ${Estilos.labels}`}>Talles</h4>
          <div className={`col-sm-10  ${Estilos.containertalles}`}>
          <label htmlFor="talles"  className={`mr-2 ${Estilos.labels}`}>Jardin/primaria</label>
          <input type="text" className={`form-control ${Estilos.inputtalles}`} id="personalizado" />

          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-10 offset-sm-2">
            <button type="submit" className={`btn ${Estilos.botonconfirmar}`}>Confirmar</button>
          </div>
        </div>
      </div>
    </form>
    </div>
    </>
  )
}
