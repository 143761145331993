import React from 'react';
import tallesStyle from "../styles/estilos/Talles.module.css";
import Guiaprimaria from "../images/guiaprimaria.png";
import tablaOversize from "../images/tallerEditado.jpg"
import imagenFrenteOver from "../images/imagenFrenteOver.png";
import talleDesk from "../images/talleDeskRosa.png";
import talleAzul from "../images/tallesDeskAzul.png";
function GuiaTallesOversizeModal({onClose}){
  return(

 
    <div className={tallesStyle.modalBackground2}>
      <div className={tallesStyle.modalGuias2}>
      
  
        <div className={tallesStyle.modalContentGuiasDesk}>
           <h2 className={tallesStyle.titleDesk}>TALLES <span  className={tallesStyle.pinkspan}>OVERSIZE</span> </h2>
           <p className={tallesStyle.texto}>
           Elegi una prenda que te quede comoda y toma la medida
          sobre una superficie plana y bien estirada
          Si te da ancho Talle S  y alto Talle M elegi siempre
          el mas grande de los dos   
           </p>
         <div className={tallesStyle.deskContainer}>
           
            <div className={tallesStyle.imagenes}>
              <div className={tallesStyle.imagen}>
               <img src={talleDesk} alt="talle"  className={tallesStyle.rosa}/>
               <img src={talleAzul} alt="talle"  className={tallesStyle.azul}/>
              </div>
              <div className={tallesStyle.frentes}>   
               <img src={imagenFrenteOver} alt="buzo" className={tallesStyle.frente} />
              </div>
            </div>
           
           <div className={tallesStyle.tablaDesk} >
               <img src={tablaOversize} alt="tabla" className={tallesStyle.tabladesk} />
           </div>
           
       </div>
  
          <button className={tallesStyle.closeDesk} onClick={onClose}>CERRAR</button>
  
       </div> 
       <img src={imagenFrenteOver} alt=""  className={tallesStyle.guiaCell}/> 
       <p className={tallesStyle.pcell}>Las medidas pueden variar hasta 2cm una vez confeccionadas</p>
       <img src={tablaOversize} alt="" className={tallesStyle.tablaCell}/>
       <button className={tallesStyle.closeCell} onClick={onClose}>CERRAR</button>
      </div>
    </div>
    );
}
export default GuiaTallesOversizeModal;