 import React from 'react';
import tallesStyle from "../styles/estilos/Talles.module.css";
import guideStyle from "../styles/estilos/guide.module.css";
import fantasma from "../images/fantasmaOpinion.png";
import remera from "../images/guideImages/remera.jpg";
import imagen5 from "../images/guideImages/guia2Imagen1.png";
import imagen6 from "../images/guideImages/guia2Imagen2.png";
import imagen7 from "../images/guideImages/guia2Imagen3.png";
import imagen9 from "../images/guideImages/guia2Imagen5.png";
import imagen10 from "../images/guideImages/guia2Imagen6.png";
import imagen11 from "../images/guideImages/recortev70.png";
import imagen12 from "../images/guideImages/imagen12.png";
import imagen13 from "../images/guideImages/imagen13.png";
import imagen14 from "../images/guideImages/imagen14.png";
import imagen15 from "../images/guideImages/imagen15.png";
const ModalTutorial2 = ({ onClose, onNext,onBack }) =>{ 

  return(
  
  <div className={tallesStyle.modalBackground}>
   <div className={tallesStyle.modalG1}>
    <div className={tallesStyle.modalContent}></div>
    <div className={guideStyle.cellContent}>
     <h4>QUE TIPO DE RECORTE LLEVA TU PRENDA?</h4>
     <div className={guideStyle.announce}>
      <p>SI NO LLEVA RECORTES ES UNA PRENDA LISA</p>
      <img  className={guideStyle.fantasma} src={fantasma} alt="fantasma"/>
    </div>
    <section>
      <p className={guideStyle.question}>ES UN RECORTE SIMPLE?</p>
      <div className={guideStyle.modelimages2}>

      <div className={guideStyle.category}>
        <p>Recorte <br/>50/50</p>
        <img  className={guideStyle.img}
        src={imagen5} alt="remera"/>
      </div>

      <div className={guideStyle.category}>
        <p>Recorte <br/>70/30</p>
        <img className={guideStyle.img} src={imagen6} alt="remera"/>
       </div>

       <div className={guideStyle.category}>
        <p>Recorte <br/> M</p>
        <img className={guideStyle.img} src={imagen7} alt="remera"/>
       </div> 

       <div className={guideStyle.category}>
        <p>Recorte 70/30 <br/>vertical</p>
        <img  className={guideStyle.img} src={imagen11} alt="remera"/>
       </div> 
       </div>
    </section>

    <section>
      <p className={guideStyle.question}>ES UN RECORTE MEDIO?</p>
      <div className={guideStyle.modelimages2}>

      <div className={guideStyle.category}>
        <p>RECORTE <br/>BOCA</p>
        <img  className={guideStyle.img}
        src={imagen9} alt="remera"/>
      </div>

      <div className={guideStyle.category}>
        <p>RECORTE <br/>VELEZ</p>
        <img className={guideStyle.img} src={imagen10} alt="remera"/>
       </div>
    </div>
  </section>
  <section>
      <p className={guideStyle.question}>ES UN RECORTE COMPLEJO?</p>
      <div className={guideStyle.modelimages2}>

      <div className={guideStyle.category}>
        <p>Recorte <br/>50/50</p>
        <img  className={guideStyle.img}
        src={imagen12} alt="remera"/>
      </div>

      <div className={guideStyle.category}>
        <p>Recorte <br/>70/30</p>
        <img className={guideStyle.img} src={imagen13} alt="recorte 70/30"/>
       </div>

       <div className={guideStyle.category}>
        <p>Recorte <br/> M</p>
        <img className={guideStyle.img} src={imagen14} alt="recorte m"/>
       </div> 

       <div className={guideStyle.category}>
        <p>Recorte 70/30 <br/>vertical</p>
        <img  className={guideStyle.img} src={imagen15} alt="recorte vertical 70/30"/>
       </div> 
       </div>
    </section>
    <div className={guideStyle.specialAnnounce}>
      
    <p className={guideStyle.mensaje}>SI TU DISEÑO NO COINCIDE CON NIGUNO DE LOS  ANTERIORES 
        <b> MARCA OTRO Y ADJUNTA IMAGEN DE REFERENCIA.</b></p>
    </div>
    <div className={guideStyle.buttonContainer2}>
      <button className={guideStyle.one}  onClick={onClose}>Cerrar</button>
      <button className={guideStyle.two} onClick={onBack}>Atras</button>
      <button className={guideStyle.two} onClick={onNext}>Siguiente</button>
    </div>
    </div>
    {/**Escritorio */}
   <div className={guideStyle.deskContent}>
     <h4>QUE TIPO DE RECORTE LLEVA TU PRENDA?</h4>
     <div className={guideStyle.announce}>
      <p>SI NO LLEVA RECORTES ES UNA PRENDA LISA</p>
      <img  className={guideStyle.fantasma} src={fantasma} alt="fantasma"/>
     </div>
     <section className={guideStyle.recorteContainer}>
      <div className={guideStyle.first}>
      <section>
      <p className={guideStyle.question}>ES UN <strong>RECORTE SIMPLE</strong> ?</p>
      <div className={guideStyle.modelimages2}>

      <div className={guideStyle.category}>
        <p>RECORTE <br/>50/50</p>
        <img  className={guideStyle.img}
        src={imagen5} alt="remera"/>
      </div>

      <div className={guideStyle.category}>
        <p>RECORTE <br/>70/30</p>
        <img className={guideStyle.img} src={imagen6} alt="remera"/>
       </div>

       <div className={guideStyle.category}>
        <p>RECORTE <br/> M</p>
        <img className={guideStyle.img} src={imagen7} alt="remera"/>
       </div> 

       <div className={guideStyle.category}>
        <p>RECORTE 70/30 <br/>vertical</p>
        <img  className={guideStyle.img} src={imagen11} alt="remera"/>
       </div>
     </div>
      </section>
      <section>
      <p className={guideStyle.question}>ES UN RECORTE MEDIO?</p>
      <div className={guideStyle.modelimages2}>

      <div className={guideStyle.category}>
        <p>RECORTE <br/>BOCA</p>
        <img  className={guideStyle.img}
        src={imagen9} alt="remera"/>
      </div>

      <div className={guideStyle.category}>
        <p>RECORTE <br/>VELEZ</p>
        <img className={guideStyle.img} src={imagen10} alt="remera"/>
       </div>
    </div> 
      </section>
      </div>
      <p className={guideStyle.question}>ES UN RECORTE COMPLEJO?</p> 
     <div className={guideStyle.second}>

     <div className={guideStyle.modelimages2}>

      <div className={guideStyle.category}>
        <p>RECORTE <br/>ARLEQUIN</p>
        <img  className={guideStyle.img}
        src={imagen12} alt="remera"/>
      </div>

      <div className={guideStyle.category}>
        <p>RECORTE <br/>DIAGONAL</p>
        <img className={guideStyle.img} src={imagen13} alt="recorte 70/30"/>
       </div>

       <div className={guideStyle.category}>
        <p>RECORTE<br/>U-BIKER</p>
        <img className={guideStyle.img} src={imagen14} alt="recorte m"/>
       </div> 

       <div className={guideStyle.category}>
        <p>RECORTE<br/>X</p>
        <img  className={guideStyle.img} src={imagen15} alt="recorte vertical 70/30"/>
       </div>

        
     </div>
        <div className={guideStyle.announce}>
           <p className={guideStyle.mensaje}>SI TU DISEÑO NO COINCIDE CON NIGUNO DE LOS  ANTERIORES 
           <b> MARCA OTRO Y ADJUNTA IMAGEN DE REFERENCIA.</b></p>
           <img  className={guideStyle.fantasma} src={fantasma} alt="fantasma"/>
        </div>
     </div>
     </section>
     <div className={guideStyle.buttonContainer2}>
      <button className={guideStyle.one}  onClick={onClose}>Cerrar</button>
      <button className={guideStyle.two} onClick={onBack}>Atras</button>
      <button className={guideStyle.two} onClick={onNext}>Siguiente</button>
    </div>
   </div>
  </div>
  </div>
   );
 };
  export default ModalTutorial2;