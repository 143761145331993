import React from 'react';
import tallesStyle from "../styles/estilos/Talles.module.css";
import guideStyle from "../styles/estilos/guide.module.css";
import fantasma from "../images/fantasmaOpinion.png";
import remera30 from "../images/remeras/remera30.png";
import remera31 from "../images/remeras/remera31.png";
import remera32 from "../images/remeras/remera32.png";
import remera33 from "../images/remeras/remera33.png";
import remera34 from "../images/remeras/remera34.png";
import remera36 from "../images/remeras/remera36.png";
import remera37 from "../images/remeras/remera37.png";
import imagenremera20 from "../images/remeras/imagenremera20.png";
import imagenremera21 from "../images/remeras/imagenremera21.png";
import imagenremera22 from "../images/remeras/imagenremera22.png";
import imagenremera23 from "../images/remeras/imagenremera23.png";
import imagenremera24 from "../images/remeras/imagenremera24.png";
import imagenremera26 from "../images/remeras/imagenremera26.png";
import imagenremera27 from "../images/remeras/imagenremera27.png";
import imagenremera28 from "../images/remeras/imagenremera28.png";
import imagenremera29 from "../images/remeras/imagenremera29.png";
const GuiaRemera5 = ({ onClose, onNext,onBack }) =>{ 

  return(
  
  <div className={tallesStyle.modalBackground}>
   <div className={tallesStyle.modalG1}>
    <div className={tallesStyle.modalContent}></div>
    <div className={guideStyle.cellContent}>
     
     <div className={guideStyle.number}>
      <h2 className={guideStyle.num}>8</h2>
      <p> LLEVA TIRAS APLICADAS?</p>
    </div>

    <div className={guideStyle.announceCarta}>
      <p>RECORDA PONER LA UBICACION DE LAS TIRAS Y LA CANTIDAD</p>
      <img  className={guideStyle.fantasma} src={fantasma} alt="fantasma"/>
    </div>
   
    <div className={guideStyle.container} >
     <div className={guideStyle.modelImages}>

       <div className={guideStyle.category}>
         <p>SI</p>
         <img src={remera30} alt="remera"/>
       </div>

       <div className={guideStyle.category}>
         <p>NO</p>
         <img src={remera31} alt="remera"/>
       </div>

     </div>
    </div>

    <div className={guideStyle.number}>
      <h2 className={guideStyle.num}>9</h2>
      <p> LLEVA APLIQUES?</p>
    </div>

    <div className={guideStyle.announceCarta}>
      <p> <span className={guideStyle.underline} >RECTO: </span>  TERMINACION BORDADA CON HILO</p>  
      <p> <span className={guideStyle.underline} >RÚSTICO:</span>  TERMINACION EN TELA DEFLECADA CON PESPUNTE</p>
      <img  className={guideStyle.fantasma} src={fantasma} alt="fantasma"/>
    </div>
   
    <div className={guideStyle.container} >
     <div className={guideStyle.modelImages}>

       <div className={guideStyle.category}>
         <p>RECTO</p>
         <img src={remera32} alt="remera"/>
       </div>

       <div className={guideStyle.category}>
         <p>Rústico</p>
         <img src={remera33} alt="remera"/>
       </div>

       <div className={guideStyle.category2}>
         <p>RÚSTICO <br/> DOBLE TELA</p>
         <img src={remera34} alt="remera"/>
       </div>

       <div className={guideStyle.category}>
         <p>ESTAMPADO</p>
         <img src={remera36} alt="remera"/>
       </div>

     </div>
    </div>
   
    <div className={guideStyle.number}>
      <h2 className={guideStyle.num}>10</h2>
      <p> TIPOGRAFÍAS</p>
    </div>
     <h6> TIPOGRAFÍAS DE <span className={guideStyle.blue} > APLIQUE</span> </h6>
     <div className={guideStyle.tipografiascontainer}>
     <img className={guideStyle.tipografias} src={remera37} alt="tipografias de aplique"/>
     </div>
    <div className={guideStyle.buttonContainer}>
      <button className={guideStyle.one}  onClick={onClose}>Cerrar</button>
      <button className={guideStyle.two} onClick={onBack}>Atras</button>
      <button className={guideStyle.two} onClick={onNext}>Siguiente</button>
    </div>
  </div>
  {/**desk */}
  <div className={guideStyle.deskContent}>
    <section className={guideStyle.first3}>
    <div className={guideStyle.number1}>
        <h2 className={guideStyle.num}>6</h2>
        <p>COLOR DE CUELLO</p>
     </div>

  <div className={guideStyle.container} >
    <div className={guideStyle.modelImages}>

     <div className={guideStyle.category}>
        <p>COLOR LISO</p>
        <img src={imagenremera20} alt="remera"/>
     </div>
    
    <div className={guideStyle.category}>
       <p>FULLPRINT</p>
       <img src={imagenremera21} alt="remera"/>
    </div>

    <div className={guideStyle.category2}>
       <p>3 TIRAS <br/>TEJIDO </p>
       <img src={imagenremera22} alt="remera"/>
    </div>

    <div className={guideStyle.category2}>
       <p>5 TIRAS<br/>TEJIDO </p>
       <img src={imagenremera23} alt="remera"/>
    </div>

    </div>
  </div>
    </section>
    <section  className={guideStyle.second3}>
    <div className={guideStyle.number1}>
        <h2 className={guideStyle.num}>7</h2>
        <p>COLOR DE PUÑOS</p>
     </div>

   <div className={guideStyle.container} >
    <div className={guideStyle.modelImages}>

    
   <div className={guideStyle.category}>
       <p>REMATE COSIDO </p>
       <img src={imagenremera24} alt="remera"/>
   </div>

    <div className={guideStyle.category}>
       <p>COLOR LISO</p>
      <img src={imagenremera26} alt="remera"/>
    </div>
    <div className={guideStyle.category}>
       <p>FULLPRINT</p>
      <img src={imagenremera27} alt="remera"/>
    </div>
    <div className={guideStyle.category2}>
       <p>3 TIRAS <br/>TEJIDO</p>
      <img src={imagenremera28} alt="remera"/>
    </div>
    <div className={guideStyle.category2}>
       <p>5 TIRAS <br/>TEJIDO</p>
      <img src={imagenremera29} alt="remera"/>
    </div>
  </div>
  </div>
  
    </section>
  <div className={guideStyle.buttonContainerRemeras}>
      <button className={guideStyle.one} onClick={onClose}>Cerrar</button>
      <button className={guideStyle.two} onClick={onBack}>Atras</button>
      <button className={guideStyle.two} onClick={onNext}>Siguiente</button>
    </div>
  </div>
  </div>
  </div>
   );
 };
  export default GuiaRemera5;