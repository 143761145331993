import React from 'react';
import tallesStyle from "../styles/estilos/Talles.module.css";
import guideStyle from "../styles/estilos/guide.module.css";
import fantasma from "../images/fantasmaOpinion.png";
import remera from "../images/guideImages/remera.jpg";
import imagen34 from "../images/guideImages/imagen34.png";
import imagen35 from "../images/guideImages/imagen35.png";
import imagen36 from "../images/guideImages/imagen36.png";
import imagen37 from "../images/guideImages/imagen37.png";
import imagen38 from "../images/guideImages/imagen38.png";
import imagen39 from "../images/guideImages/imagen39.png";
import imagen40 from "../images/guideImages/imagen40.png";
import imagen41 from "../images/guideImages/imagen41.png";
import imagen30 from "../images/guideImages/imagen30.png"
import imagen31 from "../images/guideImages/imagen31.png"
import imagen32 from "../images/guideImages/imagen32.png"
import imagen33 from "../images/guideImages/imagen33.png"


const ModalTutorial5 = ({ onClose, onNext,onBack }) =>{ 

  return(
  
  <div className={tallesStyle.modalBackground}>
   <div className={tallesStyle.modalG1}>
    <div className={tallesStyle.modalContent}></div>
    {/**contenido celular */}
    <div className={guideStyle.cellContent}>
     
     <div className={guideStyle.number}>
       <h2 className={guideStyle.num}>6</h2>
       <p>PUÑOS Y CINTURA</p>
     </div>
   
    <section>
      
      <div className={guideStyle.modelimages2}>

      <div className={guideStyle.category}>
        <p>COLOR LISO</p>
        <img  className={guideStyle.img}
        src={imagen34} alt="puño liso"/>
      </div>

      <div className={guideStyle.category}>
        <p>FULL PRINT</p>
        <img className={guideStyle.img} src={imagen35} alt="remera"/>
       </div>

       <div className={guideStyle.category}>
        <p>3 TIRAS</p>
        <img className={guideStyle.img} src={imagen36} alt="remera"/>
       </div> 

       <div className={guideStyle.category}>
        <p>5 TIRAS</p>
        <img  className={guideStyle.img} src={imagen37} alt="remera"/>
       </div> 
       </div>
    </section>

    <div className={guideStyle.number}>
      <h2 className={guideStyle.num}>7</h2>
      <p>ESTAMPADOS FULL PRINT</p>
    </div>

    <div className={guideStyle.announce}>
      <p>Pedile a tu asesor/a el catálogo actualizado</p>
      <img  className={guideStyle.fantasma} src={fantasma} alt="fantasma"/>
    </div>

    <div className={guideStyle.number}>
      <h2 className={guideStyle.num}>8</h2>
      <p>TIPOS DE BORDADOS Y APLIQUE</p>
    </div>

    <section>
      
      <div className={guideStyle.modelimages2}>

      <div className={guideStyle.category}>
        <p>BORDADO</p>
        <img  className={guideStyle.img}
        src={imagen38} alt="remera"/>
      </div>

      <div className={guideStyle.category}>
        <p>APLIQUE RECTO</p>
        <img className={guideStyle.img} src={imagen39} alt="remera"/>
       </div>
       <div className={guideStyle.category2}>
        <p>APLIQUE <br/> RÚSTICO</p>
        <img  className={guideStyle.img}
        src={imagen40} alt="remera"/>
      </div>
      <div className={guideStyle.category2}>
        <p>RÚSTICO <br/> DOBLE TELA</p>
        <img  className={guideStyle.img}
        src={imagen41} alt="remera"/>
      </div>
    </div>
  </section>
    <div className={guideStyle.number}>
      <h2 className={guideStyle.num}>9</h2>
      <p>PREDISEÑOS</p>
    </div>
    <div className={guideStyle.announce}>
      <p>Pedile a tu asesor/a los prediseños de tu promo</p>
      <img  className={guideStyle.fantasma} src={fantasma} alt="fantasma"/>
    </div>
     <div className={guideStyle.buttonContainer}>
       <button className={guideStyle.one}  onClick={onClose}>Cerrar</button>
       <button className={guideStyle.two} onClick={onBack}>Atras</button>
       <button className={guideStyle.two} onClick={onNext}>Siguiente</button>
      </div>
    </div>
     {/**Escritorio */}
  <div className={guideStyle.deskContent1}>
 
     <div className={guideStyle.modelimages4}>

      <div className={guideStyle.number}>
        <h2 className={guideStyle.num}>5</h2>
        <p>BOLSILLO</p>
      </div>

     <section  className={guideStyle.modelimage3}>
        <div className={guideStyle.category}>
          <p>SIN BOLSILLO</p>
          <img className={guideStyle.img} src={imagen30} alt="sin bolsillo"/>
        </div>

        <div className={guideStyle.category}>
         <p>CANGURO</p>
         <img className={guideStyle.img} src={imagen31} alt="remera"/>
        </div> 

        <div className={guideStyle.category}>
          <p>RECTO</p>
          <img  className={guideStyle.img} src={imagen32} alt="remera"/>
        </div>

        <div className={guideStyle.category}>
          <p>DIAGONAL</p>
          <img  className={guideStyle.img} src={imagen33} alt="remera"/>
        </div>
     </section>
     </div> 
      
    <div className={guideStyle.modelimages4}>

       <div className={guideStyle.number}>
         <h2 className={guideStyle.num}>6</h2>
         <p>PUÑOS Y CINTURA</p>
       </div>
  <section className={guideStyle.modelimage3}>
       <div className={guideStyle.category}>
         <p>COLOR LISO</p>
         <img  className={guideStyle.img}
         src={imagen34} alt="puño liso"/>
       </div>

       <div className={guideStyle.category}>
         <p>FULL PRINT</p>
         <img className={guideStyle.img} src={imagen35} alt="remera"/>
        </div>

       <div className={guideStyle.category}>
         <p>3 TIRAS</p>
         <img className={guideStyle.img} src={imagen36} alt="remera"/>
       </div> 

       <div className={guideStyle.category}>
        <p>5 TIRAS</p>
        <img  className={guideStyle.img} src={imagen37} alt="remera"/>
       </div>
       </section>
    </div>
    <div className={guideStyle.buttonContainer5}>
      <button className={guideStyle.one}  onClick={onClose}>Cerrar</button>
      <button className={guideStyle.two} onClick={onBack}>Atras</button>
      <button className={guideStyle.two} onClick={onNext}>Siguiente</button>
    </div>
  </div>
  
  </div>
  </div>
   );
 };
  export default ModalTutorial5;