import React, { useEffect, useState } from "react";
import dashboardStyle from "../styles/Dashboard.module.css";
import PagoFecilInfo from "./infoMetodospaggo/PagoFecilInfo"
import TarjDebitoInfo from "./infoMetodospaggo/TarjDebitoInfo"
import TarjCreditoInfo from "./infoMetodospaggo/TarjCreditoInfo"
import TransferInfo from "./infoMetodospaggo/TransferInfo"
import DepositoInfo from "./infoMetodospaggo/DepositoInfo"
import EfectivoInfo from "./infoMetodospaggo/EfectivoInfo";
import { useDataContext } from "../contexts/AuthContext";
import { getPaymentInformation } from "../services/api";

const ModalInfoPago=({toggleModalInfo})=>{
  
    const { contextData } = useDataContext();
  const [paymentMethod, setPaymentMethod] = useState("");
  const [infoVisible, setInfoVisible] = useState(null);

  useEffect(() => {
    if(!contextData || !contextData.customer.id){
      return
    }
    const fetchPaymentInformation = async () => {
      try {
        const data = await getPaymentInformation(contextData.customer.id);
        console.log("Datos de pago:", data);
        setPaymentMethod(data.payment_method);
      } catch (error) {
        console.log(
          `Error en la solicitud de información de pago: ${error.message}`
        );
      }
    };
    
    fetchPaymentInformation();
  }, [contextData.customer.id]);


  useEffect(() => {
    if(!paymentMethod){
      return
    }
    // Lógica para mostrar el formulario correspondiente
    switch (paymentMethod) {
      case "Depósito Bancario":
        setInfoVisible(<DepositoInfo toggleModalInfo={toggleModalInfo} />);
        break;
        case "Efectivo":
        setInfoVisible(<EfectivoInfo toggleModalInfo={toggleModalInfo} />);
        break;
      case "PagoFácil":
        setInfoVisible(<PagoFecilInfo toggleModalInfo={toggleModalInfo}/>);
        break;
      case "Tarjeta de crédito":
        setInfoVisible(<TarjCreditoInfo toggleModalInfo={toggleModalInfo} />);
        break;
      case "Tarjeta de débito":
        setInfoVisible(<TarjDebitoInfo  toggleModalInfo={toggleModalInfo}/>);
        break;
      case "Transferencia Bancaria":
        setInfoVisible(<TransferInfo toggleModalInfo={toggleModalInfo}/>);
        break;
      default:
        setInfoVisible(<p>Seleccione un método de pago.</p>);
        break;
    }
  }, [paymentMethod]);
  return(
    <>
    <div className={dashboardStyle.modalBackground}>
        <div className={dashboardStyle.modalInfo}>
          <div className={dashboardStyle.modalContent}>
          {infoVisible}
           <button className={dashboardStyle.closer} onClick={toggleModalInfo}>X</button>
           </div> 
        </div>
    </div>
    </>
  )

}
export default ModalInfoPago;