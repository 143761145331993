import React, { useState, useEffect } from "react";
import enviosStyle from "../styles/estilos/envios.module.css";

const FormularioAutocompletado = ({
  currentAddress,
  provincia,
  ciudad,
  departamento,
  handleEdition
}) => {
  const [nombre, setNombre] = useState("");
  const [telefono, setTelefono] = useState("");
  const [provinciaSeleccionada, setProvinciaSeleccionada] = useState("");
  const [municipioSeleccionado, setMunicipioSeleccionado] = useState("");
  const [localidadSeleccionada, setLocalidadSeleccionada] = useState("");
  const [codigo, setCodigo] = useState("");
  const [direccion, setDireccion] = useState("");
  const [numero, setNumero] = useState("");
  const [piso, setPiso] = useState("");
  const [mensaje, setMensaje] = useState("");

  useEffect(() => {
    if(!currentAddress){
      return
    }
    if (currentAddress) {
      setNombre(currentAddress.receives);
      setTelefono(currentAddress.phone); 
      setProvinciaSeleccionada(provincia)
      setMunicipioSeleccionado(departamento)
      setLocalidadSeleccionada(ciudad)
      setCodigo(currentAddress.postal_code)
      setDireccion(currentAddress.address)
      setNumero(currentAddress.number)
      setPiso(currentAddress.apt)
      setMensaje(currentAddress.details)
    }
  }, [currentAddress]);

  const habilitarEdicion = () => {
    handleEdition()
  }
  return (
    <form action="post">
      <h4 className={enviosStyle.firstTitle}>
        Puedes editar el siguiente formulario:
      </h4>

      <div className={enviosStyle.name}>
        <div className={enviosStyle.inputs}>
          <label className={enviosStyle.nameLabel} htmlFor="nombre">
            NOMBRE Y APELLIDO
          </label>
          <input
            type="text"
            id="nombre"
            value={nombre}
            className={enviosStyle.nameInput}
            required
            disabled
          />
        </div>

        <div className={enviosStyle.inputTelefono}>
          <label className={enviosStyle.labelenvios} htmlFor="telefono">
            NUM.DE CELULAR{" "}
          </label>
          <input
            type="text"
            id="telefono"
            value={telefono}
            className={enviosStyle.rectangle}
            placeholder={"5654519"}
            required
            disabled
          />
          <span className={enviosStyle.spanenvios}>
            (SIN 0 Y SIN 15. COD. DE ÁREA + NÚM. DE LINEA){" "}
          </span>
        </div>
      </div>

      <div className={enviosStyle.localization}>
        <div className={enviosStyle.inputs}>
          <div className={enviosStyle.selectContainer}>
            <select
              id="provincia"
              className={enviosStyle.selects}
              value={provinciaSeleccionada}
              required
              disabled
            >
              <option value="" className={enviosStyle.option}>
                {provincia}
              </option>
            </select>
          </div>
        </div>

        <div className={enviosStyle.inputs}>
         <label htmlFor="municipio"></label>
          <div className={enviosStyle.selectContainer}>
            <select
              className={enviosStyle.selects}
              id="municipio"
              value={municipioSeleccionado}
              required
              disabled
            >
               <option value="" className={enviosStyle.option}>
                {departamento}
              </option>
            </select>
            <div className={enviosStyle.arrowIcon}></div>
          </div>
        </div>

        <div className={enviosStyle.inputs}>
          <div className={enviosStyle.selectContainer}>
            <select
              className={enviosStyle.selects}
              id="localidad"
              value={localidadSeleccionada}
              required
              disabled
            >
              <option value="">{ciudad}</option>
            </select>
            <div className={enviosStyle.arrowIcon}></div>
          </div>
        </div>

        <div className={enviosStyle.inputs}>
          <label htmlFor="codigo">CP</label>
          <input
            type="text"
            id="codigo"
            value={codigo}
            className={enviosStyle.codigoInput}
            required
            disabled
          />
        </div>
      </div>

      <div className={enviosStyle.street}>
        <div className={enviosStyle.inputs}>
          <label htmlFor="direccion">CALLE</label>
          <input
            type="text"
            id="direccion"
            value={direccion}
            className={enviosStyle.streetInput}
            required
            disabled
          />
        </div>
        <div className={enviosStyle.inputs}>
          <label htmlFor="numero">ALTURA</label>
          <input
            type="text"
            id="numero"
            value={numero}
            className={enviosStyle.heightInput}
            required
            disabled
          />
        </div>
        <div className={enviosStyle.inputs}>
          <label htmlFor="numero">PISO</label>
          <input
            type="text"
            id="piso"
            value={piso}
            className={enviosStyle.pisoInput}
            disabled
          />
        </div>
      </div>

      <div className={enviosStyle.messInputs}>
        <label htmlFor="mensaje">ACLARACIONES</label>
        <textarea
          id="mensaje"
          value={mensaje}
          className={enviosStyle.specialInput}
          disabled
        ></textarea>
      </div>
      <div className={enviosStyle.nextContainer}>
        <button
          className={enviosStyle.next}
          type="button"
          onClick={habilitarEdicion}
        >
          EDITAR
        </button>
      </div>
    </form>
  );
};

export default FormularioAutocompletado;
