import React from 'react';
import tallesStyle from "../styles/estilos/Talles.module.css";
import guideStyle from "../styles/estilos/guide.module.css";
import fantasma from "../images/fantasmaOpinion.png";
import remera from "../images/guideImages/remera.jpg";
import imagen42 from "../images/guideImages/imagen42.png";
import imagen43 from "../images/guideImages/imagen43.png";
import imagen44 from "../images/guideImages/imagen44.png";
import imagen38 from "../images/guideImages/imagen38.png";
import imagen39 from "../images/guideImages/imagen39.png";
import imagen40 from "../images/guideImages/imagen40.png";
import imagen41 from "../images/guideImages/imagen41.png";
const ModalTutorial6 = ({ onClose,onBack, onNext }) =>{ 

  return(
  
  <div className={tallesStyle.modalBackground}>
   <div className={tallesStyle.modalG1}>
    <div className={tallesStyle.modalContent}></div>
    <div className={guideStyle.cellContent}>
    <div className={guideStyle.tipografiascontainer}>

    <div className={guideStyle.number}>
      <h2 className={guideStyle.num}>10</h2>
      <p>TIPOGRAFIAS</p>
    </div>
      <h6>TIPOGRAFIAS DE  <span className={guideStyle.blue}>BORDADO</span></h6>
        
    <img className={guideStyle.tipografias} src={imagen42} alt="tipografias de bordado"/>
    <h6>TIPOGRAFIAS DE  <span className={guideStyle.blue}>APLIQUE</span></h6>

    <img className={guideStyle.tipografias} src={imagen43} alt="tipografias de aplique"/>
    <h6>TIPOGRAFIAS DE  <span className={guideStyle.blue}>ESTAMPADO</span></h6>
    <img className={guideStyle.tipografias} src={imagen44} alt="tipografias de estampado"/>
    <div className={guideStyle.buttonContainer}>
      <button className={guideStyle.one}  onClick={onClose}>Cerrar</button>
      <button className={guideStyle.two} onClick={onBack}>Atras</button>
      <button className={guideStyle.two} onClick={onNext}>Siguiente</button>
    </div>
    </div>
    </div>
     {/**Escritorio */}
  <div className={guideStyle.deskContent6}>
    <div className={guideStyle.number}>
      <h2 className={guideStyle.num}>7</h2>
      <p>ESTAMPADOS FULL PRINT</p>
    </div>
    <div className={guideStyle.announce}>
      <p>Pedile a tu asesor/a el catalogo actualizado</p>
      <img  className={guideStyle.fantasma} src={fantasma} alt="fantasma"/>
    </div>
    <div className={guideStyle.number}>
      <h2 className={guideStyle.num}>8</h2>
      <p>TIPOS DE BORDADOS Y APLIQUE</p>
    </div>
    <div className={guideStyle.modelimages2}>

    <div className={guideStyle.category}>
      <p>BORDADO</p>
      <img  className={guideStyle.img}
      src={imagen38} alt="remera"/>
    </div>

<div className={guideStyle.category}>
  <p>APLIQUE RECTO</p>
  <img className={guideStyle.img} src={imagen39} alt="remera"/>
 </div>
 <div className={guideStyle.category2}>
  <p>APLIQUE <br/> RÚSTICO</p>
  <img  className={guideStyle.img}
  src={imagen40} alt="remera"/>
 </div>
 <div className={guideStyle.category2}>
   <p>RÚSTICO <br/> DOBLE TELA</p>
   <img  className={guideStyle.img}
   src={imagen41} alt="remera"/>
 </div>
 </div>
  <div className={guideStyle.number}>
      <h2 className={guideStyle.num}>9</h2>
      <p>PREDISEÑOS</p>
    </div>
    <div className={guideStyle.announce}>
      <p>Pedile a tu asesor/a los prediseños de tu promo</p>
      <img  className={guideStyle.fantasma} src={fantasma} alt="fantasma"/>
    </div>
    
    <div className={guideStyle.buttonContainer6}>
      <button className={guideStyle.one}  onClick={onClose}>Cerrar</button>
      <button className={guideStyle.two} onClick={onBack}>Atras</button>
      <button className={guideStyle.two} onClick={onNext}>Siguiente</button>
    </div>
  </div>
  </div>
  </div>
   );
 };
  export default ModalTutorial6;