import React from 'react';
import tallesStyle from "../styles/estilos/Talles.module.css";
import guideStyle from "../styles/estilos/guide.module.css";
import fantasma from "../images/fantasmaOpinion.png";
import remera from "../images/guideImages/remera.jpg";
import imagen16 from "../images/guideImages/imagen16.png";
import imagen17 from "../images/guideImages/imagen17.png";
import imagen18 from "../images/guideImages/imagen18.png";
import imagen19 from "../images/guideImages/imagen19.png";
import imagen20 from "../images/guideImages/imagen20.png";
import imagen21 from "../images/guideImages/imagen21.png";
import imagen22 from "../images/guideImages/imagen22.png";
import imagen23 from "../images/guideImages/imagen23.png";
import imagen24 from "../images/guideImages/imagen24.png";
import imagen25 from "../images/guideImages/imagen25.png";
import imagen26 from "../images/guideImages/imagen26.png";
import imagen27 from "../images/guideImages/imagen27.png";
import imagen28 from "../images/guideImages/imagen28.png";

const ModalTutorial3 = ({ onClose, onNext,onBack }) =>{ 

  return(
  
  <div className={tallesStyle.modalBackground}>
   <div className={tallesStyle.modalG1}>
    <div className={tallesStyle.modalContent}></div>
    <div className={guideStyle.cellContent}>
     <h4>TU PRENDA LLEVA CAPUCHA O CUELLO??</h4>
         
     <div className={guideStyle.number}>
      <h2 className={guideStyle.num}>2</h2>
      <p>SI TIENE CAPUCHA, CÓMO ES EL INTERIOR??</p>
    </div>
    <div className={guideStyle.modelimages2}>
    <div className={guideStyle.category}>
        <p>LISO</p>
        <img  className={guideStyle.img}
        src={imagen16} alt="remera"/>
      </div>

      <div className={guideStyle.category}>
        <p>ESTAMPADO</p>
        <img  className={guideStyle.img}
        src={imagen17} alt="remera"/>
      </div>

      <div className={guideStyle.category}>
        <p>CORDERITO</p>
        <img  className={guideStyle.img}
        src={imagen18} alt="remera"/>
      </div>

      <div className={guideStyle.category}>
        <p>PELUCHE</p>
        <img  className={guideStyle.img}
        src={imagen19} alt="remera"/>
      </div>
      </div>
    <section>
      <p className={guideStyle.question}>SI TIENE CUELLO, CÓMO ES?</p>
      <div className={guideStyle.modelimages2}>
      <div className={guideStyle.category}>
        <p>AMERICANO</p>
        <img  className={guideStyle.img}
        src={imagen20} alt="remera"/>
      </div>

      <div className={guideStyle.category}>
        <p>REDONDO</p>
        <img className={guideStyle.img} src={imagen21} alt="remera"/>
       </div>

       <div className={guideStyle.category}>
        <p>EN V</p>
        <img className={guideStyle.img} src={imagen22} alt="remera"/>
       </div> 

       <div className={guideStyle.category}>
        <p>ALTO</p>
        <img  className={guideStyle.img} src={imagen23} alt="remera"/>
       </div> 
      </div>
    </section>

    <div className={guideStyle.number}>
      <h2 className={guideStyle.num}>3</h2>
      <p>TELA</p>
    </div>

    <section>
      
      <div className={guideStyle.modelimages2}>

      <div className={guideStyle.category}>
        <p>FRISA 100%</p>
        <img  className={guideStyle.img}
        src={imagen24} alt="remera"/>
      </div>

      <div className={guideStyle.category}>
        <p>ROMPEVIENTOS</p>
        <img className={guideStyle.img} src={imagen25} alt="remera"/>
       </div>

       <div className={guideStyle.category}>
        <p>SOFTSHELL</p>
        <img  className={guideStyle.img}
        src={imagen26} alt="remera"/>
      </div>
  
    </div>
  </section>
  <p>TELA SUBLIMABLE</p>
  <section>
      
      <div className={guideStyle.modelimages2}>

      <div className={guideStyle.category}>
        <p>FRISA SUBLIMABLE</p>
        <img  className={guideStyle.img}
        src={imagen27} alt="remera"/>
      </div>

      <div className={guideStyle.category}>
        <p>DEPORTIVO SUBLIMABLE</p>
        <img className={guideStyle.img} src={imagen28} alt="remera"/>
       </div>
     </div>
    </section>
    

    <div className={guideStyle.buttonContainer}>
      <button className={guideStyle.one}  onClick={onClose}>Cerrar</button>
      <button className={guideStyle.two} onClick={onBack}>Atras</button>
      <button className={guideStyle.two} onClick={onNext}>Siguiente</button>
    </div>
  </div>
  {/*escritorio */}
  <div className={guideStyle.deskContent}>
  <h4></h4>
         
         <div className={guideStyle.number}>
          <h2 className={guideStyle.num}>2</h2>
          <p>TU PRENDA LLEVA CAPUCHA O CUELLO??</p>
        </div>
        <p className={guideStyle.question3} >SI TIENE CAPUCHA, CÓMO ES EL INTERIOR??</p>
        <div className={guideStyle.modelimages2}>
        <div className={guideStyle.category}>
            <p>LISO</p>
            <img  className={guideStyle.img}
            src={imagen16} alt="remera"/>
          </div>
    
          <div className={guideStyle.category}>
            <p>ESTAMPADO</p>
            <img  className={guideStyle.img}
            src={imagen17} alt="remera"/>
          </div>
    
          <div className={guideStyle.category}>
            <p>CORDERITO</p>
            <img  className={guideStyle.img}
            src={imagen18} alt="remera"/>
          </div>
    
          <div className={guideStyle.category}>
            <p>PELUCHE</p>
            <img  className={guideStyle.img}
            src={imagen19} alt="remera"/>
          </div>
          </div>
        <section>
          <p className={guideStyle.question3}>SI TIENE CUELLO, CÓMO ES?</p>
          <div className={guideStyle.modelimages2}>
          <div className={guideStyle.category}>
            <p>AMERICANO</p>
            <img  className={guideStyle.img}
            src={imagen20} alt="remera"/>
          </div>
    
          <div className={guideStyle.category}>
            <p>REDONDO</p>
            <img className={guideStyle.img} src={imagen21} alt="remera"/>
           </div>
    
           <div className={guideStyle.category}>
            <p>EN V</p>
            <img className={guideStyle.img} src={imagen22} alt="remera"/>
           </div> 
    
           <div className={guideStyle.category}>
            <p>ALTO</p>
            <img  className={guideStyle.img} src={imagen23} alt="remera"/>
           </div> 
          </div>
        </section>
        <div className={guideStyle.buttonContainer3}>
      <button className={guideStyle.one}  onClick={onClose}>Cerrar</button>
      <button className={guideStyle.two} onClick={onBack}>Atras</button>
      <button className={guideStyle.two} onClick={onNext}>Siguiente</button>
    </div>
  </div>  
  </div>
  </div>
   );
 };
  export default ModalTutorial3;