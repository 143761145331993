import React from 'react';
import tallesStyle from "../styles/estilos/Talles.module.css";
import guideStyle from "../styles/estilos/guide.module.css";
import fantasma from "../images/fantasmaOpinion.png";
import danger from "../images/Danger.png";
import remera7 from "../images/remeras/remera7.png";
import remera8 from "../images/remeras/remera8.png";
import remera9 from "../images/remeras/remera9.png";
import remera10 from "../images/remeras/remera10.png";
import remera11 from "../images/remeras/remera11.png";
import remera12 from "../images/remeras/remera12.png";
import remera13 from "../images/remeras/remera13.png";
import remera14 from "../images/remeras/remera14.png";
import remera15 from "../images/remeras/remera15.png";
import remera16 from "../images/remeras/remera16.png";
import remera4 from "../images/remeras/remera4.png";
import remera5 from "../images/remeras/remera5.png";
import remera6 from "../images/remeras/remera6.png";

const GuiaRemera2 = ({ onClose,onBack,onNext }) => {

  return (

    <div className={tallesStyle.modalBackground}>
      <div className={tallesStyle.modalG1}>

        <div className={tallesStyle.modalContent}></div>
        <div className={guideStyle.cellContent}>

          <div className={guideStyle.number}>

            <p>ES UN RECORTE MEDIO?</p>
          </div>

          <div className={guideStyle.container} >
            <div className={guideStyle.modelImages}>

              <div className={guideStyle.category}>
                <p>RECORTE</p> <p>BOCA</p>
                <img src={remera7} alt="remera" />
              </div>

              <div className={guideStyle.category}>
                <p>RECORTE<br />VELEZ</p>
                <img src={remera8} alt="remera" />
              </div>
              <div className={guideStyle.category}>
                <p>RECORTE<br />TRIANGULO</p>
                <img src={remera9} alt="remera" />
              </div>
              <div className={guideStyle.category}>
                <p>RECORTE<br />X</p>
                <img src={remera10} alt="remera" />
              </div>

            </div>
          </div>
          <p>SI TU DISEÑO NO COINCIDE CON NIGUNO DE LOS  ANTERIORES
            MARCA OTRO Y ADJUNTA IMAGEN DE REFERENCIA.</p>

          <div className={guideStyle.number}>
            <h2 className={guideStyle.num}>2</h2>
            <p>TELA</p>
          </div>
          <div className={guideStyle.announce}>
            <p>RECOMENDAMOS QUE PARA LA TELA JERSEY SE USE LA TÉCNICA DE ESTAMPADO
              MIENTRAS QUE PARA LA TELA PIQUE, SE UTILIZE LA TÉCNICA DE APLIQUE/BORDADO</p>
            <img className={guideStyle.fantasma} src={fantasma} alt="fantasma" />
          </div>

          <p>TELA <span className={guideStyle.blue}>SUBLIMABLE</span></p>
          <div className={guideStyle.container} >
            <div className={guideStyle.modelImages}>

              <div className={guideStyle.category2}>
                <p>Jersey de <br /> algodón</p>
                <img src={remera11} alt="remera" />
              </div>

              <div className={guideStyle.category}>
                <p>Pique </p>
                <img src={remera12} alt="remera" />
              </div>

              <div className={guideStyle.category2}>
                <p>jersey <br />sumblimable </p>
                <img src={remera13} alt="remera" />
              </div>

              <div className={guideStyle.category2}>
                <p>Pique<br />sumblimable </p>
                <img src={remera14} alt="remera" />
              </div>

            </div>
          </div>
          <div className={guideStyle.number}>
            <h2 className={guideStyle.num}>3</h2>
            <p>QUE TIPO DE MANGA PREFERIS?</p>
          </div>

          <div className={guideStyle.container} >
            <div className={guideStyle.modelimages3}>
              <div className={guideStyle.category}>
                <p>Normal </p>
                <img src={remera15} alt="remera" />
              </div>

              <div className={guideStyle.category}>
                <p>Ranglan</p>
                <img src={remera16} alt="remera" />
              </div>
            </div>
          </div>
          <div className={guideStyle.buttonContainer}>
            <button className={guideStyle.one} onClick={onClose}>Cerrar</button>
            <button className={guideStyle.two} onClick={onBack}>Atras</button>
            <button className={guideStyle.two} onClick={onNext}>Siguiente</button>
          </div>
        </div>
        {/*escritorio */}
        <div className={guideStyle.deskContent}>
          <section className={guideStyle.remera2content}>
            {/**headers */}
            <h6>QUE TIPO DE RECORTE LLEVA TU PRENDA?</h6>
            <div className={guideStyle.announce}>
              <p>SI NO LLEVA RECORTES ES UNA PRENDA LISA</p>
              <img className={guideStyle.fantasma} src={fantasma} alt="fantasma" />
            </div>
            <div className={guideStyle.firstRemera}>
              <section>
                <h6>ES UN RECORTE SIMPLE?</h6>
                <div className={guideStyle.modelimages2}>
                  
                  <div className={guideStyle.category}>
                    <p>RECORTE</p> <p>50/50</p>
                    <img className={guideStyle.img}
                      src={remera4} alt="remera" />
                  </div>

                  <div className={guideStyle.category}>
                    <p>RECORTE</p> <p>70/30</p>
                    <img className={guideStyle.img} src={remera5} alt="remera" />
                  </div>

                  <div className={guideStyle.category}>
                    <p>RECORTE</p> <p>M</p>
                    <img className={guideStyle.img} src={remera6} alt="remera" />
                  </div>
                </div>
              </section>
              <section>
              <h6>ES UN RECORTE MEDIO?</h6>
            <div className={guideStyle.container} >
             <div className={guideStyle.modelImages}>

              <div className={guideStyle.category}>
                <p>RECORTE</p> <p>BOCA</p>
                <img src={remera7} alt="remera" />
              </div>

              <div className={guideStyle.category}>
                <p>RECORTE<br />VELEZ</p>
                <img src={remera8} alt="remera" />
              </div>
              <div className={guideStyle.category}>
                <p>RECORTE<br />TRIANGULO</p>
                <img src={remera9} alt="remera" />
              </div>
              <div className={guideStyle.category}>
                <p>RECORTE<br />X</p>
                <img src={remera10} alt="remera" />
              </div>

            </div>
          </div>
          </section>
          
            </div>
            <div className={guideStyle.announcetela}>
            <p>SI TU DISEÑO NO COINCIDE CON NIGUNO DE LOS  ANTERIORES
            MARCA OTRO Y ADJUNTA IMAGEN DE REFERENCIA.</p>
            <img className={guideStyle.fantasma} src={fantasma} alt="fantasma" />
          </div> 
          <div className={guideStyle.number}>
                <h2 className={guideStyle.num}>2</h2>
                <p>TELA</p>
               </div>   
          <div className={guideStyle.secondRemera}>
            <section className={guideStyle.telasContainer}>
             
              <div className={guideStyle.modelImages}>
                 <div className={guideStyle.category2}>
                  <p>Jersey de <br /> algodón</p>
                  <img src={remera11} alt="remera" />
                 </div>

                 <div className={guideStyle.category}>
                   <p>Pique </p>
                   <img src={remera12} alt="remera" />
                 </div>
              </div>
          </section>
          <section className={guideStyle.telasublimableContainer}>
            <p>TELA <span className={guideStyle.blue}>SUBLIMABLE</span></p>

            <div className={guideStyle.containerSublimable} >
              <div className={guideStyle.category2}>
                <p>jersey <br />sublimable </p>
                <img src={remera13} alt="remera" />
              </div>

              <div className={guideStyle.category2}>
                <p>Pique<br/>sublimable </p>
                <img src={remera14} alt="remera" />
              </div>
           </div>
          </section>
        </div>
      </section>
          <div className={guideStyle.buttonContainertela}>
            <button className={guideStyle.one} onClick={onClose}>Cerrar</button>
            <button className={guideStyle.two} onClick={onBack}>Atras</button>
            <button className={guideStyle.two} onClick={onNext}>Siguiente</button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default GuiaRemera2;