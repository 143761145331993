import React from 'react';
import tallesStyle from "../styles/estilos/Talles.module.css";
import guideStyle from "../styles/estilos/guide.module.css";
import fantasma from "../images/fantasmaOpinion.png";
import remera38 from "../images/remeras/remera38.png";
import remera39 from "../images/remeras/remera39.png";
import remera37 from "../images/remeras/remera37.png";
const GuiaRemeraIntermediate = ({ onClose, onBack, onNext }) => {
  return (
    <div className={tallesStyle.modalBackground}>
      <div className={tallesStyle.modalG1}>
        <div className={tallesStyle.cellContent}></div>
        <div className={guideStyle.deskContent}>

          <div className={guideStyle.number}>
            <h2 className={guideStyle.num}>10</h2>
            <p> TIPOGRAFÍAS</p>
          </div>

          <div className={guideStyle.tipografiascontainer1}>
            <div className={guideStyle.bordado}>

              <div>
                <h6> TIPOGRAFÍAS DE <span className={guideStyle.blue} > BORDADO</span> </h6>
                <img className={guideStyle.tipografias} src={remera38} alt="tipografias de aplique" />
              </div>

              <div>
              <h6> TIPOGRAFÍAS DE <span className={guideStyle.blue} > APLIQUE</span> </h6>
                <img className={guideStyle.tipografias} src={remera37} alt="tipografias de aplique" />
              </div>

            </div>

            <div className={guideStyle.estampado}>
              <div className={guideStyle.tipografiascontainer}>
                <h6> TIPOGRAFÍAS DE <span className={guideStyle.blue}> ESTAMPADO</span> </h6>
                <img className={guideStyle.tipografias} src={remera39} alt="tipografias de aplique" /> 
              </div>
            </div>

          </div>
          <div className={guideStyle.buttonContainer8}>
            <button className={guideStyle.one} onClick={onClose}>Cerrar</button>
            <button className={guideStyle.two} onClick={onBack}>Atras</button>
            <button className={guideStyle.two} onClick={onNext}>Siguiente</button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default GuiaRemeraIntermediate;