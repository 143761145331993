// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!./fotnts.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CondicionesonesRifas_container__ulR5m {
  font-family: sans-serif;
  max-width: 80%px;
  margin: auto;
  padding: 20px;
  text-align: left;
}
.CondicionesonesRifas_title__A4PII {
  text-align: center;
  color: #d54ab7;
  margin-bottom: 30px;
  padding: inherit;

}
p {
  font-size: 20px;
  margin-bottom: 20px;
  padding: inherit;
}
.CondicionesonesRifas_containerBoton__nPYlX {
  text-align: center;
}
.CondicionesonesRifas_boton__esZRZ {
  background-color: #d54ab7;
  border: #d54ab7;
  color: white;
  margin-left: 20px;
  border-radius: 5px;
  padding: 5px;
  width: 10%;
}
.CondicionesonesRifas_boton__esZRZ:hover {
  background-color: white;
  color: #d54ab7;
  border: 2px solid #d54ab7;
}
.CondicionesonesRifas_number__CYQZZ {
  color: blue;
}
@media (max-width: 768px) {
  .CondicionesonesRifas_boton__esZRZ {
    width: 15%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/estilos/CondicionesonesRifas.module.css"],"names":[],"mappings":"AAEA;EACE,uBAAuB;EACvB,gBAAgB;EAChB,YAAY;EACZ,aAAa;EACb,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,cAAc;EACd,mBAAmB;EACnB,gBAAgB;;AAElB;AACA;EACE,eAAe;EACf,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,yBAAyB;EACzB,eAAe;EACf,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;EACZ,UAAU;AACZ;AACA;EACE,uBAAuB;EACvB,cAAc;EACd,yBAAyB;AAC3B;AACA;EACE,WAAW;AACb;AACA;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":["@import url(./fotnts.css);\n\n.container {\n  font-family: sans-serif;\n  max-width: 80%px;\n  margin: auto;\n  padding: 20px;\n  text-align: left;\n}\n.title {\n  text-align: center;\n  color: #d54ab7;\n  margin-bottom: 30px;\n  padding: inherit;\n\n}\np {\n  font-size: 20px;\n  margin-bottom: 20px;\n  padding: inherit;\n}\n.containerBoton {\n  text-align: center;\n}\n.boton {\n  background-color: #d54ab7;\n  border: #d54ab7;\n  color: white;\n  margin-left: 20px;\n  border-radius: 5px;\n  padding: 5px;\n  width: 10%;\n}\n.boton:hover {\n  background-color: white;\n  color: #d54ab7;\n  border: 2px solid #d54ab7;\n}\n.number {\n  color: blue;\n}\n@media (max-width: 768px) {\n  .boton {\n    width: 15%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CondicionesonesRifas_container__ulR5m`,
	"title": `CondicionesonesRifas_title__A4PII`,
	"containerBoton": `CondicionesonesRifas_containerBoton__nPYlX`,
	"boton": `CondicionesonesRifas_boton__esZRZ`,
	"number": `CondicionesonesRifas_number__CYQZZ`
};
export default ___CSS_LOADER_EXPORT___;
