import React from 'react';
import tallesStyle from "../styles/estilos/Talles.module.css";
import guideStyle from "../styles/estilos/guide.module.css";
import fantasma from "../images/fantasmaOpinion.png";
import danger from "../images/Danger.png";
import imagenremera17 from "../images/remeras/imagenremera17.png";
import imagenremera19 from "../images/remeras/imagenremera19.png";
import imagenremera20 from "../images/remeras/imagenremera20.png";
import imagenremera21 from "../images/remeras/imagenremera21.png";
import imagenremera22 from "../images/remeras/imagenremera22.png";
import imagenremera23 from "../images/remeras/imagenremera23.png";
import imagenremera24 from "../images/remeras/imagenremera24.png";
import imagenremera26 from "../images/remeras/imagenremera26.png";
import imagenremera27 from "../images/remeras/imagenremera27.png";
import imagenremera28 from "../images/remeras/imagenremera28.png";
import imagenremera29 from "../images/remeras/imagenremera29.png";
import carta5  from "../images/guideImages/CARTA5.png"
import carta6  from "../images/guideImages/CARTA6.png";
import carta7  from "../images/guideImages/CARTA7.png";

const GuiaRemera4 = ({ onClose,onBack, onNext }) =>{ 

  return(
  
<div className={tallesStyle.modalBackground}>
 <div className={tallesStyle.modalG1}>
  <div className={tallesStyle.modalContent}></div>
   <div className={guideStyle.cellContent}>
   
    <div className={guideStyle.number}>
      <h2 className={guideStyle.num}>5</h2>
      <p>LLEVA BOLSILLO?</p>
    </div>

    <div className={guideStyle.container} >
     <div className={guideStyle.modelImages}>

       <div className={guideStyle.category}>
        <p>SIN BOLSILLO</p>
        <img src={imagenremera17} alt="remera"/>
       </div>
            
      <div className={guideStyle.category}>
        <p>CON BOLSILLO</p>
        <img src={imagenremera19} alt="remera"/>
      </div>

     </div>
    </div>

     <div className={guideStyle.number}>
        <h2 className={guideStyle.num}>6</h2>
        <p>COLOR DE CUELLO</p>
     </div>

  <div className={guideStyle.container} >
    <div className={guideStyle.modelImages}>

     <div className={guideStyle.category}>
        <p>COLOR LISO</p>
        <img src={imagenremera20} alt="remera"/>
     </div>
    
    <div className={guideStyle.category}>
       <p>FULLPRINT</p>
       <img src={imagenremera21} alt="remera"/>
    </div>

    <div className={guideStyle.category2}>
       <p>3 TIRAS <br/>TEJIDO </p>
       <img src={imagenremera22} alt="remera"/>
    </div>

    <div className={guideStyle.category2}>
       <p>5 TIRAS<br/>TEJIDO </p>
       <img src={imagenremera23} alt="remera"/>
    </div>

    </div>
  </div>

    <div className={guideStyle.number}>
        <h2 className={guideStyle.num}>7</h2>
        <p>COLOR DE PUÑOS</p>
     </div>

   <div className={guideStyle.container} >
    <div className={guideStyle.modelImages4}>

    
   <div className={guideStyle.category}>
       <p>REMATE COSIDO </p>
       <img src={imagenremera24} alt="remera"/>
   </div>

    <div className={guideStyle.category}>
       <p>COLOR LISO</p>
      <img src={imagenremera26} alt="remera"/>
    </div>
    <div className={guideStyle.category}>
       <p>FULLPRINT</p>
      <img src={imagenremera27} alt="remera"/>
    </div>
    <div className={guideStyle.category2}>
       <p>3 TIRAS <br/>TEJIDO</p>
      <img src={imagenremera28} alt="remera"/>
    </div>
    <div className={guideStyle.category2}>
       <p>5 TIRAS <br/>TEJIDO</p>
      <img src={imagenremera29} alt="remera"/>
    </div>
  </div>
  </div>
    
   <div className={guideStyle.buttonContainer}>
      <button className={guideStyle.one}  onClick={onClose}>Cerrar</button>
      <button className={guideStyle.two}  onClick={onBack}>Atras</button>
      <button className={guideStyle.two}  onClick={onNext}>Siguiente</button>
   </div> 
   </div> 
   {/**desk */}
   <div className={guideStyle.deskContent1}>
      <section className={guideStyle.first4}>
         <div className={guideStyle.number}>
            <h2 className={guideStyle.num}>4</h2>
            <p>CARTA DE COLORES DE PIQUE</p>
         </div>
        <div className={guideStyle.announce}>
             <p>COLORES SUJETOS A DISPONIBILIDAD AL MOMENTO DE PRODUCCIÓN</p>
              <img  className={guideStyle.fantasma} src={fantasma} alt="fantasma"/>
         </div>
         <div className={guideStyle.cartas}>
            <img className={guideStyle.cartaimg2}  src={carta5} alt="" />
            <img className={guideStyle.cartaimg2} src={carta6} alt="" />
            <img className={guideStyle.cartaimg2}  src={carta7} alt="" />
          </div> 
      </section>
      <section className={guideStyle.second4}>
         <div className={guideStyle.number}>
           <h2 className={guideStyle.num}>5</h2>
           <p>LLEVA BOLSILLO?</p>
         </div> 
         <div className={guideStyle.container} >
     <div className={guideStyle.modelImages}>

       <div className={guideStyle.category}>
        <p>SIN BOLSILLO</p>
        <img src={imagenremera19} alt="remera"/>
       </div>
            
      <div className={guideStyle.category}>
        <p>CON BOLSILLO</p>
        <img src={imagenremera17} alt="remera"/>
      </div>

     </div>
    </div>  
      </section>
   <div className={guideStyle.buttonContainerpique}>
      <button className={guideStyle.one}  onClick={onClose}>Cerrar</button>
      <button className={guideStyle.two}  onClick={onBack}>Atras</button>
      <button className={guideStyle.two}  onClick={onNext}>Siguiente</button>
   </div> 
 </div>  
</div>
</div>
   );
 };
  export default GuiaRemera4;