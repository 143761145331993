// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.Size_table__t1agI{
  border-collapse: collapse;
  width: 100%;
}
.Size_th__HMuAP{
  padding: 8px;
  /* text-align:; */
  background-color: #f2f2f2; /* Color de fondo del encabezado */
  border-bottom: 1px solid black;
}
.Size_tr__0jBTi:nth-child(even) {
    background-color: grey; /* Color de fondo para filas pares */
  }
.Size_eliminar__TiP-z{
  border: none;
  background-color: transparent;
  margin-bottom: 1rem;
}
.Size_editar__M434b{
  border: none;
  background-color: transparent;

}
.Size_imgeditar__hE0wg{
  margin-left: 0rem
}
.Size_imgeeliminar__q9aeS{
  margin-left: -2rem

}
.Size_tableContainer__X32UN {
  max-height: 100px;
  /* overflow-y: auto;  */
}
.Size_confirmbutton__xhY0W{
  background-color: rgb(0, 113, 206);
}
`, "",{"version":3,"sources":["webpack://./src/styles/estilos/Size.module.css"],"names":[],"mappings":";AACA;EACE,yBAAyB;EACzB,WAAW;AACb;AACA;EACE,YAAY;EACZ,iBAAiB;EACjB,yBAAyB,EAAE,kCAAkC;EAC7D,8BAA8B;AAChC;AACA;IACI,sBAAsB,EAAE,oCAAoC;EAC9D;AACF;EACE,YAAY;EACZ,6BAA6B;EAC7B,mBAAmB;AACrB;AACA;EACE,YAAY;EACZ,6BAA6B;;AAE/B;AACA;EACE;AACF;AACA;EACE;;AAEF;AACA;EACE,iBAAiB;EACjB,uBAAuB;AACzB;AACA;EACE,kCAAkC;AACpC","sourcesContent":["\n.table{\n  border-collapse: collapse;\n  width: 100%;\n}\n.th{\n  padding: 8px;\n  /* text-align:; */\n  background-color: #f2f2f2; /* Color de fondo del encabezado */\n  border-bottom: 1px solid black;\n}\n.tr:nth-child(even) {\n    background-color: grey; /* Color de fondo para filas pares */\n  }\n.eliminar{\n  border: none;\n  background-color: transparent;\n  margin-bottom: 1rem;\n}\n.editar{\n  border: none;\n  background-color: transparent;\n\n}\n.imgeditar{\n  margin-left: 0rem\n}\n.imgeeliminar{\n  margin-left: -2rem\n\n}\n.tableContainer {\n  max-height: 100px;\n  /* overflow-y: auto;  */\n}\n.confirmbutton{\n  background-color: rgb(0, 113, 206);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `Size_table__t1agI`,
	"th": `Size_th__HMuAP`,
	"tr": `Size_tr__0jBTi`,
	"eliminar": `Size_eliminar__TiP-z`,
	"editar": `Size_editar__M434b`,
	"imgeditar": `Size_imgeditar__hE0wg`,
	"imgeeliminar": `Size_imgeeliminar__q9aeS`,
	"tableContainer": `Size_tableContainer__X32UN`,
	"confirmbutton": `Size_confirmbutton__xhY0W`
};
export default ___CSS_LOADER_EXPORT___;
